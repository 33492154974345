import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect, useMemo  } from 'react'; 
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Label,
  Form,
  HorizontalGroup,
  VerticalGroup,
  Input,
  Slider,
  ColorPickerInput,
  InputControl,
  Select,
  MultiSelect,
  Switch,
  TextArea,
  Collapse,
  useStyles2,
} from '@grafana/ui';
import { CollapsibleSection } from './CollapsibleSection';
import { L1OyMData } from './L1OyMManager';
import { MapComponent } from './PointPickerModal';
import { 
  valueToType, 
  valueToSubtype,
  valueToProtocol,
  valueToStatus,
  valueToAssetStatus,
  fiberOpticColors, 
  bufferColors, 
  multiparColors, 
  lineWidths, 
  lineTypes,
  fiberOpticConnectors,
  valueToDuplex,
  assetTypes
} from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface L1OyMExpandedFormProps {
  renderCount: number;
  onCancel: () => void;
  onDelete: () => void;
  onSave: (data: L1OyMData[]) => void;
  element: L1OyMData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  elements: L1OyMData[];
  assets: [];
  products: [];
  places: [];
  types: [];
  sites: [];
  l1Links: [];
  ppList: [];
  pluginVariables: [];
  tableMode: boolean;
  setChanges: boolean;
}

export const L1OyMExpandedForm: FC<L1OyMExpandedFormProps> = ({ 
  renderCount,
  onCancel,
  onDelete,
  onSave,
  element, 
  isDark, 
  isAdmin,
  width, 
  elements,
  assets,
  products,
  places,
  types,
  sites,
  l1Links,
  ppList,
  pluginVariables,
  tableMode,
  setChanges
}) => {
  const isAddMode = element ? element.version === 0 : false;
  const styles = useStyles2(getStyles(isDark, width));

  const [title, setTitle] = useState(element ? element.title : '');
  const [subType, setSubType] = useState(element ? element.subType : '');
  const [pathId, setPathId] = useState(element ? element.pathId : '');
  const [elementStyle, setElementStyle] = useState(element ? element.style : '{}');
  const [isOpen, setIsOpen] = useState(false);
  const [isPathOpen, setIsPathOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isParentOpen, setIsParentOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(setChanges);
  
  const [spanIDs, setSpanIDs] = useState([]);
  const [linkIDs, setLinkIDs] = useState([]);
  const [segmentIds, setSegmentIds] = useState([]);
  const [relations, setRelations] = useState([]);
  const [sublinks, setSublinks] = useState([]);
  const [validSubTypes, setValidSubTypes] = useState([]);
  const [parentPlaces, setParentPlaces] = useState([]);
  const [parentMarkers, setParentMarkers] = useState([]);
  const [parentSubType, setParentSubType] = useState('path');
  const [segmentSubLinks, setSegmentSubLinks] = useState([]);
  const [localPanelPorts, setLocalPanelPorts] = useState([]);
  const [remotePanelPorts, setRemotePanelPorts] = useState([]);

  const [linkCapacity, setLinkCapacity] = useState(element.capacity);
  const [coordinates, setCoordinates] = useState(element.coordinates);

  const today = new Date();
  const todayISO = today.toISOString().split('T')[0];
  const maxDate = new Date(today);
  maxDate.setFullYear(today.getFullYear() + 5);
  const maxDateISO = maxDate.toISOString().split('T')[0];

  useEffect(() => {  
	if (setChanges !== hasChanges) {
      setHasChanges(true);
    }
	const parent = elements.find(ele => ele.uid === element.parentsIds[0]);
	const subType = parent ? parent.subType : 'path';
	setParentSubType(subType);
  }, [setChanges]);

  const handlePlaceCoordsChange = (coords, elementId) => {
	if (elementId && elementId !== null) {
	  let element = elements.find(ele => ele.uid === elementId);
      if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
	    element.coordinates[0] = parseFloat(coords[0]);
	    element.coordinates[1] = parseFloat(coords[1]);
		element.wasChange = true;
	  }
	}
	setCoordinates(coords);
	setHasChanges(true);
  };

  const handleNewPlaceCoords = (currentCoords, elementId) => {
	setCoordinates(currentCoords);
	setHasChanges(true);
  };

  const handleCoordsChange = (e) => {
	const value = e.split(',').map(Number);
    if (value.length === 2 && !isNaN(value[0]) && !isNaN(value[1])) {
      setCoordinates(value);
	  setHasChanges(true);
	  return true;
    }
  };
  
  const handleAddSublink = () => {
    setSublinks([...sublinks, { sublinkId: '', buffer: '', color: '', distance: 0, attenuation: 0 }]);
  };

  const handleRemoveSublink = (index) => {
    setSublinks(sublinks.filter((_, i) => i !== index));
  };

  const handleChangeSublink = (index, field, value) => {
    const newSublinks = [...sublinks];
    newSublinks[index][field] = value;
    setSublinks(newSublinks);
  };

  const handleChangeStyle = (index, value) => {
	setElementStyle(prevStyle => ({
      ...prevStyle,
      [index]: value
    }));
  };

  const handleAddParent = () => {
    setRelations([...relations, { element_id: '', distance: '', capacity: '', port: '' }]);
  };

  const handleRemoveParent = (index) => {
    setRelations(relations.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const newParents = [...relations];
    newParents[index][field] = value;
    setRelations(newParents);
	if (newParents[index].element_id !== '') {
	  updateParentMarkers(newParents);
	}
  };

  const handleSubmit = (value: any, uidx: string, operation: string) => {
    //console.log(value);
	if (operation === 'submit') {
	  let valuesToUpdate = value;
	  valuesToUpdate.status = value.state === false ? 'out_of_use' : value.status;
	  if (value.elementType === 'path') {
	    const elementsChanged = elements.filter(ele => ele.wasChange === true);
	    elementsChanged.forEach(element => {
		  const valueToUpdate = element;
		  onSave(valueToUpdate, 'coordinates');
		  element.wasChange = false;
	    });
	    valuesToUpdate.pathId = value.idx;
	    valuesToUpdate.style = elementStyle;
	    onSave(valuesToUpdate, 'full');
	  } else if (value.elementType === 'link') {
	    valuesToUpdate.linkId = value.idx;
	    valuesToUpdate.style = elementStyle;
	    onSave(valuesToUpdate, 'full');
	  } else {
	    valuesToUpdate = formatToSubmit(value);
	    valuesToUpdate.style = elementStyle;
	    onSave(valuesToUpdate, 'full');
	  }
	} else if (uidx !== '' && operation === 'delete') {
	  const elementDelete = elements.find(ele => ele.uid === uidx);
	  if (elementDelete) {
		let valueToDelete = elementDelete;
		valueToDelete.status = 'delete';
		valueToDelete.state = false;
		onSave(valueToDelete, 'full');
	  }
	}
  };

  const formatToSubmit = (value: any) => {
    let valueFormated = value;
	//console.log(valueFormated);
	valueFormated.idx = value.idx ? value.idx : value.uid;
	if (value.elementType === 'element') {
	    valueFormated.elementId = valueFormated.idx;
	    valueFormated.coordinates = coordinates;
	    const relationIds = relations.map(p => p.element_id);
        const capacities = relations.map(p => parseFloat(p.capacity) || 0);
	    valueFormated.relationIds = relationIds;
	    valueFormated.relationCapacities = capacities;
        if (value.subfamily !== 'panels' && value.subfamily !== 'termination') {
		  const distances = relations.map(p => parseFloat(p.distance) || 0);
	      valueFormated.relationDistances = distances;
		} else {
		  valueFormated.relationDistances = [];
		}
	} else if (value.elementType === 'segment') {
	    valueFormated.segmentId = valueFormated.idx;
	    if (parentSubType === 'fo_path' || parentSubType === 'ge_path' || parentSubType === 'utp_path') {
	      valueFormated.capacity = linkCapacity;
	    }
	    const sublink_ids = sublinks.map(p => p.sublinkId);
        const sublink_buffer = sublinks.map(p => p.buffer || '');
        const sublink_color = sublinks.map(p => p.color || '');
        const sublink_distances = sublinks.map(p => parseFloat(p.distance) || 0);
        const sublink_attenuation = sublinks.map(p => parseFloat(p.attenuation) || 0);
	    valueFormated.sublinkIds = sublink_ids;
	    valueFormated.sublinkBuffer = sublink_buffer;
	    valueFormated.sublinkColor = sublink_color;
	    valueFormated.sublinkDistance = sublink_distances;
	    valueFormated.sublinkAttenuation = sublink_attenuation;
	} else if (value.elementType === 'path' || value.elementType === 'span') {
	  valueFormated.elementId = valueFormated.idx;
	  valueFormated.coordinates = coordinates;
	} else {
	  valueFormated.elementId = valueFormated.idx;
	}
	return valueFormated;
  }

  const updateValidSubTypes = (typeSelected: string) => {
	const validTypes = valueToSubtype.filter(value => value.family === typeSelected)
	setValidSubTypes(validTypes);
  }

  const widthToOption = (value: number) => {
	const valueSelected = lineWidths.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  const dashArrayToOption = (value: string) => {
	const valueSelected = lineTypes.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected;
	} else {
	 return null;
	}
  }

  const assetPortsToString = (value: string, localPort: boolean) => {
	const valueSelected = localPort ? localAssetPorts.find(ele => ele.value === value) : remoteAssetPorts.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const productToString = (value: string, returnLabel: boolean) => {
	const valueSelected = products.find(ele => ele.value === value);
	if (valueSelected) {
	  if (returnLabel) {
	    return valueSelected.label;
	  } else {
		return valueSelected.protocol
	  }
	} else {
	 return null;
	}
  }

  const valueToLabel = (value: string, array: any) => {
	const valueSelected = array.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const coordinatesToPlace = (coordinates: []) => {
	const valueSelected = places.find(ele => 
	  parseFloat(ele.lat).toFixed(6) === parseFloat(coordinates[0]).toFixed(6) && 
	  parseFloat(ele.lng).toFixed(6) === parseFloat(coordinates[1]).toFixed(6));
	if (valueSelected) {
	  return valueSelected.value;
	} else {
	 return null;
	}
  }

  useEffect(() => {
	updateValidSubTypes(element.elementType);
        if (element.elementType === 'element') {
	  const initialParents = element.relationIds.map((relationId, index) => ({
        element_id: relationId || '', 
        distance: element.subFamily !== 'panels' ? element.relationDistances[index] || '' : '', 
        capacity: element.relationCapacities[index] || '',
		port: element.relationPorts[index] || '',
      }));
      setRelations(initialParents);
	  if (element.subFamily === 'panels') {
		updateLinks('any');
		updateSegments(element.linkId);
	  }
	} else if (element.elementType === 'asset') {
	  const initialParents = element.relationIds.map((relationId, index) => ({
        element_id: relationId || '', 
		port: element.relationPorts[index] || '',
      }));
      setRelations(initialParents);
	  if (pluginVariables[13] && pluginVariables[13] !== '') {
	    const queryMap = {
		  [`var-${pluginVariables[13]}`]: element.idx,
	    };
	    try {
		  locationService.partial(queryMap, true);
	    } catch (error) {
		  console.error(error);
	    }
	  }
	} else if (element.elementType === 'segment') {
      const initialSubLinks = element.sublinkIds.map((sublinkId, index) => ({
        sublinkId: sublinkId || '', 
        buffer: element.sublinkBuffer[index] || '', 
        color: element.sublinkColor[index] || '',
        distance: parseFloat(element.sublinkDistance[index]) || 0, 
        attenuation: parseFloat(element.sublinkAttenuation[index]) || 0,
      }));
	  setSublinks(initialSubLinks);
	  updateSegment(element.capacity, element.linkId);
	  updateLinks(element.subType);
	} else if (element.elementType === 'data_link') {
	  const origin = pluginVariables[13] && pluginVariables[13] !== '' ? elements.find((ele) => ele.uid === element.origin) : undefined;
	  const destination = pluginVariables[14] && pluginVariables[14] !== '' ? elements.find((ele) => ele.uid === element.destination) : undefined;
	  const queryMap = origin  && destination ? { 
		  [`var-${pluginVariables[13]}`]: origin.idx,
		  [`var-${pluginVariables[14]}`]: destination.idx
		} : {
		  [`var-${pluginVariables[13]}`]: origin.idx	  
		};
	  if (origin) {
	    try {
		  locationService.partial(queryMap, true);
	    } catch (error) {
		  console.error(error);
	    }
	  }
	}
	if (element.style && element.style !== null) {
	  const elementStyleObject = {
	    color: element.style.color,
		size: element.style.size,
		weight: element.style.weight,
		icon: element.style.icon,
		dashArray: element.style.dashArray,
		id: element.style.id,
	  }
	  setElementStyle(elementStyleObject);
	}
  }, [element]);

  const updateElement = (subType: string, parentUid: string, pathId: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	const pathInfo = elements.find(ele => ele.uid === pathId);
	const relationStyle = pathInfo ? pathInfo.style : {color: '#000000', size: 32, weight: 2, icon: '', dashArray: '', id: ''};
	const parentEmps: [] = [];
	if (parent) {
	  if (parent.origin !== '' || parent.destination !== '') {
		const originPlace = places.find(ele => ele.value === parent.origin);
		const newOriginPlace = {
		  elementId: originPlace.value,
		  pathId: pathId,
		  label: originPlace.label,
		  description: originPlace.description,
		  family: 'emp',
		  value: originPlace.value,
		  lat: parseFloat(originPlace.lat),
		  lng: parseFloat(originPlace.lng),
		  destLat: parseFloat(originPlace.lat),
		  destLng: parseFloat(originPlace.lng),
		  style: relationStyle,
		};
		parentEmps.push(newOriginPlace);
		const destinationPlace = places.find(ele => ele.value === parent.destination);
		const newDestinationPlace = {
		  elementId: destinationPlace.value,
		  pathId: pathId,
		  label: destinationPlace.label,
		  description: destinationPlace.description,
		  family: 'emp',
		  value: destinationPlace.value,
		  lat: parseFloat(destinationPlace.lat),
		  lng: parseFloat(destinationPlace.lng),
		  destLat: parseFloat(destinationPlace.lat),
		  destLng: parseFloat(destinationPlace.lng),
		  style: relationStyle,
		};
		parentEmps.push(newDestinationPlace);
		setParentPlaces(parentEmps);
	  }
	}
  };

  const updateParentMarkers = (parents: []) => {
	const newMarkers: [] = [];
	for (let i = 0; i < parents.length; i++) {
	  const parentUid = parents[i].element_id;
	  const parentData = elements.find(ele => ele.uid === parentUid);
	  if (parentData) {
	    if (parentData.coordinates.length > 1) {
		  const newMarker = {
			label: parentData.title,
			description: parentData.address,
			family: parentData.subType,
			value: parentData.elementId,
			lat: parseFloat(parentData.coordinates[0]),
			lng: parseFloat(parentData.coordinates[1]),
			style: parentData.style,
		  };
		  newMarkers.push(newMarker);
		}
	  } else {
		const empData = places.find(ele => ele.value === parentUid);
		if (empData) {
		  const newEmp = {
			label: empData.label,
			description: empData.description,
			family: 'emp',
			value: empData.value,
			lat: parseFloat(empData.lat),
			lng: parseFloat(empData.lng),
			style: empData.style,
		  };
		  newMarkers.push(newEmp);
		}
	  }
	}
	if (newMarkers.length > 0) {
	  const markers = newMarkers.sort((a, b) => a.label.localeCompare(b.label));
	  setParentMarkers(markers);
	}
  };

  const updateSpans = (subType: string) => {
	const newSpanIds: [] = [];
	let spanList = elements.filter(value => 
	  value.elementType === 'path' && (value.subType === 'underground_span' || value.subType === 'aerial_span')
	);

    for (let i = 0; i < spanList.length; i++) {
	  let newSpan = {
	    label: spanList[i].title ? spanList[i].title : spanList[i].idx,
	    description: spanList[i].idx,
	    value: spanList[i].elementId,
	  };
	  newSpanIds.push(newSpan);
    }
	const spans = newSpanIds.sort((a, b) => a.label.localeCompare(b.label));
	setSpanIDs(spans);
  }

  const updateLinks = (subType: string) => {
	const newLinksIds: [] = [];
	let linkList = elements.filter(value => value.elementType === 'link');
	if (subType === 'radio') {
      linkList = elements.filter(value => 
	    value.elementType === 'link' && (value.subType === 'fo_path' || value.subType === 'ge_path' || value.subType === 'utp_path')
	  );
	} else if (subType === 'aerial' || subType === 'underground') {
      linkList = elements.filter(value => value.elementType === 'link' && (value.subType === 're_path' || value.subType === 'sa_path'));
	}

    for (let i = 0; i < linkList.length; i++) {
	  let newLink = {
	    label: linkList[i].title ? linkList[i].title : linkList[i].idx,
	    description: 'ID: ' + linkList[i].idx,
	    value: linkList[i].elementId,
	  };
	  newLinksIds.push(newLink);
    }
	const links = newLinksIds.sort((a, b) => a.label.localeCompare(b.label));
	setLinkIDs(links);
  }

  const updateSegment = (capacity: number, parentUid: string) => {
	const parent = elements.find(ele => ele.uid === parentUid);
	if (parent) {
	  if (parentSubType !== parent.subType || segmentSubLinks.length !== capacity) {
	    let segmentName = 'hilo';
        let segmentLabel = 'fiber';
		if (parent.subType === 're_path' || parent.subType === 'sa_path') {
          segmentName = 'radio';
          segmentLabel = 'channel';
        } else if (parent.subType === 'ge_path') {
          segmentName = 'par';
          segmentLabel = 'pair';
        } else if (parent.subType === 'utp_path') {
          segmentName = 'cable';
          segmentLabel = 'utp';
        }
        const newSegmentSubLinks: [] = [];
        for (let i = 0; i < capacity; i++) {
	      let segmentSubLink = {
	        label: `${segmentLabel}_${i + 1}`,
	        description: `${parent.title} ${segmentName} ${i + 1}`,
	        value: `${parent.segmentId}_${segmentLabel}_${i + 1}`,
	      };
	      newSegmentSubLinks.push(segmentSubLink);
        }
        setParentSubType(parent.subType);
	    setSegmentSubLinks(newSegmentSubLinks);
	  }
	}
  };

  const updateSegments = (linkId: string) => {
	const newSegmentIds: [] = [];
	const segmentsList = linkId !== '' ? 
	  elements.filter(value => value.elementType === 'segment' && value.linkId === linkId) :
	  elements.filter(value => value.elementType === 'segment' && value.version > 0);
    for (let i = 0; i < segmentsList.length; i++) {
	  const newSegment = {
	    label: segmentsList[i].title ? segmentsList[i].title : segmentsList[i].idx,
	    description: 'ID: ' + segmentsList[i].idx,
	    value: segmentsList[i].elementId,
	  };
	  newSegmentIds.push(newSegment);
    }
	const segments = newSegmentIds.sort((a, b) => a.label.localeCompare(b.label));
	setSegmentIds(segments);
  }

  const updatePanelPort = (panelId: string, scope: string) => {
    const newPanelPorts: [] = [];
    const panel = elements.filter(ele => ele.uid === panelId);
	const panelPorts = elements.filter(ele => ele.subFamily === 'termination' && ele.panelId === panelId);
	if (panelPorts) {
      for (let i = 0; i < panelPorts.length; i++) {
	    const panelPort = {
	      label: panelPorts[i].title ? panelPorts[i].title : panelPorts[i].idx,
	      description: 'ID: ' + panelPorts[i].idx,
	      value: panelPorts[i].elementId,
	    };
	    newPanelPorts.push(panelPort);
      }
	  const panels = newPanelPorts.sort((a, b) => a.label.localeCompare(b.label));
	  if (scope === 'local') {
	    setLocalPanelPorts(panels);
	  } else {
	    setRemotePanelPorts(panels);
	  }
	}
  };

  const pathIDs = useMemo(() => {
    return elements
      .filter((value) => value.elementType=== 'path' && value.version > 0)
      .map((path) => ({
        label: path.title ? path.title : path.idx,
        description: 'ID: ' + path.idx,
        value: path.elementId,
      }))
	  .sort((a, b) => a.label.localeCompare(b.label));
  }, [elements]);

  const linkSegments = useMemo(() => {
    return elements
      .filter((value) => value.elementType === 'segment' && value.version > 0)
      .map((segment) => ({
        label: segment.title ? segment.title : '',
        description: 'ID: ' + segment.idx,
        value: segment.elementId,
      }))
	  .sort((a, b) => a.label.localeCompare(b.label));
  }, [elements]);

  const panelIDs = useMemo(() => {
    return elements
      .filter((value) => value.subFamily === 'panels' && value.version > 0)
      .map((panel) => ({
        label: panel.title ? panel.title : '',
        description: 'ID: ' + panel.idx,
        value: panel.elementId,
      }))
	  .sort((a, b) => a.label.localeCompare(b.label));
  }, [elements]);

  const { placeIDs, manteinanceIDs, assetIDs } = useMemo(() => {
    const manteinanceSet = new Set<string>();
    const manteinanceList: { label: string; description: string; value: string }[] = [];
    const placeList = elements.filter((value) => value.elementType === 'emp' && value.version > 0);
    const placeIDs = placeList.map((place) => {
      return {
        label: place.title ? place.title : place.idx,
        description: 'ID: ' + place.idx,
        value: place.uid,
      };
    });
    const manteinanceIDs = placeList.map((place) => {
      if (place.technician && !manteinanceSet.has(place.technician)) {
        manteinanceSet.add(place.technician);
        return {
          label: place.technician,
          description: 'Grupo de Mantenimiento ' + place.technician,
          value: place.technician,
        };
	  }
    });

    const assetList = elements.filter((value) => value.elementType === 'asset' && value.subFamily === 'hw' && value.version > 0);
    const assetIDs = assetList.map((asset) => {
      return {
        label: asset.title ? asset.title : asset.idx,
        description: asset.assetType,
        value: asset.elementId,
      };
    });

    const sortedManteinanceList = manteinanceIDs
	  .filter((item) => item !== undefined)
	  .sort((a, b) => a.label.localeCompare(b.label)
    );
    const sortedPlaceList = placeIDs.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    const sortedAssetList = assetIDs.sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return {
      placeIDs: sortedPlaceList,
      manteinanceIDs: sortedManteinanceList,
      assetIDs: sortedAssetList,
    };
  }, [elements]);

  const { pathEvents, elementsRelations } = useMemo(() => {
    const pathEvents = elements
      .filter((value) => (value.elementType === 'element' || value.elementType === 'emp') && value.version > 0)
      .map((event) => ({
        label: event.title ? event.title : event.elementId,
        description: event.address,
        value: event.elementId,
        family: event.subType,
        lat: event.coordinates[0] || 0,
        lng: event.coordinates[1] || 0,
        style: event.style,
      }));

    const selectedPath = element.pathId;
    const selectedElements = elements.filter((ele) => ele.pathId === selectedPath);
    const elementsRelations = [];
    selectedElements.forEach((newElement) => {
      const markerElement = elementsRelations.find((ele) => ele.elementId === newElement.uid);
      const pathInfo = pathEvents.find((ele) => ele.value === newElement.pathId);
      const relationStyle =
        pathInfo?.style || { color: '#000000', size: 32, weight: 2, icon: '', dashArray: '', id: '' };

      if (!markerElement) {
        const relationIds = newElement.relationIds || [];
        relationIds.forEach((relationId) => {
          const parentData = elements.find((ele) => ele.uid === relationId);
          if (parentData && parentData.coordinates.length > 1) {
            elementsRelations.push({
              elementId: newElement.uid,
              pathId: newElement.pathId,
              label: newElement.title ? newElement.title : '',
              description: newElement.address,
              family: newElement.subType,
              value: null,
              parentId: parentData.elementId,
              lat: newElement.coordinates[0] ? parseFloat(newElement.coordinates[0]) : null,
              lng: newElement.coordinates[1] ? parseFloat(newElement.coordinates[1]) : null,
              destLat: parseFloat(parentData.coordinates[0]),
              destLng: parseFloat(parentData.coordinates[1]),
              style: relationStyle,
            });
          } else {
            const empData = places.find((ele) => ele.value === relationId);
            if (empData) {
              elementsRelations.push({
                elementId: newElement.uid,
                pathId: newElement.pathId,
                label: newElement.title ? newElement.title : empData.value,
                description: newElement.address,
                family: 'emp',
                value: empData.value,
                parentId: empData.value,
                lat: newElement.coordinates[0] ? parseFloat(newElement.coordinates[0]) : null,
                lng: newElement.coordinates[1] ? parseFloat(newElement.coordinates[1]) : null,
                destLat: parseFloat(empData.lat),
                destLng: parseFloat(empData.lng),
                style: relationStyle,
              });
            }
          }
        });

        if (!elementsRelations.some((ele) => ele.elementId === newElement.uid)) {
          elementsRelations.push({
            elementId: newElement.elementId,
            pathId: newElement.pathId,
            label: newElement.title ? newElement.title : newElement.elementId,
            description: newElement.address,
            family: newElement.subType,
            value: newElement.elementId,
            parentId: null,
            lat: parseFloat(newElement.coordinates[0]),
            lng: parseFloat(newElement.coordinates[1]),
            destLat: parseFloat(newElement.coordinates[0]),
            destLng: parseFloat(newElement.coordinates[1]),
            style: { color: '#000000', size: 32, weight: 2, icon: '', dashArray: '' },
          });
        }
      }
    });

    const sortedpathEvents = pathEvents.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    const sortedelementsRelations = elementsRelations.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    return { 
	  pathEvents: sortedpathEvents, 
	  elementsRelations: sortedelementsRelations,
    };
  }, [elements, element, places]);

  const parsePorts = (portsString: string): { label: string; description: string; value: string }[] => {
    if (!portsString || portsString === '') return [];
    return portsString
	  .split(',')
	  .map((port) => {
        const [label, description, value] = port.split('||');
        return { label, description, value };
      })
	  .sort((a, b) => a.label.localeCompare(b.label));
  };

  const localAssetPorts = useMemo(
    () => parsePorts(pluginVariables[15] || ''),
    [pluginVariables[15]]
  );

  const remoteAssetPorts = useMemo(
    () => parsePorts(pluginVariables[16] || ''),
    [pluginVariables[16]]
  );

  const memoizedMap = useMemo(() => (
	<MapComponent 
	  isDark={isDark}
	  renderCount={renderCount}
	  currentNodes={[
		{
		  ...element,
		  title: title,
		  coordinates: coordinates,
		  subType: subType,
		  pathId: pathId,
		}
	  ]}
	  onCoordinatesChange={(coords, currentPlace) => {
		handlePlaceCoordsChange(coords, currentPlace);
	  }}
	  onNewCoordinates={(currentCoords, elementId) => {
		handleNewPlaceCoords(currentCoords, elementId);
	  }}
	  markers={[]}
	  showMarkerIcons={isParentOpen}
	  mapSource={pluginVariables[4]}
	  geoVariables={[
	    pluginVariables[5],
		pluginVariables[6],
		pluginVariables[7],
		pluginVariables[8],
		pluginVariables[9],
		pluginVariables[1],
		pluginVariables[11],
		pluginVariables[12]
	  ]}
	  readOnly={!isAdmin}
	  tableMode={true}
	/>
 ), [
    isDark,
    element,
	title,
	coordinates,
	subType,
	pathId,
    handlePlaceCoordsChange,
    handleNewPlaceCoords,
    parentPlaces,
    elementsRelations,
    pluginVariables,
    isAdmin,
  ]);

  const memoizedMapFull = useMemo(() => (
    <MapComponent
      isDark={isDark}
      renderCount={renderCount}
      currentNodes={[
        {
          ...element,
          title: title,
          subType: subType,
          pathId: pathId,
        }
      ]}
      onCoordinatesChange={(coords, currentPlace) => {
        handlePlaceCoordsChange(coords, currentPlace);
      }}
      onNewCoordinates={(currentCoords, elementId) => {
        handleNewPlaceCoords(currentCoords, elementId);
      }}
      markers={[...parentPlaces, ...elementsRelations]}
      showMarkerIcons={true}
      mapSource={pluginVariables[4]}
      geoVariables={[
        pluginVariables[5],
        pluginVariables[6],
        pluginVariables[7],
        pluginVariables[8],
        pluginVariables[9],
        pluginVariables[1],
        pluginVariables[11],
        pluginVariables[12],
      ]}
      readOnly={!isAdmin}
      tableMode={true}
    />
  ), [
    isDark,
    renderCount,
    element,
	title,
	subType,
	pathId,
    handlePlaceCoordsChange,
    handleNewPlaceCoords,
    parentPlaces,
    elementsRelations,
    pluginVariables,
    isAdmin,
  ]);

  //console.log(sublinks, segmentSubLinks);
  //console.log(element);
  // console.log(pathIDs);
  //console.log(placeIDs, manteinanceIDs);
  //console.log(assetIDs, remoteAssetPorts, localAssetPorts);
  //console.log(panelIDs, localPanelPorts, remotePanelPorts);

  return (
    <Form 
	  defaultValues={element} 
	  onSubmit={(value) => {
		handleSubmit(value, '', 'submit');
		setHasChanges(false);
	  }} 
	  className={styles.formContainer}
	>
      {({ control, register, watch, setValue }) => (
        <>
          {/* @ts-ignore-check: react-hook-form made me do this */}
          <input type="hidden" {...register('id')} />
		  {!tableMode && (
		    <HorizontalGroup key={'title'} align="flex-start" height="35px"> 
		      <Label className={styles.labelStyle} >
			    {'[' + watch().uid + '] ' + valueToLabel(watch().elementType, valueToType) + ' ' + watch().title}
			  </Label>
		    </HorizontalGroup>
		  )}
		  <HorizontalGroup key={'status'} align="flex-start" height="55px"> 
		    <Field label="Habilitar" className={styles.switchContainer}>
              <Switch id="state" className={styles.switchStyle} {...register('state')}/>
            </Field>
			{watch().state && watch().elementType !== 'splicer' && watch().elementType !== 'splitter' && (
			  <>
			  <Field 
				label="Estado administrativo:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="status"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={watch().elementType === 'asset' ? 
					    valueToAssetStatus.filter(ele => ele.label !== 'Detenido' && ele.label !== 'Eliminado') : 
						valueToStatus.filter(ele => ele.label !== 'Eliminado') 
					  }
					  defaultValue={valueToLabel(field, valueToStatus)}
					  title="Estado administrativo del elemento que esta configurando"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="status"
				/>
			  </Field>
			  <Field 
				label="ID:"
			    className={styles.nameContainer2}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Input
					  aria-label="idx"
					  {...field}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
					    onChange(value.value);
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="ID asignado al elemento"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="idx"
				/>
			  </Field>
			  </>
			)}
			{watch().elementType === 'emp' && (
			  <Field 
				label="Colector:"
				className={styles.nameContainer2}
			  >
			  <InputControl
				render={({ field: { onChange, ref, ...field } }) => (
				  <Select
					aria-label="siteId"
					{...field}
					className={styles.select}
					onChange={(value) => {
					  onChange(mapSelectValueToString(value));
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
					}}
					options={sites}
					defaultValue={valueToLabel(field, sites)}
					title="ID del colector asignado por defecto a este emplazamiento"
					disabled={!isAdmin}
				  />
				)}
				control={control}
				name="siteId"
			  />
			  </Field>
			)}
		  </HorizontalGroup>
		  {watch().state && (
			<HorizontalGroup key={'type'} align="flex-start" height="55px">
			  <Field 
				label="Familia:"
				className={styles.nameContainer}
				required={isAdmin}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="elementType"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
					    updateValidSubTypes(value.value);
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={valueToType}
					  defaultValue={valueToLabel(field, valueToType)}
					  title="Tipo de elemento que esta definiendo"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="elementType"
				/>
			  </Field>
			  {watch().elementType !== 'path' && (
			    <Field 
				  label="Tipo:"
			      className={styles.nameContainer}
				  required={isAdmin}
			    >
				  <InputControl
				    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="subType"
					    {...field}
					    className={styles.select}
					    onChange={(value) => {
						  if (watch().elementType === 'segment' || (watch().elementType === 'element' && watch().subFamily === 'panels')) {
						    //updateSegment(watch().capacity, value.value, watch().linkId);
						    updateLinks(value.value);
						  } else if (watch().elementType === 'span') {
						    updateSpans(value.value);
						  }
						  onChange(mapSelectValueToString(value));
						  setSubType(value.value);
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
					    }}
					    options={validSubTypes}
					    defaultValue={valueToLabel(field, validSubTypes)}
					    title="Tipo de enlace que esta definiendo"
						disabled={!isAdmin}
					  />
				    )}
				    control={control}
				    name="subType"
				  />
			    </Field>
			  )}
			  {watch().elementType === 'data_link' && watch().subType === 'inet_link' && (
				<>
				  <Field 
				    label="Operador:"
				    className={styles.nameContainer}
				    required={isAdmin}
				  >
				    <Input
					  aria-label="supplier"
					  {...register('supplier')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
					    if (!hasChanges) {
						  setHasChanges(true);
					    }
					  }}
					  title="Nombre o Designación del proveedor de servicios"
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field 
				    label="Referencia:"
				    className={styles.nameContainer}
				    required={isAdmin}
				  >
				    <Input
					  aria-label="reference"
					  {...register('reference')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
					    if (!hasChanges) {
						  setHasChanges(true);
					    }
					  }}
					  title="Referencia entregada por el operador para este vinculo"
					  disabled={!isAdmin}
				    />
				  </Field>
				</>
			  )}
			  {watch().elementType === 'asset' && (
			    <Field 
				  label="SubTipo:"
				  className={styles.nameContainer}
			    >
			    <InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
				    <Select
					  aria-label="assetType"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
					    onChange(mapSelectValueToString(value));
					    if (!hasChanges) {
					      setHasChanges(true);
					    }
					  }}
					  options={assetTypes.filter((ele) => ele.family === watch().subType)}
					  defaultValue={valueToLabel(field, assetTypes)}
					  title="ID del colector asignado por defecto a este emplazamiento"
					  disabled={!isAdmin}
				    />
				  )}
				  control={control}
				  name="assetType"
			    />
			    </Field>
			  )}
		    </HorizontalGroup>
		  )}
		  {watch().state && (
		    <HorizontalGroup key={'main'} align="flex-start" height="55px"> 
			  <Field 
			    label="Designación:"
			    className={watch().elementType !== 'splicer' && watch().elementType !== 'splitter' ? styles.nameContainer2 : styles.nameContainer3}
			  >
				<Input
				  aria-label="title"
				  {...register('title')}
				  className={styles.input}
				  type="text"
				  onChange={(value) => {
					setTitle(value.value);
					if (!hasChanges) {
					  setHasChanges(true);
					}
				  }}
				  placeholder="Ingrese un identificador..."
				  title="Nombre o alias asignado al elemento"
				  disabled={!isAdmin}
			    />
			  </Field>
              {(watch().elementType !== 'splicer' && watch().elementType !== 'splitter') && (
			    <Field 
		          label="Propietario:"
				  className={!tableMode ? styles.nameContainer : styles.nameContainer2}
		        >
				  <Input
					aria-label="owner"
				    {...register('owner')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					placeholder="ingrese un nombre o razon social."
					title="Dueño del recurso."
					disabled={!isAdmin}
                  />
                </Field>
			  )}
		    </HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'asset' && (
			<CollapsibleSection 
			  label="Configuración del Activo"
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'assetSettings'} align="flex-start" height="55px">
			    <Field 
				  label="Producto:"
				  className={styles.nameContainer}
			    >
			    <InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
				    <Select
					  aria-label="assetModel"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
					    onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={products}
					  defaultValue={productToString(field, true)}
					  title="Tipo de producto"
					  disabled={!isAdmin}
				    />
				  )}
				  control={control}
				  name="assetModel"
			    />
			    </Field>
			    <Field 
				  label="Direcciones MAC:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="macNumber"
				    {...register('macNumber')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Direcciones MAC del activo"
					disabled={!isAdmin}
					suffix={<Icon name="help-fill" size="xs" title="Para más de una dirección, ingrese las MAC separadas por coma" />}
				  />
			    </Field>
			  </HorizontalGroup>
			  <HorizontalGroup key={'protocol'} align="flex-start" height="55px">
			    <Field 
				  label="Monitoreado vía:"
			      className={styles.nameContainer}
			    >
			      <InputControl
				    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
					    aria-label="protocol"
					    {...field}
					    className={styles.select}
					    onChange={(value) => {
					      onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
					    }}
					    options={valueToProtocol}
					    defaultValue={valueToLabel(field, valueToProtocol)}
					    title="Protocolo utilizado para monitorear el activo"
					    disabled={!isAdmin}
				      />
				    )}
				    control={control}
				    name="protocol"
				  />
			    </Field>
				{watch().protocol === 'SNMPv1' || watch().protocol === 'SNMP' && (
				  <Field 
					label="Comunidad:"
					className={styles.nameContainer}
				  >
					<Input
					  aria-label="community"
				      {...register('community')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  placeholder="Ingrese una comunidad..."
					  title="Comnunidad SNMP a la que pertence el activo"
					  disabled={!isAdmin}
					/>
				  </Field>
				)}
				{watch().status === 'orquested' && (
				  <Field 
					label="Orquestado por OmniVista 2500:"
					className={styles.switchContainer}
				  >
					<Switch id="ovOrquested" className={styles.switchStyle} {...register('ovOrquested')} />
				  </Field>
				)}
			  </HorizontalGroup>
			  <HorizontalGroup key={'assetSettings2'} align="flex-start" height="55px">
			    <Field 
				  label="SLA esperado:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="sla"
				    {...register('sla')}
					className={styles.input}
					type="number"
					min="0"
					max="100"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="SLA objetivo o esperado para este enlace"
					suffix="%"
					disabled={!isAdmin}
				  />
				</Field>
			    <Field 
				  label="Activo Crítico:"
				  className={styles.switchContainer}
			    >
				  <Switch id="critic" className={styles.switchStyle} {...register('critic')} />
			    </Field>
			    <Field 
				  label="Notificaciones activas:"
				  className={styles.switchContainer}
			    >
				  <Switch id="notifEnable" className={styles.switchStyle} {...register('notifEnable')} />
			    </Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'asset' && (
			<CollapsibleSection 
			  label="Relaciones del Activo"
			  className={styles.collapseContainer} 
			  onChange={(isCollapsed) => {
			    if (pluginVariables[13] && pluginVariables[13] !== '') {
				  const queryMap = {
				    [`var-${pluginVariables[13]}`]: watch().idx,
				  };
				  try {
				    locationService.partial(queryMap, true);
				  } catch (error) {
				    console.error(error);
				  }
				}
			  }}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'ID del Activo'}</Label>
				<Label className={styles.nameContainer4} >{'Interface Local'}</Label>
				{isAdmin && (
				  <button 
				    type="button" 
				    className={styles.button} 
				    onClick={() => {
				      handleAddParent();
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
				    }}
				    title={'Agregar una nueva conexión'}
				  >
				    <div className={styles.add_icon}>
				      <Icon name="plus-circle-fill" size="lg" />
				    </div>
				  </button>
				)}
			  </HorizontalGroup>
			  {relations.map((relation, index) => (
				<HorizontalGroup key={'conexion_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
				  	  aria-label={`relation ${index + 1}`}
					  className={styles.select}
					  options={assetIDs}
					  value={relation.element_id}
					  onChange={(v) => handleChange(index, 'element_id', v.value)}
					  title={'Seleccionar el ID del activo con el cual se conecta'}
					  disabled={!isAdmin}
					/>
				  </Field>
				  <Field className={styles.nameContainer4}>
					<Select
					  aria-label={`relation ${index + 1}`}
					  className={styles.select}
					  options={localAssetPorts}
					  value={relation.port}
					  onChange={(v) => handleChange(index, 'port', v.value)}
					  title={'Seleccionar la interface local que se emplea para la conexión'}
					  disabled={!isAdmin}
					/>
				  </Field>
				  {isAdmin && (
				    <button 
				     type="button" 
				      onClick={() => handleRemoveParent(index)}
				     className={styles.button}
				   > 
				      <div className={styles.del_icon}>
				  	    <Icon name="trash-circle-fill" size="lg" />
					  </div>
				    </button>
				  )}
				</HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'data_link' && watch().subType === 'data_link' && (
			<CollapsibleSection 
			  label="Componentes del enlace"
			  className={styles.collapseContainer}
			  onChange={(isCollapsed) => {
			    if ((pluginVariables[13] && pluginVariables[13] !== '') || (pluginVariables[14] && pluginVariables[14] !== '')) {
				  const origin = elements.find((ele) => ele.uid === watch().origin);
				  const destination = elements.find((ele) => ele.uid === watch().destination);
				  const queryMap = {
					[`var-${pluginVariables[13]}`]: origin.idx,
					[`var-${pluginVariables[14]}`]: destination.idx,
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
					console.error(error);
				  }
				}
				updatePanelPort(watch().localPanel, 'local');
				updatePanelPort(watch().remotePanel, 'remote');
			  }}
			>
			  <HorizontalGroup key={'localData'} align="flex-start" height="55px">
				<Field 
				  label="Panel Local:"
				  className={styles.nameContainer}
				>
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="localPanel"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						if (value.value !== watch().remotePanel) {
						  onChange(mapSelectValueToString(value));
						  updatePanelPort(value.value, 'local');
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}
					  }}
					  options={panelIDs}
					  defaultValue={valueToLabel(field, panelIDs)}
					  title="Pasivo de conectividad local"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="localPanel"
				/>
				</Field>
				<Field 
				  label="Puerto:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="localPort"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						options={localPanelPorts}
						title="Puerto utilizado en el pasivo de conectividad para conecectar el activo"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="localPort"
				  />
				</Field>
				<Field 
				  label="Activo Local:"
				  className={styles.nameContainer}
				>
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="origin"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (pluginVariables[13] && pluginVariables[13] !== '') {
						  const origin = elements.find((ele) => ele.uid === value.value);
						  const queryMap = {
							[`var-${pluginVariables[13]}`]: origin.idx,
						  };
						  try {
							locationService.partial(queryMap, true);
						  } catch (error) {
							console.error(error);
						  }
						}
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={assetIDs}
					  defaultValue={valueToLabel(field, assetIDs)}
					  title="Activo local u origen del enlace"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="origin"
				/>
				</Field>
				<Field 
				  label="Interface:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="originPort"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						options={localAssetPorts}
						defaultValue={assetPortsToString(field, true)}
						title="Interface física o lógica utilizada en el activo origen"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="originPort"
				  />
				</Field>
			  </HorizontalGroup>
			  <HorizontalGroup key={'remoteData'} align="flex-start" height="55px">
				<Field 
				  label="Panel Remoto:"
				  className={styles.nameContainer}
				>
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="remotePanel"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						if (value.vlaue !== watch().localPanel) {
						  onChange(mapSelectValueToString(value));
						  updatePanelPort(value.value, 'remote');
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}
					  }}
					  options={panelIDs}
					  defaultValue={valueToLabel(field, panelIDs)}
					  title="Puerto en el pasivo de conectividad remoto donde se conecta el activo"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="remotePanel"
				/>
				</Field>
				<Field 
				  label="Puerto:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="remotePort"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						options={remotePanelPorts}
						title="Puerto utilizado en el panel remoto"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="remotePort"
				  />
				</Field>
				<Field 
				  label="Activo Remoto:"
				  className={styles.nameContainer}
				>
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="destination"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (pluginVariables[14] && pluginVariables[14] !== '') {
						  const destination = elements.find((ele) => ele.uid === value.value);
						  const queryMap = {
							[`var-${pluginVariables[14]}`]: destination.idx,
						  };
						  try {
							locationService.partial(queryMap, true);
						  } catch (error) {
							console.error(error);
						  }
						}
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={assetIDs}
					  invalid={watch().localPanel !== watch().remotePanel}
					  defaultValue={valueToLabel(field, assetIDs)}
					  title="Pasivo de conectividad remoto"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="destination"
				/>
				</Field>
				<Field 
				  label="Interface:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="destinationPort"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						options={remoteAssetPorts}
						defaultValue={assetPortsToString(field, false)}
						title="Interface física o lógica utilizada en el activo remoto"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="destinationPort"
				  />
				</Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'data_link' && watch().subType === 'inet_link' && (
			<CollapsibleSection 
			  label="Componentes del enlace"
			  className={styles.collapseContainer}
			  onChange={(isCollapsed) => {
			    if (pluginVariables[13] && pluginVariables[13] !== '') {
				  const origin = elements.find((ele) => ele.uid === watch().origin);
				  const queryMap = {
					[`var-${pluginVariables[13]}`]: origin.idx,
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
					console.error(error);
				  }
				}
				updatePanelPort(watch().remotePanel, 'remote');
			  }}
			>
			  <HorizontalGroup key={'localData'} align="flex-start" height="55px">
				<Field 
				  label="Activo Local:"
				  className={styles.nameContainer}
				>
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="origin"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (pluginVariables[13] && pluginVariables[13] !== '') {
						  const origin = elements.find((ele) => ele.uid === value.value);
						  const queryMap = {
							[`var-${pluginVariables[13]}`]: origin.idx,
						  };
						  try {
							locationService.partial(queryMap, true);
						  } catch (error) {
							console.error(error);
						  }
						}
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={assetIDs}
					  defaultValue={valueToLabel(field, assetIDs)}
					  title="Activo local u origen del enlace"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="origin"
				/>
				</Field>
				<Field 
				  label="Interface:"
				  className={styles.nameContainer}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
						aria-label="originPort"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						options={localAssetPorts}
						defaultValue={assetPortsToString(field, true)}
						title="Interface física o lógica utilizada en el activo local"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="originPort"
				  />
				</Field>
				<Field 
				  label="BW Download:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="bwDown"
					{...register('bwDown')}
					className={styles.input}
					type="number"
					min="0"
					max="1000000"
					step="1"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Ancho de banda de bajada contratado"
					suffix="Mbps"
					disabled={!isAdmin}
				  />
				</Field>
				<Field 
				  label="BW Upload:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="bwUp"
					{...register('bwUp')}
					className={styles.input}
					type="number"
					min="0"
					max="1000000"
					step="1"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Ancho de banda de subida contratado"
					suffix="Mbps"
					disabled={!isAdmin}
				  />
				</Field>
			  </HorizontalGroup>
			  <HorizontalGroup key={'remoteData'} align="flex-start" height="55px">
				<Field 
				  label="Direccionamiento IP:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="ip"
					{...register('ip')}
					className={styles.input}
					type="text"
					pattern="^(?:\d{1,3}\.){3}\d{1,3}$" 
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Direccionamiento IP asignado al enlaca"
					disabled={!isAdmin}
				  />
				</Field>
				<Field 
				  label="Mascara:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="mask"
					{...register('mask')}
					className={styles.input}
					type="text"
					pattern="^(?:\d{1,3}\.){3}\d{1,3}$" 
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Mascara de red"
					disabled={!isAdmin}
				  />
				</Field>
				<Field 
				  label="IP Gateway:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="gw"
					{...register('gw')}
					className={styles.input}
					type="text"
					pattern="^(?:\d{1,3}\.){3}\d{1,3}$" 
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Dirección IP del gateway asociado al enlace"
					disabled={!isAdmin}
				  />
				</Field>
				<Field 
				  label="FQDN Gateway:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="fqdnGw"
					{...register('fqdnGw')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Nombre del gateway asociado al enlace"
					disabled={!isAdmin}
				  />
				</Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'data_link' && (
			<CollapsibleSection 
			  label="Configuración del enlace"
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'dataLinkSettings'} align="flex-start" height="55px">
				<Field 
				  label="SLA esperado:"
				  className={styles.nameContainer}
				>
				  <Input
					aria-label="sla"
					{...register('sla')}
					className={styles.input}
					type="number"
					min="0"
					max="100"
					step="0.01"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="SLA objetivo o esperado para este enlace"
					suffix="%"
					disabled={!isAdmin}
				  />
				</Field>
				<Field 
				  label="Enlace Crítico:"
				  className={styles.switchContainer}
				>
				  <Switch 
					id="critic"
					className={styles.switchStyle} 
					disabled={!isAdmin}
					{...register('critic')}
				  />
				</Field>
				<Field 
				  label="Notificaciones activas:"
				  className={styles.switchContainer}
				>
				  <Switch
					id="notifEnable"
					className={styles.switchStyle} 
					disabled={!isAdmin}
					{...register('notifEnable')}
				  />
				</Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && (watch().elementType === 'segment' || (watch().elementType === 'element' && watch().subFamily !== 'termination' && watch().subFamily !== 'panels')) && (
			<HorizontalGroup key={'path'} align="flex-start" height="55px" > 
			  {watch().elementType === 'element' && (
				<Field 
				  label="ID de Trayecto:"
				  className={styles.nameContainer2}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
					    aria-label="pathId"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  if (watch().elementType !== 'span') {
						    updateElement(watch().elementType, value.value, watch().pathId);
						  }
						  onChange(mapSelectValueToString(value));
						  setPathId(value.value);
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
						}}
						options={pathIDs}
						defaultValue={field}
						title="Trayecto al que pertenece el elemento"
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="pathId"
				  />
				</Field>
			  )}
			  {(watch().elementType === 'segment' || watch().elementType === 'element') && (
				<Field 
				  label="ID de Enlace:"
				  className={styles.nameContainer2}
				>
				  <InputControl
					render={({ field: { onChange, ref, ...field } }) => (
					  <Select
					    aria-label="linkId"
						{...field}
						className={styles.select}
						onChange={(value) => {
						  updateSegments(value.value);
						  if (watch().elementType === 'segment') {
						    updateSegment(watch().capacity, value.value);
						  }
						  onChange(mapSelectValueToString(value));
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
						}}
						options={linkIDs}
						defaultValue={field}
						title={watch().elementType === 'segment' ? 
						  'Enlace al que pertenece el segmento' : 'Enlace al que pertenece el pasivo de distribución'
						}
						disabled={!isAdmin}
					  />
					)}
					control={control}
					name="linkId"
				  />
				</Field>
			  )}
			</HorizontalGroup>
		  )}
		  {watch().state && (watch().elementType === 'splicer' || watch().elementType === 'splitter') && (
			<HorizontalGroup key={'segment'} align="flex-start" height="55px" >
			  <Field 
				label="Segmento:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="segmentId"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={linkSegments}
					  defaultValue={valueToLabel(field, linkSegments)}
					  title="Segmento de enlace donde se realiza el empalme"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="segmentId"
				/>
			  </Field>
			  <Field 
				label={watch().subFamily === 'termination' ? 'Panel:' : 'Ubicación:'}
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
				    <Select
					  aria-label={watch().subFamily === 'termination' ? 'panelId' : 'pathEventId'}
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
					    onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={pathEvents}
					  defaultValue={valueToLabel(field, pathEvents)}
					  title="Ubicación (Elemento) donde se encuentra el empalme"
					  disabled={!isAdmin}
				    />
				  )}
				  control={control}
				  name={watch().subFamily === 'termination' ? 'panelId' : 'pathEventId'}
			    />
			  </Field>
			  {watch().subFamily === 'termination' && (
			    <>
				  <Field 
					label="Conector:"
					className={styles.nameContainer}
				  >
					<InputControl
					  render={({ field: { onChange, ref, ...field } }) => (
						<Select
						  aria-label="connector"
						  {...field}
						  className={styles.select}
						  onChange={(value) => {
							onChange(mapSelectValueToString(value));
						    if (!hasChanges) {
						      setHasChanges(true);
						    }
						  }}
						  options={fiberOpticConnectors}
						  defaultValue={valueToLabel(field, fiberOpticConnectors)}
						  title="Tipo de conector utilizado"
						  disabled={!isAdmin}
						/>
					  )}
					  control={control}
					  name="connector"
					/>
				  </Field>
				  <Field 
					label="Asignación:"
					className={styles.nameContainer}
				  >
					<InputControl
					  render={({ field: { onChange, ref, ...field } }) => (
						<Select
						  aria-label="duplex"
						  {...field}
						  className={styles.select}
						  onChange={(value) => {
							onChange(mapSelectValueToString(value));
						    if (!hasChanges) {
						      setHasChanges(true);
						    }
						  }}
						  options={valueToDuplex}
						  defaultValue={valueToLabel(field, valueToDuplex)}
						  title="Asignación del conector en el activo"
						  disabled={!isAdmin}
						/>
					  )}
					  control={control}
					  name="duplex"
					/>
				  </Field>
				</>
			  )}
			</HorizontalGroup>
		  )}
		  {watch().state && (watch().elementType === 'path' || watch().elementType === 'link' || watch().elementType === 'span') && (
			<HorizontalGroup key={'path'} align="flex-start" height="55px" > 
			  {watch().elementType !== 'path' && (
			    <Field 
				  label="ID de Trayecto:"
				  className={styles.nameContainer}
			    >
				  <InputControl
				    render={({ field: { onChange, ref, ...field } }) => (
				      <Select
				        aria-label="pathId"
				        {...field}
					    className={styles.select}
					    onChange={(value) => {
					      if (watch().elementType !== 'span') {
						    updateElement(watch().elementType, value.value, watch().pathId);
						  }
						  onChange(mapSelectValueToString(value));
						  setPathId(value.value);
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
					    }}
					    options={pathIDs}
					    defaultValue={field}
					    title="Trayecto al que pertenece el elemento"
						disabled={!isAdmin}
					  />
				    )}
				    control={control}
				    name="pathId"
				  />
			    </Field>
			  )}
			  <Field 
				label="Origen:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="origin"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={placeIDs}
					  defaultValue={valueToLabel(field, placeIDs)}
					  title="Emplazamiento origen del enlace"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="origin"
				/>
			  </Field>
			  <Field 
				label="Destino"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="destination"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={placeIDs}
					  defaultValue={valueToLabel(field, placeIDs)}
					  title="Emplazamiento destino del enlace"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="destination"
				/>
			  </Field>
			</HorizontalGroup>
		  )}
		  {watch().state && (watch().elementType === 'path' || watch().elementType === 'span' || (watch().elementType === 'link' && watch().origin !== watch().destination)) && !isAddMode && (
			<HorizontalGroup key={'location'}> 
			  <CollapsibleSection 
			    label="Trazado"
			    className={styles.collapseContainer}
			    onChange={(isCollapsed) => {
				  updateElement(watch().elementType, watch().pathId, watch().pathId);
				  setIsPathOpen(isCollapsed);
			    }}
			  >
			    {isPathOpen && tableMode && (
				  <div className={styles.mapContainerFull}>
				    {memoizedMapFull}
			      </div>
			    )}
			  </CollapsibleSection>
			</HorizontalGroup>
		  )}
		  {watch().state && (watch().elementType === 'element' || watch().elementType === 'emp' || watch().elementType === 'asset') && (
			<HorizontalGroup key={'location'}> 
			  <VerticalGroup>
			    <CollapsibleSection 
			      label="Ubicación"
			      className={styles.collapseContainer}
			      onChange={(isCollapsed) => {
			        if (watch().elementType !== 'asset') {
					  updateElement(watch().elementType, watch().pathId, watch().pathId);
					}
				    if (coordinates.length > 0) {
				      setIsLocationOpen(isCollapsed);
				    }
			      }}
			    >
				  <HorizontalGroup key={'coordinates'} align="flex-start" height="55px"> 
				    <Field 
				      label="Coordenadas [lat, lon]:"
				      className={styles.nameContainer}
			        >
				      <Input
					    aria-label="coordinates"
					    className={styles.input}
					    value={coordinates.join(', ')}
					    onChange={(e) => {
						  const isReady = handleCoordsChange(e.target.value);
						  setIsLocationOpen(isReady);
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
						}}
					    type="text"
					    placeholder="lat, lon"
					    title="Coordenadas geograficas en el formato [lat, lon], con latitud y longitud numéricas"
						disabled={!isAdmin}
				      />
			        </Field>
			        {watch().elementType !== 'asset' && (
					  <Field 
				        label="Elevación:"
				        className={styles.nameContainer4}
			          >
						<Input
						  aria-label="elevation"
						  {...register('elevation')}
						  className={styles.input}
						  type="number"
						  min="0"
						  max="5000"
						  step="1"
						  onChange={(value) => {
							if (!hasChanges) {
							  setHasChanges(true);
							}
						  }}
						  title="Elevación del sitio en mts"
						  disabled={!isAdmin}
						  suffix="Mts"
				        />
			          </Field>
					)}
				  </HorizontalGroup>
			      {watch().elementType === 'element' || watch().elementType === 'asset' && (
				    <HorizontalGroup>
				      <Field 
				        label="Emplazamiento:"
				        className={styles.textContainer2}
			          >
					    <Field className={styles.nameContainer4}>
						  <Select
						    aria-label={`emp`}
						    className={styles.select}
						    options={places}
						    value={coordinatesToPlace(coordinates)}
						    onChange={(v) => {
							  const isReady = handleCoordsChange(v.lat + ',' + v.lng);
						      setIsLocationOpen(isReady);
							  if (!hasChanges) {
							    setHasChanges(true);
							  }
						    }}
						    title="Seleccionar el ID del elemento"
							disabled={!isAdmin}
						  />
					    </Field>
			          </Field>
				    </HorizontalGroup>
				  )}
			      <HorizontalGroup>
				    <Field 
				      label="Dirección:"
				      className={styles.textContainer2}
			        >
					  <TextArea
						aria-label="address"
						{...register('address')}
						className={styles.textArea}
						rows="4"
						type="text"
						onChange={(value) => {
						  if (!hasChanges) {
							setHasChanges(true);
						  }
						}}
						title="Dirección postal del sitio"
						disabled={!isAdmin}
				      />
			        </Field>
				  </HorizontalGroup>
			    </CollapsibleSection>
				{watch().elementType === 'element' && (
				  <CollapsibleSection 
				    label="Relaciones del Elemento"
				    className={styles.collapseContainer} 
				    onChange={(isCollapsed) => {
					  updateParentMarkers(relations);
					  if (relations.length > 0) {
					    setIsParentOpen(isCollapsed);
					  }
				    }}
				  >
				    <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
					  <Label className={watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? styles.nameContainer4 : styles.nameContainer} >
					    {watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? 'ID Elemento' : 'ID Segmento'}
					  </Label>
					  {watch().subFamily !== 'termination' && watch().subFamily !== 'panels' && 
					    <Label className={styles.nameContainer4} >{'Distancia'}</Label>
					  }
					  <Label className={styles.nameContainer4} >
					    {watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? 'Capacidad' : 'Cantidad'}
					  </Label>
					  {isAdmin && (
					    <button 
					      type="button" 
					      className={styles.button} 
					      onClick={() => {
						    handleAddParent();
						    if (!hasChanges) {
							  setHasChanges(true);
						    }
					      }}
					      title={'Agregar una nueva relación'}
					    >
					      <div className={styles.add_icon}>
						    <Icon name="plus-circle-fill" size="lg" />
					      </div>
					    </button>
					  )}
				    </HorizontalGroup>
				    {relations.map((parent, index) => (
					  <HorizontalGroup key={'conexion_data'} align="flex-start" height={'35px'} >
					    <Field className={watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? styles.nameContainer4 : styles.nameContainer}>
						  <Select
						    aria-label={`parent ${index + 1}`}
						    className={styles.select}
						    options={watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? pathEvents : segmentIds}
						    value={parent.element_id}
						    onChange={(v) => handleChange(index, 'element_id', v.value)}
						    title={watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? 
							  'Seleccionar el ID del elemento' : 
							  'Seleccionar el ID del segmento que se distribuye o contectoriza'
							}
							disabled={!isAdmin}
						  />
					    </Field>
						{watch().subFamily !== 'termination' && watch().subFamily !== 'panels' && (
						  <Field className={styles.nameContainer4}>
						    <Input
						      aria-label={`distance ${index + 1}`}
						      className={styles.input}
						      type="number"
						      step="1"
							  min="0"
							  value={parent.distance}
						      onChange={(e) => handleChange(index, 'distance', e.currentTarget.value)}
						      placeholder="Distancia total al elemento"
						      title="Ingresar la distancia"
							  disabled={!isAdmin}
						    />
					      </Field>
						)}
					    <Field className={styles.nameContainer4}>
						  <Input
						    aria-label={`capacity ${index + 1}`}
						    className={styles.input}
						    type="number"
							step="1"
							min="1"
						    value={parent.capacity}
						    onChange={(e) => handleChange(index, 'capacity', e.currentTarget.value)}
						    placeholder="Capacidad..."
						    title={watch().subFamily !== 'termination' && watch().subFamily !== 'panels' ? 
							  'Ingresar la capacidad' : 'Ingresar la cantidad de medios que se distribuyen/contectorizan del segmento'
							}
							disabled={!isAdmin}
						  />
					    </Field>
					    {isAdmin && (
						  <button 
						   type="button" 
						    onClick={() => handleRemoveParent(index)}
						   className={styles.button}
					      > 
						    <div className={styles.del_icon}>
						      <Icon name="trash-circle-fill" size="lg" />
						    </div>
					      </button>
						)}
					  </HorizontalGroup>
				    ))}
				  </CollapsibleSection>
				)}
			  </VerticalGroup>
			  {(isLocationOpen || isParentOpen) && coordinates && coordinates !== null && tableMode && (
			    <VerticalGroup>
			      <div className={(isLocationOpen && isParentOpen) ? styles.mapContainer2 : styles.mapContainer}>
			        {memoizedMap}
			      </div>
			    </VerticalGroup>
			  )}
			</HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'segment' && (
			<HorizontalGroup key={'general'} align="flex-start" height="55px" >  
			  <Field 
				label="Origen:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="origin"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={[...panelIDs, ...assetIDs]}
					  defaultValue={valueToLabel(field, [...panelIDs, ...assetIDs])}
					  title="Activo o Panel local del segmento"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="origin"
				/>
			  </Field>
			  <Field 
				label="Destino:"
				className={styles.nameContainer}
			  >
				<InputControl
				  render={({ field: { onChange, ref, ...field } }) => (
					<Select
					  aria-label="destination"
					  {...field}
					  className={styles.select}
					  onChange={(value) => {
						onChange(mapSelectValueToString(value));
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  options={[...panelIDs, ...assetIDs]}
					  defaultValue={valueToLabel(field, [...panelIDs, ...assetIDs])}
					  title="Activo o Panel remoto del segmento"
					  disabled={!isAdmin}
					/>
				  )}
				  control={control}
				  name="destination"
				/>
			  </Field>
			  {(parentSubType === 'fo_path' || parentSubType === 'ge_path' || parentSubType === 'utp_path') && (
			    <Field 
				  label="Capacidad:"
				  className={styles.nameContainer4}
			    >
				  <Input
				    aria-label="capacity"
				  	className={styles.input}
					type="number"
					step="1"
					min="1"
				    value={linkCapacity}
					onChange={(e) => {
					  setLinkCapacity(e.currentTarget.value);
					  updateSegment(e.currentTarget.value, watch().linkId);
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
					}}
					title={parentSubType === 'fo_path' ? 'Cantidad de fibras en el cable' : 'Cantidad de pares en el cable'}
					disabled={!isAdmin}
				  />
			    </Field>
			  )}
			  {(parentSubType === 'sa_path' || parentSubType === 're_path') && (
			    <Field 
				  label="Capacidad:"
				  className={styles.nameContainer4}
			    >
				  <Input
					aria-label="capacity"
				    {...register('capacity')}
					className={styles.input}
					onChange={(value) => {
					  updateSegment(1, watch().linkId);
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					type="number"
					step="1"
					min="1"
					title={'Capacidad Total de Transporte en Mbps'}
					disabled={!isAdmin}
				  />
			    </Field>
			  )}
			  <Field 
				label="Caracteristicas:"
				className={styles.nameContainer}
			  >
				<Input
				  aria-label="sectionType"
				  {...register('sectionType')}
				  className={styles.input}
				  type="text"
				  onChange={(value) => {
					if (!hasChanges) {
					  setHasChanges(true);
					}
				  }}
				  placeholder="Tipo de sección"
				  title="Caracteristicas principales de la sección"
				  disabled={!isAdmin}
				/>
			  </Field>
			</HorizontalGroup>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType === 'radio' && (parentSubType === 're_path' || parentSubType === 'sa_path') && (
			<CollapsibleSection 
			  label="Detalles del Radio Enlace" 
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Frecuencia (Canal)'}</Label>
				<Label className={styles.nameContainer4} >{'Ancho de Banda'}</Label>
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
				<Label className={styles.nameContainer4} >{'SNR'}</Label>
			    {isAdmin && watch().capacity > sublinks.length && (
				  <button 
				    type="button" 
				    className={styles.button} 
				    onClick={() => {
				      handleAddSublink();
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
				    }}
				    title={'Agregar una nueva relación'}
			      >
				    <div className={styles.add_icon}>
				      <Icon name="plus-circle-fill" size="lg" />
				   </div>
			      </button>
				)}
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  disabled={!isAdmin}
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`channel ${index + 1}`}
				  	  className={styles.input}
					  type="number"
					  min="1"
				      value={sublink.buffer}
				      onChange={(e) => handleChangeSublink(index, 'buffer', e.currentTarget.value)}
					  title="Canal o Frecuencia de operación en MHz"
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`bw ${index + 1}`}
				  	  className={styles.input}
					  type="number"
					  min="1"
				      value={sublink.color}
				      onChange={(e) => handleChangeSublink(index, 'color', e.currentTarget.value)}
					  title="Ancho de banda del Canal en MHz"
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
					  min="1"
					  step="1"
				      value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  placeholder="Distancia del segmento"
					  title="Ingresar la distancia total del segmento de enlace en mts"
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
					  aria-label={`capacity ${index + 1}`}
					  className={styles.input}
					  type="number"
					  min="0"
					  value={sublink.attenuation}
					  onChange={(e) => handleChangeSublink(index, 'attenuation', e.currentTarget.value)}
					  placeholder="SNR"
					  title="Ingresar Relación Señal-Ruido del enlace en dB obtenida en la puesta en servicio"
					  disabled={!isAdmin}
				    />
				  </Field>
                  {isAdmin && (
				    <button 
				      type="button" 
					  onClick={() => handleRemoveSublink(index)}
					  className={styles.button}
				    >
				      <div className={styles.del_icon}>
					    <Icon name="trash-circle-fill" size="lg" />
				      </div>
                    </button>
				  )}
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType !== 'radio' && parentSubType === 'fo_path' && (
			<CollapsibleSection 
			  label="Detalles del Cable" 
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Buffer'}</Label>
				<Label className={styles.nameContainer4} >{'Color'}</Label>
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
				<Label className={styles.nameContainer4} >{'Atenuación'}</Label>
			    {isAdmin && watch().capacity > sublinks.length && (
			      <button 
				    type="button" 
				    className={styles.button} 
				    onClick={() => {
				      handleAddSublink();
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
				    }}
				    title={'Agregar una nueva relación'}
			      >
				    <div className={styles.add_icon}>
				      <Icon name="plus-circle-fill" size="lg" />
				    </div>
			      </button>
				)}
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  disabled={!isAdmin}
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Select
					  aria-label={`buffer ${index + 1}`}
					  className={styles.select}
					  options={bufferColors}
					  value={sublink.buffer}
					  onChange={(v) => handleChangeSublink(index, 'buffer', v.value)}
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Select
					  aria-label={`color ${index + 1}`}
					  className={styles.select}
					  options={fiberOpticColors}
					  value={sublink.color}
					  onChange={(v) => handleChangeSublink(index, 'color', v.value)}
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
					  min="0"
				      value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  title="Ingresar la distancia total del segmento de enlace en mts"
					  disabled={!isAdmin}
				    />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
					  aria-label={`attenuation ${index + 1}`}
					  className={styles.input}
					  type="number"
					  min="0"
					  value={sublink.attenuation}
					  onChange={(e) => handleChangeSublink(index, 'attenuation', e.currentTarget.value)}
					  title="Ingresar la atenuación total del segmento de enlace en dB"
					  disabled={!isAdmin}
				    />
				  </Field>
                  {isAdmin && (
				    <button 
				      type="button" 
					  onClick={() => handleRemoveSublink(index)}
					  className={styles.button}
				    >
				      <div className={styles.del_icon}>
					    <Icon name="trash-circle-fill" size="lg" />
				      </div>
                    </button>
				  )}
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'segment' && watch().subType !== 'radio' && 
		    parentSubType === 'ge_path' && parentSubType === 'utp_path' && (
			<CollapsibleSection 
			  label="Detalles del Cable" 
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'new_conexions'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer} >{'Sublink ID'}</Label>
				<Label className={styles.nameContainer4} >{'Grupo'}</Label>
				{parentSubType === 'utp_path' && (
				  <Label className={styles.nameContainer4} >{'Color'}</Label>
				)}
				<Label className={styles.nameContainer4} >{'Distancia'}</Label>
			    {isAdmin && watch().capacity > sublinks.length && (
			      <button 
				    type="button" 
				    className={styles.button} 
				    onClick={() => {
				      handleAddSublink();
					  if (!hasChanges) {
					    setHasChanges(true);
					  }
				    }}
				    title={'Agregar una nueva relación'}
			      >
				    <div className={styles.add_icon}>
				      <Icon name="plus-circle-fill" size="lg" />
				    </div>
			      </button>
				)}
			  </HorizontalGroup>
			  {sublinks.map((sublink, index) => (
			    <HorizontalGroup key={'sublink_data'} align="flex-start" height={'35px'} >
				  <Field className={styles.nameContainer}>
				    <Select
					  aria-label={`sublinkId ${index + 1}`}
					  className={styles.select}
					  options={segmentSubLinks}
					  value={sublink.sublinkId}
					  onChange={(v) => handleChangeSublink(index, 'sublinkId', v.value)}
					  title="Seleccionar el ID del elemento"
					  disabled={!isAdmin}
					  />
				  </Field>
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`buffer ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      step="1"
					  min="1"
					  value={sublink.buffer}
				      onChange={(e) => handleChangeSublink(index, 'buffer', e.currentTarget.value)}
					  title="Ingrese el número de grupo o conjunto de pares"
					  disabled={!isAdmin}
				    />
				  </Field>
				  {parentSubType === 'utp_path' && (
				    <Field className={styles.nameContainer4}>
				      <Select
					    aria-label={`color ${index + 1}`}
					    className={styles.select}
					    options={multiparColors}
					    value={sublink.color}
					    onChange={(v) => handleChangeSublink(index, 'color', v.value)}
						disabled={!isAdmin}
				      />
				    </Field>
				  )}
				  <Field className={styles.nameContainer4}>
				    <Input
				      aria-label={`distance ${index + 1}`}
				  	  className={styles.input}
					  type="number"
				      step="1"
					  min="0"
					  value={sublink.distance}
				      onChange={(e) => handleChangeSublink(index, 'distance', e.currentTarget.value)}
					  title="Ingresar la distancia total del segmento de enlace en mts"
					  disabled={!isAdmin}
				    />
				  </Field>
                  {isAdmin && (
				    <button 
				      type="button" 
					  onClick={() => handleRemoveSublink(index)}
					  className={styles.button}
				    >
				      <div className={styles.del_icon}>
					    <Icon name="trash-circle-fill" size="lg" />
				      </div>
                    </button>
				  )}
			    </HorizontalGroup>
			  ))}
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType === 'asset' && (
			<CollapsibleSection 
			  label="Información de compra"
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'adquisitionData'} align="flex-start" height="55px">
			    <Field 
				  label="Proyecto:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="project"
					{...register('project')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Proyecto asociado a la compra de este activo"
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Orden de Compra:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="purchaseOrder"
					{...register('purchaseOrder')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Número de orden de compra o expediente utilizado para la adquisición "
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Fecha de Compra:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="purchaseDate"
					{...register('purchaseDate')}
					className={styles.input}
					type="date"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Fecha de recepción del activo"
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Proveedor:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="supplier"
					{...register('supplier')}
					className={styles.input}
					style={{ fontSize: 12, textAlign: 'left' }}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Proyecto asociado a la compra de este activo"
					disabled={!isAdmin}
				  />
			    </Field>
			  </HorizontalGroup>
			  <HorizontalGroup key={'warranty'} align="flex-start" height="55px">
			    <Field 
				  label="Fecha de Entrega:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="deliveryDate"
					{...register('deliveryDate')}
					className={styles.input}
					type="date"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Fecha de recepción del activo"
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Nota de Entrega:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="deliveryNote"
					{...register('deliveryNote')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Número de remito o nota de entrega"
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Factura:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="invoiceNumber"
					{...register('invoiceNumber')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Número de factura o comprobante de compra"
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Números de serie:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="serialNumber"
					{...register('serialNumber')}
					className={styles.input}
					type="text"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					title="Números de serie o identificadores del hardware entregado"
					disabled={!isAdmin}
					suffix={<Icon name="help-fill" size="xs" title="Para más de un número de serie, ingrese los numeros separados por coma" />}
				  />
			    </Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && watch().elementType !== 'data_link' && (
			<CollapsibleSection 
			  label="Instalación y Mantenimiento" 
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'instalation'} align="flex-start" height="55px" >  
			    <Field 
				  label="Instalación:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="installationDate"
					{...register('serialNumber')}
					className={styles.input}
					type="date"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					placeholder="ingrese una fecha"
					title="Fecha de instalación."
					disabled={!isAdmin}
				  />
			    </Field>
			    <Field 
				  label="Ultima inspección:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="lastInspection"
					{...register('lastInspection')}
					className={styles.input}
					type="date"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					placeholder="ingrese una fecha"
					title="Fecha de la última inspección realizada al enlace"
					disabled={!isAdmin}
				  />
			    </Field>
				{(watch().elementType === 'asset' || watch().elementType === 'emp') && (
				  <Field 
				    label="Cuadrilla:"
				    className={styles.nameContainer}
			      >
					<InputControl
					  render={({ field: { onChange, ref, ...field } }) => (
					    <Select
						  aria-label="technician"
						  {...field}
						  className={styles.select}
						  onChange={(value) => {
						    onChange(mapSelectValueToString(value));
						    if (!hasChanges) {
						      setHasChanges(true);
						    }
						  }}
						  options={manteinanceIDs}
						  defaultValue={field}
						  title="Nombre de la cuadrilla asignada para su mantenimiento"
						  disabled={!isAdmin}
						/>
					  )}
					  control={control}
					  name="technician"
					/>
			      </Field>
				)}
				{(watch().elementType === 'splicer' || watch().elementType === 'splitter') && (
				  <Field 
				    label="Responsable:"
				    className={styles.nameContainer}
			      >
					<Input
					  aria-label="technician"
					  {...register('technician')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Nombre del técnico que realizo el último mantenimiento"
					  disabled={!isAdmin}
				    />
			      </Field>
				)}
			    <Field 
				  label="Próximo mantenimiento:"
				  className={styles.nameContainer}
			    >
				  <Input
					aria-label="nextMaintenance"
					{...register('nextMaintenance')}
					className={styles.input}
					type="date"
					min={todayISO}
					max={maxDateISO}
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					placeholder="ingrese una fecha"
					title="Fecha programada para el siguiente mantenimiento"
					disabled={!isAdmin}
				  />
			    </Field>
			  </HorizontalGroup>
			  {watch().elementType === 'asset' && (
			    <HorizontalGroup key={'warranty'} align="flex-start" height="55px">
			      <Field 
				    label="Soporte HardWare finaliza:"
				    className={styles.nameContainer}
			      >
					<Input
					  aria-label="warrantyHWExp"
					  {...register('warrantyHWExp')}
					  className={styles.input}
					  type="date"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Fecha de vencimiento de la garantía asociada al activo"
					  disabled={!isAdmin}
				    />
			      </Field>
			      <Field 
				    label="Números de Contrato:"
				    className={styles.nameContainer}
			      >
					<Input
					  aria-label="hwContractNumber"
					  {...register('hwContractNumber')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Numero de contrato u orden de compra asociada al servicio de soporte de HW"
					  disabled={!isAdmin}
					  suffix={<Icon name="help-fill" size="xs" title="Para más de un contrato, ingrese los numeros separados por coma" />}
				    />
			      </Field>
			      <Field 
				    label="Soporte SoftWare finaliza:"
				    className={styles.nameContainer}
			      >
					<Input
					  aria-label="warrantyExp"
					  {...register('warrantyExp')}
					  className={styles.input}
					  type="date"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Fecha de vencimiento de la garantía asociada al activo"
					  disabled={!isAdmin}
				    />
			      </Field>
			      <Field 
				    label="Números de Contrato:"
				    className={styles.nameContainer}
			      >
					<Input
					  aria-label="contractNumber"
					  {...register('contractNumber')}
					  className={styles.input}
					  type="text"
					  onChange={(value) => {
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Numero de contrato u orden de compra asociada al servicio de soporte de SW"
					  disabled={!isAdmin}
					  suffix={<Icon name="help-fill" size="xs" title="Para más de un contrato, ingrese los numeros separados por coma" />}
				    />
			      </Field>
			    </HorizontalGroup>
			  )}
			  <HorizontalGroup key={'notes'} align="flex-start" height="110px" > 
			    <Field 
		          label="Notas:"
			      className={styles.nameContainer3}
			    >
				  <TextArea
					aria-label="installationNotes"
					{...register('installationNotes')}
					className={styles.textArea}
					rows="2"
					onChange={(value) => {
					  if (!hasChanges) {
						setHasChanges(true);
					  }
					}}
					placeholder="Notas de la instalación"
					title="Notas relacionadas con la instalación o sus modificaciones"
					disabled={!isAdmin}
			      />
			    </Field>
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {watch().state && (
			<CollapsibleSection 
			  label="Estilo" 
			  className={styles.collapseContainer}
			>
			  <HorizontalGroup key={'style_header'} align="flex-start" height={'25px'} >
				<Label className={styles.nameContainer}>
				  {'Color'}
				</Label>
				{watch().state && watch().elementType === 'emp' && (
				  <Label className={styles.nameContainer} >{'Tamaño del icono'}</Label>
				)}
				{watch().state && (watch().elementType === 'path' || watch().elementType === 'link' ||
				  watch().elementType === 'segment' || watch().elementType === 'span') && (
				  <>
					<Label className={styles.nameContainer} >{'Ancho de la linea'}</Label>
					<Label className={styles.nameContainer} >{'Formato de la linea'}</Label>
				  </>
				)}
			  </HorizontalGroup>
			  <HorizontalGroup key={'style'} align="flex-start" height="55px" >  
				  <Field className={styles.nameContainer}>
				    <ColorPickerInput
					  className={styles.input}
					  value={elementStyle.color}
					  returnColorAs={'hex'}
					  onChange={(color: string) => {
						handleChangeStyle('color', color);
						if (!hasChanges) {
						  setHasChanges(true);
						}
					  }}
					  title="Ingrese el color que desea asignar al elemento"
					  disabled={!isAdmin}
				    />
				  </Field>
				  {watch().state && watch().elementType === 'emp' && (
				    <Field className={styles.nameContainer}>
					  <Slider
				        ariaLabelForHandle={`size`}
				  	    className={styles.input}
						included
						marks={24}
						max={48}
						min={12}
						orientation="horizontal"
						value={elementStyle.size}
					    onChange={(value) => {
						  handleChangeStyle('size', value);
						  if (!hasChanges) {
						    setHasChanges(true);
						  }
					    }}
					    title="Tamaño del icono"
						disabled={!isAdmin}
				      />
				    </Field>
				  )}
				  {watch().state && (watch().elementType === 'path' || watch().elementType === 'link' ||
				    watch().elementType === 'segment' || watch().elementType === 'span') && (
					<>
					  <Field className={styles.nameContainer}>
						<Select
						  aria-label={`lineWeight`}
						  className={styles.select}
						  onChange={(option) => {
							handleChangeStyle('weight', option.value);
						    if (!hasChanges) {
						      setHasChanges(true);
						    }
						  }}
						  options={lineWidths}
						  defaultValue={widthToOption(elementStyle.weight)}
						  title="Ancho de la linea"
						  disabled={!isAdmin}
						/>
					  </Field>
					  <Field className={styles.nameContainer}>
						<Select
						  aria-label={`lineType`}
						  className={styles.select}
						  onChange={(option) => {
							handleChangeStyle('dashArray', option.value);
						    if (!hasChanges) {
						      setHasChanges(true);
						    }
						  }}
						  options={lineTypes}
						  defaultValue={dashArrayToOption(elementStyle.dashArray)}
						  title="Formato de la linea"
						  disabled={!isAdmin}
						/>
					  </Field>
					</>
				  )}
			  </HorizontalGroup>
			</CollapsibleSection>
		  )}
		  {hasChanges && isAdmin && (
			<div className={styles.buttonGroup}>
		      <button 
			    type="submit"
			    className={styles.okButton} 
			    title={'Guardar modificaciones'}
			  >
			    <div className={styles.del_icon}>
			      <Icon name={'save'} size="lg" />
			    </div>
			    {'Guardar'}
			  </button>
			  <button 
			    type="button" 
			    className={styles.cancelButton} 
			    onClick={() => {
			      if (!tableMode) {
			        if (pluginVariables[9] && pluginVariables[9] !== '' && pluginVariables[1] && pluginVariables[1] !== '') {
				      const queryMap = {
					    [`var-${pluginVariables[1]}`]: '',
					    [`var-${pluginVariables[9]}`]: '',
				      };
				      try {
					    locationService.partial(queryMap, true);
				      } catch (error) {
					    console.error(error);
				      }
				    }
				  }
			      onCancel();
			    }}
			    title={'Cerrar sin modificar'}
			  >
			    <div className={styles.del_icon}>
			      <Icon name="times-fill" size="lg" />
			    </div>
			    {'Cerrar'}
			  </button>
            </div>
		  )}
		  {!hasChanges && (
			<div className={styles.buttonGroup}>
			  <button 
			    type="button" 
			    className={styles.cancelButton} 
			    onClick={() => {
			      if (!tableMode) {
			        if (pluginVariables[9] && pluginVariables[9] !== '' && pluginVariables[1] && pluginVariables[1] !== '') {
				      const queryMap = {
					    [`var-${pluginVariables[1]}`]: '',
					    [`var-${pluginVariables[9]}`]: '',
				      };
				      try {
					    locationService.partial(queryMap, true);
				      } catch (error) {
					    console.error(error);
				      }
				    }
				  }
			      onCancel();
			    }}
			    title={'Cerrar ventana de edición'}
			  >
			    <div className={styles.del_icon}>
			      <Icon name="times-fill" size="lg" />
			    </div>
			    {'Cerrar'}
			  </button>
			  {isAdmin && (
			    <button 
			      type="button" 
			      className={styles.deleteButton} 
			      onClick={() => {
			        handleSubmit([], watch().uid, 'delete');
				    onCancel();
			      }}
			      title={'Eliminar elemento seleccionado'}
			    >
			      <div className={styles.del_icon}>
			        <Icon name="trash" size="lg" />
			      </div>
			      {'Eliminar'}
			    </button>
			  )}
            </div>
		  )}
        </>
      )}
    </Form>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const deleteButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const closeButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    mapContainer: css`
	  width: ${width * 0.47}px;
	  min-width: 250px;
	  height: 250px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    mapContainer2: css`
	  width: ${width * 0.47}px;
	  min-width: 250px;
	  height: 350px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    mapContainerFull: css`
	  width: ${width * 0.95}px;
	  min-width: 250px;
	  height: 350px;
      margin-top: 5px;
      margin-left: -10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
	formContainer: css`
      max-width: 100%;
	  border-top: 1px solid ${buttonBorder};
      margin-top: 5px;
      padding-top: 10px;
      margin-left: -10px;
    `,
	collapseContainer: css`
      width: ${width * 0.47}px;
	  min-width: 50px;
	  margin-right: 15px;
	  font-size: 11px;
	  color: ${textColor};
	  box-shadow: none;
      margin-bottom: 15px;
    `,
    nameContainer: css`
      width: ${(width * 0.23) - 15}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  text-align: left;
    `,
    nameContainer2: css`
      width: ${(width * 0.46) - 5}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  text-align: left;
    `,
    nameContainer3: css`
      width: ${(width * 0.92) + 15}px;
	  min-width: 50px;
	  color: ${textColor};
	  text-align: left;
    `,
    nameContainer4: css`
      width: ${(width * 0.115) - 15}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  text-align: left;
    `,
    textContainer: css`
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  text-align: left;
    `,
    textContainer2: css`
      width: ${(width * 0.46) - 5}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  text-align: left;
    `,
    switchContainer: css`
      width: ${(width * 0.23) - 15}px;
	  min-width: 50px;
	  margin-right: 15px;
    `,
    buttonGroup: css`
      margin: 16px 0px 32px;

      & > * + * {
        margin-left: 8px;
      }
    `,
    addButton: css`
      margin: 0px;
	  width: 100%;
	  align-items:right;
      & > * + * {
        margin-right: 8px;
      }
    `,
    labelStyle: css`
      width: ${(width * 0.46) - 5}px;
	  min-width: 50px;
	  margin-right: 15px;
	  color: ${textColor};
	  font-size: 16px;
	  text-align: left;
    `,
    switchStyle: css`
	  width: 32px;
      height: 16px;
      position: relative;
	  top: 4px;
    `,
    select: css`
      flex: 1;
		: 90%;
	  max-width: 350px;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 12px;
	  text-align: left;
    `,
    textArea: css`
      flex: 1;
      width: 90%;
	  margin-top: 2px;
	  font-size: 12px;
	  text-align: left;
    `,
    input: css`
      flex: 1;
      width: 90%;
	  margin-left: 5px;
	  margin-top: 10px;
	  height: 18px;
	  font-size: 12px;
	  text-align: left;
    `,
    deleteButton: css`
      background-color: ${deleteButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    cancelButton: css`
      background-color: ${closeButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: ${textColor};
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    button: css`
      background-color: transparent;
      border: none;
      color: ${textColor};
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 4px 0px 4px;
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    del_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
