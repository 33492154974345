import { SelectableValue } from '@grafana/data';
import { generarID } from './L1OyMTable';
import moment from 'moment';

export interface L1OyMPanelOptions {
  uid: string;
  title: string;
  technicalDetails: string;
  updated_at: string;
  created_at: string;
  user: string;
  version: string;
  api: string;
  mapSource: string;
  uidVariable: string;
  useZoomVariable: boolean;
  zoomVariable: string;
  xVariable: string;
  yVariable: string;
  variableToTrack: string;
  processList: string;
  servicesList: string;
  appsList: string;
  assetList: string;
  localAssetPortList: string;
  remoteAssetPortList: string;
  productList: string;
  siteList: string;
  placeList: string;
  placeTypeList: string;
  localAssetVariable: string;
  remoteAssetVariable: string;
  placeVariable: string;
  placeTypesVariable: string;
  linkList: string;
  intetLinkList: string;
  showTableModeVariable: string;
  pluginTitle: string;
  successMessage: string;
  editorCanAdmin: boolean;
  showTitle: boolean;
  showPlaceSelector: boolean;
  drillDownLink_asset: string;
  drillDownLink_site: string;
  error4: string;
}

export const valueToSubtype: SelectableValue[] = [
  { label: 'Dependencia', description: 'Edificio/Oficina donde opera una sucursal o dependencia', value: 'emp', family: 'emp', subFamily: 'any', acronim: 'EMP' },
  { label: 'Nodo', description: 'Sitio donde se ubica un nodo de comunicaciones', value: 'node', family: 'emp', subFamily: 'any', acronim: 'NODE' },
  { label: 'Centro de Datos', description: 'Centro de datos que opera dentro de una dependencia o nodo de red', value: 'data_center', family: 'emp', subFamily: 'internal', acronim: 'DC' },
  { label: 'Cuarto de Cableado', description: 'Gabinete o habitación con elementos de conectividad', value: 'wiring_room', family: 'emp', subFamily: 'internal', acronim: 'ROOM' },
  { label: 'Mastil', description: 'Mastil que posee elementos de conectividad', value: 'mast', family: 'emp', subFamily: 'external', acronim: 'MAST' },
  { label: 'Poste', description: 'Poste que posee elementos de conectividad', value: 'pole', family: 'emp', subFamily: 'external', acronim: 'POLE' },
  { label: 'Trayecto', description: 'Uno o más secciones que conectan fisicamente emplazamientos', value: 'path', family: 'path', subFamily: 'any', acronim: 'PATH' },
  { label: 'Tramo Subterráneo', description: 'Sección compuesta de ductos de PVC o acero', value: 'underground_span', family: 'span', subFamily: 'any', acronim: 'SPAN' },
  { label: 'Tramo Aereo', description: 'Sección instalada sobre postes, torres o estructuras elevadas', value: 'aerial_span', family: 'span', subFamily: 'any', acronim: 'SPAN' },
  { label: 'Enlace de Cobre', description: 'Enlace generico con multipares entre emplazamientos', value: 'ge_path', family: 'link', subFamily: 'wired', acronim: 'LINK' },
  { label: 'Enlace UTP/FTP/STP ', description: 'Enlace entre emplazamientos utilizando pares trenzados', value: 'utp_path', family: 'link', subFamily: 'wired', acronim: 'LINK' },
  { label: 'Enlace Optico', description: 'Enlace de fibra optica entre emplazamientos', value: 'fo_path', family: 'link', subFamily: 'wired', acronim: 'LINK' },
  { label: 'Enlace de Radio', description: 'Enlace de Radio entre emplazamientos', value: 're_path', family: 'link', subFamily: 'wireless', acronim: 'LINK' },
  { label: 'Enlace Satelital', description: 'Enlace Satelital entre emplazamientos', value: 'sa_path', family: 'link', subFamily: 'wireless', acronim: 'LINK' },
  { label: 'Cable Subterráneo', description: 'Segmento subterraneo entre elementos', value: 'underground_cable', family: 'segment', subFamily: 'wired', acronim: 'SEG' },
  { label: 'Cable', description: 'Segmento entre elementos', value: 'cable', family: 'segment', subFamily: 'wired', acronim: 'SEG' },
  { label: 'Enlace de Radio', description: 'Segmento inalambrico entre elementos', value: 'radio', family: 'segment', subFamily: 'wireless', acronim: 'SEG' },
  { label: 'Fusión', description: 'Fusion de hilos de fibra óptica', value: 'fusion', family: 'splicer', subFamily: 'termination', acronim: 'DIST' },
  { label: 'Conector', description: 'Interconexión empleando conectores mecanicos', value: 'connector', family: 'splicer', subFamily: 'termination', acronim: 'DIST' },
  { label: 'FBT Splitter', description: 'Fused Biconical Taper', value: 'fbt', family: 'splitter', subFamily: 'termination', acronim: 'DIST' },
  { label: 'PLC Splitter', description: 'Planar Lightwave Circuit', value: 'plc', family: 'splitter', subFamily: 'termination', acronim: 'DIST' },
  { label: 'Elemento pasivo', description: 'Elemento pasivo sin clasificar', value: 'element', family: 'element', subFamily: 'spanEvent', acronim: 'HOLE' },
  { label: 'Caja de Inpección', description: 'Caja de acceso de interconexión o empalme', value: 'hand_hole', family: 'element', subFamily: 'spanEvent', acronim: 'HOLE' },
  { label: 'Cámara de Inspección', description: 'Cámaras subterráneas de derivación o empalme', value: 'man_hole', family: 'element', subFamily: 'spanEvent', acronim: 'HOLE' },
  { label: 'Botella de Empalme', description: 'Dispositivos sellados donde se conectan y protegen los empalmes de cables', value: 'fo_closure', family: 'element', subFamily: 'spanEvent', acronim: 'DIST' },
  { label: 'Panel de Cruzada', description: 'Paneles de interconexión mecánicos', value: 'patch_panel', family: 'element', subFamily: 'panels', acronim: 'DIST' },
  { label: 'Distribuidor Optico', description: 'Paneles de interconexión óptica', value: 'odf', family: 'element', subFamily: 'panels', acronim: 'DIST' },
  { label: 'Panel de Activo', description: 'Puertos o interfaces de un activo monitoreado', value: 'asset_panel', family: 'element', subFamily: 'panels', acronim: 'DIST' },
  { label: 'Cajas de distribución', description: 'Puntos donde se dividen o distribuyen cables a varias ubicaciones', value: 'distribution_box', family: 'element', subFamily: 'panels', acronim: 'DIST' },
  { label: 'Cajas de terminación', description: 'Puntos donde se conectan equipos u otros cables mediante conectores.', value: 'termination_box', family: 'element', subFamily: 'panels', acronim: 'DIST' },
  { label: 'Activo de red', description: 'Activo de red o seguridad', value: 'asset', family: 'asset', subFamily: 'hw', acronim: 'ACT' },
  { label: 'Infraestructura IT', description: 'Equipo para el procesamiento o almacenamiento de datos', value: 'it_asset', family: 'asset', subFamily: 'hw', acronim: 'ACT' },
  { label: 'Dispositivo IoT', description: 'Dispositivo o sensores para la captura de datos', value: 'iot_asset', family: 'asset', subFamily: 'hw', acronim: 'ACT' },
  { label: 'Aplicación', description: 'Aplicación o servicio ejecutado por un SO', value: 'app', family: 'asset', subFamily: 'app', acronim: 'ACT' },
  { label: 'Interconexión de Activos', description: 'Enlace lógico entre activos de red', value: 'data_link', family: 'data_link', subFamily: 'link', acronim: 'DL' },
  { label: 'Conexion a redes', description: 'Enlace a redes internas o externas', value: 'net_link', family: 'data_link', subFamily: 'link', acronim: 'DL' },
  { label: 'Conexion a Internet', description: 'Enlace a Internet', value: 'inet_link', family: 'data_link', subFamily: 'link', acronim: 'DL' },
];

export const valueToType: SelectableValue[] = [
  { label: 'Emplazamiento', description: 'Lugar físico donde se ubica un elemento determinado', value: 'emp' , order: 0},
  { label: 'Trayecto', description: 'Trayecto fisico compuesto por tramos que incluyen elementos de conectividad pasiva', value: 'path' , order: 1},
  { label: 'Tramo', description: 'Tramo que compone un trayecto físico', value: 'span', order: 3 },
  { label: 'Enlace Físico', description: 'Enlace físco que conecta dos emplazamientos a traves de segmentos', value: 'link', order: 2 },
  { label: 'Segmento', description: 'Sección de Cable o RE entre elementos de conectividad pasiva', value: 'segment', order: 3 },
  { label: 'Distribución/Conexión', description: 'Elementos de conectividad pasiva - Terminadores', value: 'element', order: 4 },
  { label: 'Empalme', description: 'Elemento que conecta dos segmentos de un mismo enlace', value: 'splicer', order: 5 },
  { label: 'División', description: 'Elemento que conecta dos segmentos de diferentes enlaces', value: 'splitter', order: 5 },
  { label: 'Enlace Lógico', description: 'Enlace lógico entre activos de red', value: 'data_link', order: 6 },
  { label: 'Activo', description: 'Activos de red, IT, IoT, App o enlace monitoreado', value: 'asset', order: 6 },
];

export const valueToStatus: SelectableValue[] = [
  { label: 'Eliminado', description: 'Elemento eliminado', value: 'delete' },
  { label: 'En Uso', description: 'Elemento actualmente en uso', value: 'in_use' },
  { label: 'Fuera de Uso', description: 'Elemento que no es utilizado', value: 'out_of_use' },
  { label: 'En Reparación', description: 'Elemento que esta siendo reparado/modificado', value: 'under_repair' },
  { label: 'En Construcción', description: 'Elemento en contrucción', value: 'under_construction' },
];

export const valueToAssetStatus: SelectableValue[] = [
  { label: 'Eliminado', description: 'Activo eliminado', value: 'delete' },
  { label: 'Activo monitoreado', description: 'Activo que es monitoreado', value: 'in_use' },
  { label: 'Activo, sin monitorear', description: 'Activo definido pero que no es monitoreado', value: 'defined' },
  { label: 'Gestionado por otro activo', description: 'Activo orquestado por otro activo monitoreado', value: 'orquested' },
  { label: 'Solo Alerta', description: 'Activo monitoreado solo para la emisión de alertas de indisposibilidad', value: 'alert_only' },
  { label: 'Detenido', description: 'Activo que no es utilizado', value: 'out_of_use' },
  { label: 'En RMA', description: 'Activo fuera de servicio por repación / RMA', value: 'under_repair' },
  { label: 'En Instalación', description: 'Elemento que se encuentra en proceso de instalación', value: 'under_construction' },
];

export const valueToProtocol: SelectableValue[] = [
  { label: 'Ninguno', description: 'Activo no monitoreado', value: 'NONE' },
  { label: 'SNMP', description: 'Simple Network Management Protocol', value: 'SNMPv1' },
  { label: 'SNMP v2', description: 'Simple Network Management Protocol v2', value: 'SNMP' },
  { label: 'SNMP v3', description: 'Simple Network Management Protocol v3', value: 'SNMPv3' },
  { label: 'ICMP', description: 'Internet Control Message Protocol', value: 'PING' },
  { label: 'REST API', description: 'Application Programming Interface', value: 'API' },
  { label: 'URL', description: 'Uniform Resource Locator', value: 'URL' },
  { label: 'RCP', description: 'Bosch RCP Protocol', value: 'RCP' },
  { label: 'ONVIF', description: 'Open Network Video Interface Forum', value: 'ONVIF' },
];

export const fiberOpticConnectors = [
  { label: 'RJ-11', description: 'EIA RJ-11', value: 'RJ-11', family: 'utp' },
  { label: 'RJ-45', description: 'EIA RJ-45', value: 'RJ-45', family: 'utp' },
  { label: 'BNC', description: 'Bayonet Neill-Concelman', value: 'BNC', family: 'utp' },
  { label: 'LC-PC', description: 'Little/Local Connector - physical contact', value: 'LC', family: 'fiber' },
  { label: 'LC-PC', description: 'Little/Local Connector - physical contact', value: 'LC-PC', family: 'fiber' },
  { label: 'LC-UPC', description: 'Little/Local Connector - ultra polish contact', value: 'LC-UPC', family: 'fiber' },
  { label: 'LC-APC', description: 'Little/Local Connector - Angle-polished contact', value: 'LC-APC', family: 'fiber' },
  { label: 'SC-PC', description: 'Standard/Square Connector - physical contact', value: 'SC', family: 'fiber' },
  { label: 'SC-PC', description: 'Standard/Square Connector - physical contact', value: 'SC-PC', family: 'fiber' },
  { label: 'SC-UPC', description: 'Standard/Square Connector - ultra polish contact', value: 'SC-UPC', family: 'fiber' },
  { label: 'SC-APC', description: 'Standard/Square Connector - Angle-polished contact', value: 'SC-APC', family: 'fiber' },
  { label: 'FC-PC', description: 'Ferrule/Fiber Channel Connector - physical contact', value: 'FC-PC', family: 'fiber' },
  { label: 'FC-UPC', description: 'Ferrule/Fiber Channel Connector - ultra polish contact', value: 'FC-UPC', family: 'fiber' },
  { label: 'ST-PC', description: 'Straight Tip/Bayonet Connector - physical contact', value: 'ST-PC', family: 'fiber' },
  { label: 'ST-UPC', description: 'Straight Tip/Bayonet Connector - ultra polish contact', value: 'ST-UPC', family: 'fiber' },
];

export const valueToDuplex: SelectableValue[] = [
  { label: 'TX', description: 'Asignado a Transmisión', value: 'Tx' },
  { label: 'RX', description: 'Asignado a Recepción', value: 'Rx' },
  { label: 'TX/RX', description: 'Asignado a Transmisión/Recepción', value: 'Tx/Rx' },
];

export const fiberOpticColors = [
  { label: 'Azul', description: 'Primera fibra', value: 'blue' },
  { label: 'Naranja', description: 'Segunda fibra', value: 'orange' },
  { label: 'Verde', description: 'Tercera fibra', value: 'green' },
  { label: 'Marrón', description: 'Cuarta fibra', value: 'brown' },
  { label: 'Gris', description: 'Quinta fibra', value: 'gray' },
  { label: 'Blanco', description: 'Sexta fibra', value: 'white' },
  { label: 'Rojo', description: 'Séptima fibra', value: 'red' },
  { label: 'Negro', description: 'Octava fibra', value: 'black' },
  { label: 'Amarillo', description: 'Novena fibra', value: 'yellow' },
  { label: 'Violeta', description: 'Décima fibra', value: 'violet' },
  { label: 'Turquesa', description: 'Undécima fibra', value: 'turquoise' },
  { label: 'Rosa', description: 'Duodécima fibra', value: 'pink' },
  { label: 'Cyan', description: 'Decimotercera fibra', value: 'cyan' },
  { label: 'Magenta', description: 'Decimocuarta fibra', value: 'magenta' }
];

export const bufferColors = [
  { label: 'Azul', description: 'Primer buffer', value: 'blue' },
  { label: 'Naranja', description: 'Segundo buffer', value: 'orange' },
  { label: 'Verde', description: 'Tercer buffer', value: 'green' },
  { label: 'Marrón', description: 'Cuarto buffer', value: 'brown' },
  { label: 'Gris', description: 'Quinto buffer', value: 'gray' },
  { label: 'Blanco', description: 'Sexto buffer', value: 'white' },
  { label: 'Rojo', description: 'Séptimo buffer', value: 'red' },
  { label: 'Negro', description: 'Octavo buffer', value: 'black' },
  { label: 'Amarillo', description: 'Noveno buffer', value: 'yellow' },
  { label: 'Violeta', description: 'Décimo buffer', value: 'violet' },
  { label: 'Turquesa', description: 'Undécimo buffer', value: 'turquoise' },
  { label: 'Rosa', description: 'Duodécimo buffer', value: 'pink' },
  { label: 'Cyan', description: 'Decimotercera buffer', value: 'cyan' },
  { label: 'Magenta', description: 'Decimocuarta buffer', value: 'magenta' }
];

export const multiparColors = [
  { label: 'Blanco/Azul', description: 'Par 1', value: 'white-blue' },
  { label: 'Blanco/Naranja', description: 'Par 2', value: 'white-orange' },
  { label: 'Blanco/Verde', description: 'Par 3', value: 'white-green' },
  { label: 'Blanco/Marrón', description: 'Par 4', value: 'white-brown' },
  { label: 'Blanco/Gris', description: 'Par 5', value: 'white-gray' },
  { label: 'Rojo/Azul', description: 'Par 6', value: 'red-blue' },
  { label: 'Rojo/Naranja', description: 'Par 7', value: 'red-orange' },
  { label: 'Rojo/Verde', description: 'Par 8', value: 'red-green' },
  { label: 'Rojo/Marrón', description: 'Par 9', value: 'red-brown' },
  { label: 'Rojo/Gris', description: 'Par 10', value: 'red-gray' },
  { label: 'Negro/Azul', description: 'Par 11', value: 'black-blue' },
  { label: 'Negro/Naranja', description: 'Par 12', value: 'black-orange' },
  { label: 'Negro/Verde', description: 'Par 13', value: 'black-green' },
  { label: 'Negro/Marrón', description: 'Par 14', value: 'black-brown' },
  { label: 'Negro/Gris', description: 'Par 15', value: 'black-gray' },
  { label: 'Amarillo/Azul', description: 'Par 16', value: 'yellow-blue' },
  { label: 'Amarillo/Naranja', description: 'Par 17', value: 'yellow-orange' },
  { label: 'Amarillo/Verde', description: 'Par 18', value: 'yellow-green' },
  { label: 'Amarillo/Marrón', description: 'Par 19', value: 'yellow-brown' },
  { label: 'Amarillo/Gris', description: 'Par 20', value: 'yellow-gray' },
  { label: 'Violeta/Azul', description: 'Par 21', value: 'violet-blue' },
  { label: 'Violeta/Naranja', description: 'Par 22', value: 'violet-orange' },
  { label: 'Violeta/Verde', description: 'Par 23', value: 'violet-green' },
  { label: 'Violeta/Marrón', description: 'Par 24', value: 'violet-brown' },
  { label: 'Violeta/Gris', description: 'Par 25', value: 'violet-gray' }
];

export const lineWidths: SelectableValue[] = [
  { label: '1/4 Punto', description: 'Línea de 1/4 punto de grosor', value: 0.5 },
  { label: '1/2 Punto', description: 'Línea de 1/2 punto de grosor', value: 1 },
  { label: '3/4 Punto', description: 'Línea de 3/4 punto de grosor', value: 1.5 },
  { label: '1 Punto', description: 'Línea de 1 punto de grosor', value: 2 },
  { label: '2 Puntos', description: 'Línea de 2 puntos de grosor', value: 4 },
  { label: '3 Puntos', description: 'Línea de 3 puntos de grosor', value: 8 },
];

export const lineTypes: SelectableValue[] = [
  { label: 'Solida', description: 'Línea contínua', value: '' },
  { label: 'Puntos', description: 'Línea de puntos', value: '2, 5' },
  { label: 'Guión corto', description: 'Línea discontinua corta', value: '5, 2' },
  { label: 'Guiones', description: 'Línea discontinua', value: '5, 5' },
  { label: 'Guión Largo', description: 'Línea discontinua larga', value: '10, 2' },
  { label: 'Compuesta', description: 'Línea compuesta guión - punto - guión', value: '5, 5, 1, 5' },
];

export const assetTypes: SelectableValue[] = [
  { label: 'Activo de Red', description: 'Activo de Red genérico sin clasificar', value: 'asset', family: 'asset' },  
  { label: 'Host Físico', description: 'Dispositivo de procesamiento conectado a una red, que utilizan servicios de ella', value: 'host', family: 'it_asset' },  
  { label: 'Host ESXI', description: 'Hypervisor VMWare tipo 1', value: 'esxi', family: 'it_asset' },
  { label: 'Servidor', description: 'Dispositivo de procesamiento genérico', value: 'server', family: 'it_asset' },
  { label: 'Storage', description: 'Sistema de almacenamiento en línea', value: 'storage', family: 'it_asset' },
  { label: 'Máquina Virtual', description: 'Equipos definido por software dentro de un Host', value: 'vm', family: 'it_asset' },
  { label: 'Biblioteca de Cintas', description: 'Dispositivo de almacenamiento que contiene una o más unidades de cinta', value: 'tape', family: 'it_asset' },
  { label: 'Switch', description: 'Conmutador de red', value: 'switch', family: 'asset' },
  { label: 'Router', description: 'Encaminador o Conmutador L3', value: 'router', family: 'asset' },
  { label: 'Firewall', description: 'Firewall físico (appliance) o virtual', value: 'firewall', family: 'asset' },
  { label: 'Virtual Chassis Member', description: 'Activo que forma parte de un cluster de procesamiento', value: 'virtual_chassis', family: 'asset' },
  { label: 'Wireless Access Controller', description: 'Appliance destinado a la orquestación de infraestructura WiFi', value: 'wlc', family: 'asset' },
  { label: 'Virtual Wireless Access Controller', description: 'Software destinado a la orquestación de infraestructura WiFi', value: 'wlc', family: 'asset' },
  { label: 'Punto de Acceso', description: 'Punto de Acceso Wifi autónomo o gestionado', value: 'ap', family: 'asset' },
  { label: 'Punto a Punto', description: 'Equipo de radio para enlace PtP', value: 'ptp', family: 'asset' },
  { label: 'Radio Base', description: 'Punto de Acceso o Base de un sistema inalámbrico PaMP', value: 'base_station', family: 'asset' },
  { label: 'Subscriptor', description: 'Cliente de un sistema PaMP inalambrico', value: 'wireless_cpe', family: 'asset' },
  { label: 'OLT', description: 'Optical Line Termination en un sistema GPON', value: 'olt', family: 'asset' },
  { label: 'ONT', description: 'Optical Network Termination en un sistema GPON', value: 'ont', family: 'asset' },
  { label: 'NVR', description: 'Network Video Recorder', value: 'nvr', family: 'asset' },
  { label: 'Camara', description: 'Cámara de video vigilancia', value: 'camera', family: 'iot_asset' },
  { label: 'Sensor Biometrico', description: 'Dispositivo de seguridad para el control de acceso biometrico', value: 'biometric', family: 'iot_asset' },
  { label: 'IoT', description: 'Dispositivo destinado a la captura de datos o variables ambientales', value: 'iot', family: 'iot_asset' },
  { label: 'Rectificador', description: 'Sistema para gestión de energía (DC) y control de almacenamiento (Baterías)', value: 'dc_supply', family: 'iot_asset' },
  { label: 'UPS', description: 'Sistema para gestión de energía (AC) y control de almacenamiento (Baterías)', value: 'ac_supply', family: 'iot_asset' },
  { label: 'PBX', description: 'Conmutador telefonico tradicional o IP', value: 'pabx', family: 'asset' },
  { label: 'Telefono', description: 'Aparato telefonico IP', value: 'phone', family: 'asset' },
  { label: 'Aplicación', description: 'Tarea realizada por un programa a través de servicios y que posee un API', value: 'web', family: 'app' },
  { label: 'Servicio', description: 'Funciones provistas por un sistema operativo empleando procesos', value: 'service', family: 'app' },
  { label: 'Proceso', description: 'Instancia de un programa corriendo en un sistema operativo', value: 'process', family: 'app' },
];

export const newObject = (actualElementsLength: number, objectType: string, id: string, idx: string, title: string): L1OyMData[] => { 
  const date = new Date();
  const today = moment(date).format('DD/MM/YYYY HH:mm:ss');
  const todayISO = date.toISOString().split('T')[0];
  const newId = id && id !== '' && id !== 'undefined' ? id : generarID();
  const elementType = valueToSubtype.find(element => element.value === objectType);
  const elementName = elementType ? elementType.acronim + '_' + newId.toUpperCase() : 'ELE_' + newId.toUpperCase();
  const elementIdx = idx && idx !== '' ? idx : elementName;
  let elementTitle = elementType ? elementType.label + ' ' + newId.toUpperCase() : 'Elemento ' + newId.toUpperCase();
  elementTitle = title && title !== '' ? title : elementTitle;
  const emptyObject = {
	  id: actualElementsLength,
	  uid: newId,
	  idx: elementIdx,
	  key: '',
	  title: elementTitle,
	  style: {color: '#000000', size: 24, weight: 2, icon: '', dashArray: '', id: ''},
	  importId: '',
	  state: true,
	  created: today,
	  updated: today,
	  user: '',
	  elementType: elementType ? elementType.family : 'element',
	  subFamily: elementType ? elementType.subFamily : 'spanEvent',
	  subType: objectType !== '' ? objectType : 'element',
	  elementId: '',
	  siteId: '',
	  groupIds: [],
	  pathId: newId,
	  linkId: '',
	  spanId: '',
	  panelId: '',
	  sectionType: '',
	  status: 'in_use',
	  installationDate: todayISO,
	  installationNotes: '',
	  technician: '',
	  lastInspection: todayISO,
	  nextMaintenance: todayISO,
	  owner: '',
	  technicalDetails: '',
	  origin: '',
	  originPort: '',
	  destination: '',
	  destinationPort: '',
	  localPanel: '',
	  remotePanel: '',
	  localPort: '',
	  remotePort: '',
	  coordinates: [],
	  address: '',
	  elevation: 0,
	  ovOrquested: false,
	  orquesterId: '',
	  parentsIds: [],
	  relationIds: [],
	  relationPorts: [],
	  relationRemotePorts: [],
	  relationDistances: [],
	  relationCapacities: [],
	  sublinkIds: [],
	  sublinkBuffer: [],
	  sublinkColor: [],
	  sublinkAttenuation: [],
	  sublinkDistance: [],
	  segmentId: '',
	  pathEventId: '',
	  capacity: 0,
	  connector: '',
	  duplex: '',
	  assetType: '',
	  assetModel: '',
	  ip: '',
	  mask: '',
	  fqdn: '',
	  http_enable: false,
	  https_enable: false,
	  http_port: 80,
	  https_port: 443,
	  params: '',
	  path: '',
	  sla: 98,
	  bwUp: 0,
	  bwDown: 0,
	  gw: '',
	  fqdnGw: '',
	  gwCentral: '',
	  critic: false,
	  notifEnable: false,
	  fiberIds: [],
	  fiberAttenuation: [],
	  lastInspector: '',
	  inspectionReport: '',
	  inspectionNotes: '',
	  images: '',
	  purchaseOrder: '',
	  purchaseDate: '',
	  supplier: '',
	  reference: '',
	  deliveryDate: '',
	  deliveryNote: '',
	  invoiceNumber: '',
	  warrantyExp: '',
	  contractNumber: '',
	  warrantyHwExp: '',
	  hwContractNumber: '',
	  serialNumber: '',
	  macNumber: '',
	  project: '',
	  version: 0,
	  wasChange: false,
  };
  return emptyObject;
};
