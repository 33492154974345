import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { L1OyMData, L1OyMStyle } from './L1OyMManager';
import { Text } from './Tags';
import { valueToType, valueToSubtype, valueToStatus } from './types';
import { DynamicTable, DynamicMap, DynamicTableColumnProps, DynamicTableItemProps } from './L1OyMDynamicTable';

export interface l1OyMTableProps {
  renderCount: number;
  isAdmin: boolean;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  assets: [];
  products: [];
  places: [];
  types: [];
  sites: [];
  l1Links: [];
  ppList: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

type RouteTableColumnProps = DynamicTableColumnProps<L1OyMData>;
type RouteTableItemProps = DynamicTableItemProps<L1OyMData>;

export const getFilteredElements = (
  elements: L1OyMData[],
  isElement?: boolean,
  title?: string,
  elementType?: string,
  subType?: string,
  place?: string,
  hasChange?: boolean,
  version?: number,
  colOrder?: string,
  mode?: string
) => {
  let elementsFiltered: [] = [];
  elements.forEach((ele) => {
    const element = isElement ? ele : ele.data;
	let mustFilter = false;
	if (title) {
	  mustFilter = element.title.toLowerCase().includes(title.toLowerCase()) ? mustFilter : true;
    }
	if (elementType !== undefined && subType !== undefined) {
	  mustFilter = element.elementType.toLowerCase() === elementType.toLowerCase() && 
	    element.subType.toLowerCase() === subType.toLowerCase() ? mustFilter : true;
	} else if (elementType !== undefined) {
	  mustFilter = element.elementType.toLowerCase() === elementType.toLowerCase() ? mustFilter : true;
	} else if (subType !== undefined) {
	  mustFilter = element.subType.toLowerCase() === subType.toLowerCase() ? mustFilter : true;
    } 
	if (place !== undefined && element.origin !== place && element.destination !== place && element.siteId !== place) {
	  mustFilter = true;
	}
	if (hasChange !== undefined && element.wasChange !== hasChange) {
	  mustFilter = true;
	}
	if (version !== undefined  && element.version !== version) {
	  mustFilter = true;
	}
	if (!mustFilter) {
	  elementsFiltered.push(element);
	}
  });

  let sortedElements = elementsFiltered;

  if (colOrder !== '') {
    sortedElements = elementsFiltered.sort((a, b) => {
      if (typeof a[colOrder] === "string") {
        return mode === 'asc'
          ? a[colOrder].localeCompare(b[colOrder])
          : b[colOrder].localeCompare(a[colOrder]);
      } else {
        return mode === 'asc'
          ? a[colOrder] - b[colOrder]
          : b[colOrder] - a[colOrder];
      }
    });
  } else {
	sortedElements = elementsFiltered.sort((a, b) => {
      if (a.elementType < b.elementType) return 1;
      if (a.elementType > b.elementType) return -1;

      if (a.subType < b.subType) return 1;
      if (a.subType > b.subType) return -1;

      if (a.title < b.title) return 1;
      if (a.title > b.title) return -1;
    });
  }

  if (isElement) {
    return sortedElements;
  } else {
	const sortedItems = sortedElements.map(item => ({
	  data: item,
	  id: item.id
	}));
	return sortedItems;
  }
};

export const cleanElements = (items: L1OyMData[]): L1OyMData[] => {
  const cleannerElements: L1OyMData[] = [];
  for (let i = 0; i < items.length; i++) {
	const item = items[i].data;
	let elementData = item;
	cleannerElements.push(elementData);
  }
  return cleannerElements;
};

export const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 10; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
}

export const updateElement = (actualElements: L1OyMData[], item: l1OyM): L1OyMData[] => {
  const newElements = [...actualElements];
  const editIndex = newElements.findIndex((element) => element.id === item.id);
  const userName = contextSrv.user.name;
  item.updated = '';
  item.version = item.version + 1;
  item.user = userName;
  if (editIndex >= 0) {
    newElements[editIndex] = {
      ...newElements[editIndex],
      ...item,
    };
  } else {
    newElements.push(item);
  }
  return newElements;
};

export const getStyle = (styleId: string, elementType: string, importId: string, title: string): L1OyMStyle[] => {
  let style = generarID();
  if (styleId && styleId.length > 0) {
    style = styleId;
  }
  const colors = [
    "#F17171",
    "#71E200",
    "#A2ADB8",
    "#7100FF",
    "#FFA071",
	"#A20471",
    "#FF71B7", 
    "#7EA9ff",
    "#FF0071",
    "#B380FF"
  ];
  const dashPatterns = [
    '2, 1',
    '1, 2',
    '2, 2',
  ];

  const hash = Array.from(style).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colorId = (hash % 30) + 1;
  const fillColor = colors[hash % colors.length];
  const dashArray = dashPatterns[hash % dashPatterns.length];
  const pathId = elementType === 'span' ? generarID() : '';
  
  const elementStyle = elementType === 'span' ?
    { id: style, color: fillColor, dashArray: dashArray, pathId: pathId, title: title, size: 24, weight: 1, icon: '', importId: importId } :
	{ id: style, color: fillColor, dashArray: dashArray, size: 24, weight: 1, icon: '', importId: importId };

  return elementStyle
}

export const updateImportElements = (actualElements: L1OyMData[], newElements: L1OyMData[]): L1OyMData[] => {
  const existingElements: L1OyMData[] = [...actualElements];
  const empStyles: [] = [];
  const spanStyles: [] = [];
  let wasConfirmEmp = false;
  let mustUpdateEmp = false;
  let wasConfirm = false;
  let mustUpdate = false;
  for (let i = 0; i < newElements.length; i++) {
	let item = newElements[i];
    if (item.elementType === 'emp' && item.coordinates.length > 1 && item.title) {
	  const editIndex = existingElements.findIndex((element) => 
	    element.elementType === item.elementType && 
	    element.subType === item.subType && 
	    element.title === item.title 
      );
      if (editIndex >= 0) {
		const hasSameCoords = _.isEqual(item.coordinates, existingElements[editIndex].coordinates);
		if (!hasSameCoords) {
		  if (!wasConfirmEmp) {
		    mustUpdateEmp = window.confirm(`¿Sobrescribir datos de emplazamientos existentes?`);
		    wasConfirmEmp = true;
		  }
		  if (mustUpdateEmp) {
		    item.updated = '';
	        item.wasChange = true;
            item.version = existingElements[editIndex].version + 1;
		    item.style = existingElements[editIndex].style;
			item.uid = existingElements[editIndex].uid;
		    item.idx = existingElements[editIndex].idx;
            existingElements[editIndex] = {
              ...existingElements[editIndex],
              ...item,
            };
		  }
		}
	  } else {
	    let empStyle = empStyles.find((style) => style.id === item.style.id);
		if (!empStyle) {
		  const styleColors = getStyle(item.style.id, item.elementType, item.importId);
		  empStyles.push(styleColors);
		  empStyle = styleColors;
		}
		item.style = empStyle;
		item.updated = '';
	    item.wasChange = true;
        item.version = item.version + 1;
        existingElements.push(item);
	  }
	} else if (item.elementType === 'span' && item.coordinates.length > 1 && item.title) {
	  const similarSpans = existingElements.filter((element) => 
	    element.elementType === item.elementType && 
	    element.subType === item.subType && 
	    element.title === item.title 
      );
      if (similarSpans.length >= 0) {
	    const hasSameCoords = similarSpans.find(span => _.isEqual(item.coordinates, span.coordinates));
		if (!hasSameCoords) {
		  if (!wasConfirm) {
		    mustUpdate = window.confirm(`¿Importar multiples tramos con la misma Designación?`);
		    wasConfirm = true;
		  }
		  if (mustUpdate) {
			let spanStyle = spanStyles.find((style) => style.id === item.style.id);
			if (!spanStyle) {
			  const styleColors = getStyle(item.style.id, item.elementType, item.importId, item.title);
			  spanStyles.push(styleColors);
			  spanStyle = styleColors;
			}
			item.style = {
			  color: spanStyle.color,
			  size: spanStyle.size,
			  weight: spanStyle.weight,
			  icon: spanStyle.icon,
			  dashArray: spanStyle.dashArray,
			  id: spanStyle.id
			};
			item.pathId = spanStyle.pathId;
			item.capacity = 1;
		    item.updated = '';
	        item.wasChange = true;
            item.version = item.version + 1;
            existingElements.push(item);
	      }
	    }
	  } else {
	    let spanStyle = spanStyles.find((style) => style.id === item.style.id);
		if (!spanStyle) {
		  const styleColors = getStyle(item.style.id, item.elementType, item.importId, item.title);
		  spanStyles.push(styleColors);
		  spanStyle = styleColors;
		}
	    item.style = {
		  color: spanStyle.color,
		  size: spanStyle.size,
		  weight: spanStyle.weight,
		  icon: spanStyle.icon,
		  dashArray: spanStyle.dashArray,
		  id: spanStyle.id
		};
		item.pathId = spanStyle.pathId;
		item.capacity = 1;
		item.updated = '';
	    item.wasChange = true;
        item.version = item.version + 1;
        existingElements.push(item);
	  }
	}
  }
  if (spanStyles.length > 0) {
    const createPaths = window.confirm(`¿Desea crear Trayectos para los tramos importados?`);
	if (createPaths) {
	  const nextId = existingElements.length;
	  for (let i = 0; i < spanStyles.length; i++) {
	    const uidx = spanStyles[i].pathId;
	    const idx = 'PATH_' + spanStyles[i].pathId.toUpperCase();
		const title = 'Trayecto ' + spanStyles[i].title;
	    let newPath = newObject(nextId, 'path', uidx, idx, title);
	    newPath.style = { 
		  color: spanStyles[i].color, size: spanStyles[i].size, weight: spanStyles[i].weight, 
		  icon: spanStyles[i].icon, dashArray: spanStyles[i].dashArray, id: spanStyles[i].id 
		};
		newPath.pathId = spanStyles[i].pathId;
	    newPath.importId = spanStyles[i].importId;
	    newPath.user = 'Import Process';
	    newPath.version = 1;
		existingElements.push(newPath);
	  }
	}
  }
  return existingElements;
};

export const saveImportData = async (newElements: [], nextId: number, file: any): L1OyMData[] => {
  const newElementsConfigured: L1OyMData[] = [];
  const userName = contextSrv.user.name;
  const importId = `file:${file.name}[${file.size}]${file.lastModified}`
  if (newElements) {
    for (let i = 0; i < newElements.length; i++) {
	  const uidx = generarID();
	  const idx = 'EMP_' + uidx.toUpperCase();
	  let newElement = newObject(nextId, 'emp', uidx, idx, newElements[i].properties.name);
	  newElement.style = {color: '#000000', size: 24, weight: 2, icon: '', dashArray: '', id: newElements[i].properties.styleHash},
	  newElement.importId = importId,
	  newElement.user = userName;
	  newElement.version = 1;
	  if (newElements[i].geometry.type === 'Point') {
		newElement.coordinates = [newElements[i].geometry.coordinates[1], newElements[i].geometry.coordinates[0]];
		newElement.elevation = newElements[i].geometry.coordinates[2];
	  } else if (newElements[i].geometry.type === 'LineString') {
		newElement.idx = 'SPAN_' + uidx.toUpperCase(),
		newElement.elementId = uidx;
		newElement.spanId = uidx;
		newElement.elementType = 'span';
		newElement.subType = 'underground_span';
		const coords = newElements[i].geometry.coordinates;
		const newCoords = [];
		for (let i = 0; i < coords.length - 1; i++) {
		  const point = coords[i];
		  newCoords.push([point[1], point[0]]);
		}
		newElement.coordinates = newCoords;
	  }
	  newElementsConfigured.push(newElement);
	}
	return newElementsConfigured;
  }
  return [];
};

export const saveElement = async (elements: L1OyMData[], pluginApi: string, newElement: L1OyMData[]): Promise<boolean> => {
  const element = newElement ? newElement : elements.find(element => element.updated === '');
  //console.log(element);
  if (element) {
    const referencePath = (element.elementType === 'element' && element.subFamily !== 'termination') || element.elementType === 'path' ||
	  element.elementType === 'span' || element.elementType === 'link' ? ', "path": "' + element.pathId + '"' : '';
    const groups = JSON.stringify(element.groupIds);
	const referenceGroup = (element.elementType === 'asset') ? ', "groups": ' + groups : '';
	const referenceAssets = (element.elementType === 'data_link') ? ', "assets": [' + element.origin + ',' + element.destination + ']' : '';
    const objectIdx = element.idx && element.idx !== 'undefined' ? element.idx : element.uid;
	var json_values = '{"json_values": {' + 
      '"title":"' + element.title + '",' + 
	  '"uid":"' + element.uid + '",' + 
	  '"user":"' + element.user + '",' + 
	  '"version":' + element.version + ',' + 
	  '"groupname":"' + element.elementType + '",' +
	  '"reference": {' + '"id": "' + objectIdx + '"' + referencePath + referenceGroup + referenceAssets + '} }';
    var objectId = element.pathId;
	if (element.elementType === 'link') {
	  objectId = element.linkId;
	} else if (element.elementType === 'span') {
	  objectId = element.spanId;
	} else if (element.elementType === 'segment') {
	  objectId = element.segmentId;
	} else {
	  objectId = element.elementId;
	}
	if ((objectId === undefined || objectId === 'undefined' || objectId === '') && element.uid !== undefined) {
	  objectId = element.uid;
	}
	const elementStyle = JSON.stringify(element.style);
	const mediaData = element.inspectionMediaData && element.inspectionMediaData !== 'undefined' ? element.inspectionMediaData : '';
	const capacity = element.capacity ? element.capacity : 0;
	var json_data = ', "json_data":' +
	  '{"id":"' + objectId + '",' +
	  '"idx":"' + objectIdx + '",' +
	  '"style":' + elementStyle + ',' +
	  '"import_id":"' + element.importId + '",' +	  
	  '"type":"' + element.subType + '",' +
	  '"inspection_data":{"last_inspector": "' + element.lastInspector + '",' +
		'"report":"' + element.inspectionReport + '",' +
		'"notes":"' + element.inspectionNotes + '"},' +
	  '"media":{"images":"' + mediaData + '"},';

	if (element.elementType !== 'splicer' && element.elementType !== 'splitter') {
	  json_data = json_data + '"technical_details": { ' +
		'"status": "' + element.status + '",';
		if (element.elementType === 'asset' || element.elementType === 'emp') {
		  json_data = json_data + '"technician":"' + element.technician + '",';
		}
		json_data = json_data + '"installation_date":"' + element.installationDate + '",' +
		'"last_inspection":"' + element.lastInspection + '",' +
		'"next_maintenance":"' + element.nextMaintenance + '",' +
		'"owner":"' + element.owner + '",';

	  if (element.elementType === 'element') {
	    const elevation = element.elevation ? element.elevation : 0;
	    const jsonString = element.relationIds.map((id, index) => ({
		  element_id: id,
		  distances: element.relationDistances[index],
		  capacities: element.relationCapacities[index],
	    }));
	    const sublinkText = JSON.stringify(jsonString);
	    json_data = json_data + '"path_id":"' + element.pathId + '",';
		if (element.subFamily === 'termination') {
		  json_data = json_data + '"link_id":"' + element.linkId + '",' + 
		  '"segment_id":"' + element.segmentId + '",' +
		  '"capacity":' + capacity + ',';
		}
		json_data = json_data + '"parents":["' + element.pathId + '"],' +
		  '"location":{' + '"coordinates":[' + element.coordinates + '],' +
		    '"address":"' + element.address + '",' +
		    '"elevation":' + elevation + '},' +
		  '"relations":' + sublinkText + ',' +
		  '"notes":"' + element.installationNotes + '"}}}';
	  } else if (element.elementType === 'emp') {
	    const elevation = element.elevation ? element.elevation : 0;
	    json_data = json_data + '"collector":"' + element.siteId + '",' +
		  '"parents":["' + element.pathId + '"],' +
		  '"location":{' + '"coordinates":[' + element.coordinates + '],' +
		    '"address":"' + element.address + '",' +
		    '"elevation":' + elevation + '},' +
		  '"notes": "' + element.installationNotes + '"}}}';
	  } else if (element.elementType === 'asset') {
	    const groups = JSON.stringify(element.groupIds);
		const sla = element.sla >= 0 &&  element.sla <= 100 ? element.sla.toFixed(2) : '98';
	    const critic = element.critic === true ? 'true' : 'false';
	    const notifEnable = element.notifEnable === true ? 'true' : 'false';
	    let clusterString = element.clusterSerials ? element.clusterSerials.map((id, index) => ({
		  index: index,
		  mac_id: '',
		  serial_id: id
	    })) : null;
		clusterString = clusterString === null && element.clusterMacs ? element.clusterSerials.map((id, index) => ({
		  index: index,
		  mac_id: id,
		  serial_id: ''
	    })) : null;
		const clusteringText = clusterString !== null ? JSON.stringify(clusterString) : '';
	    const relationString = element.relationIds.map((id, index) => ({
		  element_id: id,
		  port: element.relationPorts[index] || '',
	    }));
	    const relationText = JSON.stringify(relationString);
	    json_data = json_data + '"key":"' + element.elementId + '",' +
		  '"collector":"' + element.siteId + '",';
		  if (relationText !== '[]') {
		    json_data = json_data + '"conected_to":' + relationText + ',';
		  }
		  json_data = json_data + '"group_ids":' + groups + ',' +
		  '"location":{' + '"coordinates":[' + element.coordinates + ']},';
		  if (clusteringText !== '') {
		    json_data = json_data + '"clustering":' + clusteringText + ',';
		  }
		  json_data = json_data + '"monitoring":{' + '"product":"'  + element.assetModel + '",' +
		    '"type":"' + element.assetType + '",' +
		    '"family":"' + element.subFamily + '",';
		    if (element.orquesterId !== '') {
			  const ovOrquested = element.ovOrquested ? 'true' : 'false';
			  json_data = json_data + '"orquested_by":"' + element.orquesterId + '",' +
			  '"is_OV2500":"' + ovOrquested + '",';
			}
		    json_data = json_data + '"protocol":"' + element.protocol + '",';
			if (element.protocol === 'SNMPv1' || element.protocol === 'SNMP') {
		      json_data = json_data + '"community":"' + element.community + '",';
			}
		    json_data = json_data + '"fqdn":"' + element.fqdn + '",' +
		    '"ip":"' + element.ip + '",' +
		    '"target_sla":"' + sla + '",' +
		    '"critic":"' + critic + '",' +
		    '"notification_enable":"' + notifEnable + '"}';
		  const macsString = element.macNumber;
		  const macs = macsString ? macsString.split(',') : [];
		  const jsonMacs = JSON.stringify(macs);
		  const serialNumberString = element.serialNumber;
		  const serialNumber = serialNumberString ? serialNumberString.split(',') : [];
		  const jsonSerialNumber = JSON.stringify(serialNumber);
		  const contractNumberString = element.contractNumber;
		  const contractNumber = contractNumberString ? contractNumberString.split(',') : [];
		  const jsonContractNumber = JSON.stringify(contractNumber);
		  const hwContractNumberString = element.hwContractNumber;
		  const hwContractNumber = hwContractNumberString ? hwContractNumberString.split(',') : [];
		  const jsonHwContractNumber = JSON.stringify(hwContractNumber);
		  json_data = json_data + ', "inventory":{' + '"project":"' + element.project + '",' +
		    '"purchase_order":"'  + element.purchaseOrder + '",' +
			'"purchase_date":"'  + element.purchaseDate + '",' +
			'"supplier":"'  + element.supplier + '",' +
		    '"delivery_date":"' + element.deliveryDate + '",' +
		    '"delivery_note":"' + element.deliveryNote + '",' +
		    '"invoice_number":"' + element.invoiceNumber + '",' +
		    '"serial_number":' + jsonSerialNumber + ',' +
		    '"warranty_exp":"' + element.warrantyExp + '",' +
		    '"contract_number":' + jsonContractNumber + ',' +
		    '"warranty_hw_exp":"' + element.warrantyHwExp + '",' +
		    '"hw_contract_number":' + jsonHwContractNumber + ',' +
		    '"mac_number":' + jsonMacs + '}';
		if (element.installationNotes !== '') {
		  json_data = json_data + ', "notes": "' + element.installationNotes + '"}}}';
		} else {
		  json_data = json_data + '}}}';
		}
	  } else if (element.elementType === 'path') {
	    const coordinates = JSON.stringify(element.coordinates);
		json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"origin": "' + element.origin + '",' +
		  '"destination": "' + element.destination + '",' +
		  '"geometry": {' + '"coordinates":' + coordinates + '},' +
		  '"notes": "' + element.installationNotes + '"}}}';
      } else if (element.elementType === 'span') {
		const coordinates = JSON.stringify(element.coordinates);
		json_data = json_data + '"path_id":"' + element.pathId + '",' +
		  '"span_id":"' + element.spanId + '",' +
		  '"parents":["' + element.pathId + '"],' +
		  '"capacity":' + capacity + ',' +
		  '"origin":"' + element.origin + '",' +
		  '"destination":"' + element.destination + '",' +
		  '"geometry":{' + '"coordinates":' + coordinates + '},' +
		  '"notes":"' + element.installationNotes + '"}}}';
	  } else if (element.elementType === 'link') {
		const coordinates = JSON.stringify(element.coordinates);
		json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"link_id":"' + element.linkId + '",' +
		  '"parents":["' + element.pathId + '"],' +
		  '"capacity":' + capacity + ',' +
		  '"origin":"' + element.origin + '",' +
		  '"destination":"' + element.destination + '",' +
		  '"geometry":{' + '"coordinates":' + coordinates + '},' +
		  '"notes":"' + element.installationNotes + '"}}}';
      } else if (element.elementType === 'segment') {
	    const coordinates = JSON.stringify(element.coordinates);
		const jsonString = element.subType !== 're_path' && element.subType !== 'sa_path' ?
		  element.sublinkIds.map((id, index) => ({
		    sublink_id: id,
		    buffer: element.sublinkBuffer[index],
		    color: element.sublinkColor[index],
		    attenuation: element.sublinkAttenuation[index],
		    distance: element.sublinkDistance[index],
	      })) : element.sublinkIds.map((id, index) => ({
		    sublink_id: id,
		    frecuency: element.sublinkBuffer[index],
		    bw: element.sublinkColor[index],
		    snr: element.sublinkAttenuation[index],
		    distance: element.sublinkDistance[index],
	      }));
	    const sublinkText = JSON.stringify(jsonString);
		json_data = json_data + '"link_id":"' + element.linkId + '",' +
		  '"segment_id":"' + element.segmentId + '",'
		  '"segment_type":"' + element.sectionType + '",'
		  '"parents":["' + element.linkId + '"],' +
		  '"capacity":' + capacity + ',' +
	      '"sublinks":' + sublinkText + ',';
		  '"origin":"' + element.origin + '",' +
		  '"destination":"' + element.destination + '",' +
		  '"geometry":{' + '"coordinates":' + coordinates + '},' +
		  '"notes":"' + element.installationNotes + '"}}}';
	  } else if (element.elementType === 'data_link') {
		const sla = element.sla >= 0 &&  element.sla <= 100 ? element.sla.toFixed(2) : '98';
	    const critic = element.critic === true ? 'true' : 'false';
	    const notifEnable = element.notifEnable === true ? 'true' : 'false';
		const coordinates = JSON.stringify(element.coordinates);
		json_data = json_data + '"link_id": "' + element.linkId + '",' +
		  '"segment_id":"' + element.segmentId + '",' +
		  '"parents":["' + element.segmentId + '"],';
		  json_data = json_data + '"monitoring":{' + '"link_type":"'  + element.subType + '",' +
		    '"capacity":' + capacity + ',' +
		    '"origin":"' + element.origin + '",' +
		    '"origin_port":"' + element.originPort + '",' +
		    '"destination":"' + element.destination + '",' +
		    '"destination_port":"' + element.destinationPort + '",' +
		    '"local_panel":"' + element.localPanel + '",' +
		    '"remote_panel":"' + element.remotePanel + '",' +
		    '"local_port":"' + element.localPort + '",' +
		    '"remote_port":"' + element.remotePort + '",' +
		    '"target_sla":"' + sla + '",' +
		    '"critic":"' + critic + '",' +
		    '"notification_enable":"' + notifEnable + '"},' +
		  '"geometry":{' + '"coordinates":' + coordinates + '},' +
		  '"notes":"' + element.installationNotes + '"}}}';
      } 
	} else {
	  const sublinks = element.fiberIds.map((id, index) => ({
		fiber_id: id,
		attenuation: element.eventAttenuation[index],
	  }));
	  const sublinkText = JSON.stringify(sublinks);
	  const relations = element.relationIds.map((id, index) => ({
		asset_id: id,
		port_id: element.relationPorts[index],
	  }));
	  const relationIdsText = JSON.stringify(relations);
	  json_data = json_data + '"technical_details":{ ' +
		'"element_id":"' + objectIdx + '",';
	  if (element.elementType === 'splicer') {
		if (element.subType === 'connector') {
		  json_data = json_data + '"parents": ["' + element.parentsIds[0] + '"],' +
		  '"capacity":' + capacity + ',' +
		  '"connector": "' + element.connector + '",' +
		  '"duplex": "' + element.duplex + '",'
		  '"relations":' + relationIdsText + ',';
		} else {
		  json_data = json_data + '"splicings":' + sublinkText + ',' +
		  '"segment_id":"' + element.segmentId + '",' +
		  '"path_event_id":"' + element.pathEventId + '",' +	
		  '"parents":["' + element.segmentId + '"],';
		}
	  } else {
	    json_data = json_data + '"splittings":' + sublinkText + ',';
	  }
	  json_data = json_data + '"technician":"' + element.technician + '",' + 
		'"installation_date":"' + element.installationDate + '",' +
	    '"notes":"' + element.installationNotes + '"}}}';
	}

	const elementToUpdate = json_values + json_data;
	/*if (element.elementType === 'asset') {
	  console.log(elementToUpdate);
	}
	axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	try {
	  const response = await axios.post(pluginApi, elementToUpdate);
	  if (response.statusText === 'OK') {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, ['Elemento actualizado correctamente']);
        return true;
      } else {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
        return false;
      }
	} catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Error al actualizar elemento: ' + error.response.status]);
      return false;
    }
	return false;*/
	return true;
  }
};

export const L1OyMTable: FC<l1OyMTableProps> = ({
  renderCount,
  isAdmin,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  assets,
  products,
  places,
  types,
  sites,
  l1Links,
  ppList,
  pluginVariables,
  isKmzAddMode
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const statusToSubtype = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const subtypeToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.state);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 10,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'subType',
      label: 'Tipo',
      renderCell: (item) => {
		return item.data.subType.length ? (
          <Text text={subtypeToString(item.data.subType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.status);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
    {
      id: 'version',
      label: 'Versión',
      renderCell: (item) => {
		return item.data.version ? (
          <Text text={String(item.data.version)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 3,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 5,
	});
	cols.push({
	  id: 'updated',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.updated ? (
		  <Text text={item.data.updated} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 6,
	});
  }

  //console.log(elements);
  const elementsFiltered = useMemo(
    () => elements.filter(ele => ele.status !== 'delete'), [elements]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (elementsFiltered.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        items={dynamicTableElements}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		assets={assets}
		products={products}
		places={places}
		types={types}
		sites={sites}
		l1Links={l1Links}
		ppList={ppList}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};

export interface L1OyMMapProps {
  renderCount: number;
  isAdmin: boolean;
  onImportData: (newElements: L1OyMData[]) => void;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const L1OyMMap: FC<L1OyMMapProps> = ({
  renderCount,
  isAdmin,
  onImportData,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}) => {
  const elementsFiltered = useMemo(
    () => getFilteredElements(
	  elements,
	  true,
	  filters?.title,
	  filters?.elementType,
	  filters?.subType,
	  filters?.place,
	  filters?.hasChange,
	  filters?.version,
	  '', ''
	), [elements, filters]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (elements.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicMap
        renderCount={renderCount}
		items={dynamicTableElements}
		onImportData={onImportData}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		places={places}
		types={types}
		sites={sites}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};