import { css, cx } from '@emotion/css';
import React, { ReactNode, useEffect, useState, useMemo } from 'react';
import { locationService, getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2, AppEvents } from '@grafana/data';
import { IconButton, Pagination, useStyles2, Icon } from '@grafana/ui';
import { usePagination } from 'app/features/alerting/unified/hooks/usePagination';
import { getPaginationStyles } from 'app/features/alerting/unified/styles/pagination';
import { L1OyMExpandedForm } from './L1OyMExpandedForm';
import { updateElement, cleanElements, saveElement, saveImportData, updateImportElements, getFilteredElements } from './L1OyMTable';
import { L1OyMData } from './L1OyMManager';
import { MapComponent } from './PointPickerModal';
import { L1OyMMapForm } from './L1OyMMapForm';

interface DynamicTablePagination {
  itemsPerPage: number;
}

export interface DynamicTableColumnProps<T = unknown> {
  id: string | number;
  label: string;
  renderCell: (item: DynamicTableItemProps<T>, index: number) => ReactNode;
  size?: number | string;
}

export interface DynamicTableItemProps<T = unknown> {
  id: string | number;
  data: T;
  renderExpandedContent?: () => ReactNode;
}

export interface DynamicTableProps<T = unknown> {
  cols: Array<DynamicTableColumnProps<T>>;
  items: Array<DynamicTableItemProps<T>>;
  isExpandable?: boolean;
  pagination?: DynamicTablePagination;
  paginationStyles?: string;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  renderPrefixHeader?: () => ReactNode;
  renderPrefixCell?: (
    item: DynamicTableItemProps<T>,
    index: number,
    items: Array<DynamicTableItemProps<T>>
  ) => ReactNode;
  isDark:boolean;
  isAdmin: boolean;
  footerRow?: JSX.Element;
  width: number;
  height: number;
  elements: L1OyMData[];
  assets: [];
  products: [];
  places: [];
  types: [];
  sites: [];
  l1Links: [];
  ppList: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const DynamicTable = <T extends object>({
  cols,
  items,
  isExpandable,
  pagination,
  paginationStyles,
  onSave,
  onCancel,
  filters,
  renderPrefixHeader,
  renderPrefixCell,
  isDark,
  isAdmin,
  footerRow,
  width,
  height,
  elements,
  assets,
  products,
  places,
  types,
  sites,
  l1Links,
  ppList,
  pluginVariables,
  isKmzAddMode
}: DynamicTableProps<T>) => {
  const defaultPaginationStyles = useStyles2(getPaginationStyles);
  const styles = useStyles2(getStyles(cols, isExpandable, !!renderPrefixHeader, isDark));
  const [expandedIds, setExpandedIds] = useState<Array<DynamicTableItemProps['id']>>([]);
  const [colOrder, setColOrder] = useState('');
  const [orderMode, setOrderMode] = useState('');
  const [count, setCount] = useState(0);
  const itemsFiltered = useMemo(
    () => getFilteredElements(
	  items,
	  false,
	  filters?.title,
	  filters?.elementType,
	  filters?.subType,
	  filters?.place,
	  filters?.hasChange,
	  filters?.version,
	  colOrder,
	  orderMode
	), [items, filters, colOrder, orderMode]
  );

  const toggleExpanded = (item: DynamicTableItemProps<T>) => {
    if (expandedIds.includes(item.id)) {
	  setExpandedIds(expandedIds.filter((itemId) => itemId !== item.id));
	  setCount(-1);
    } else {
	  setExpandedIds([item.id]);
	  setCount(item.id);
	}
  };

  const itemsPerPage = pagination?.itemsPerPage ?? itemsFiltered.length;
  const { page, numberOfPages, onPageChange, pageItems } = usePagination(itemsFiltered, 1, itemsPerPage);
  const isAddMode = items.length === 1 && items[0].data.version === 0 ? true: false;

  if (isAddMode) {
	if (!expandedIds.includes(items[0].data.id)) {
	  setExpandedIds([items[0].data.id]);
	}
  }

  return (
    <>
      <div className={styles.container} style={{ height: height }}>
        <div className={styles.header}>
          {renderPrefixHeader && renderPrefixHeader()}
          {isExpandable && <div className={styles.cell} />}
          {cols.map((col) => (
            <div 
			  className={colOrder === col.id ? styles[`boldCell_${orderMode}`] : styles.cell} 
			  key={col.id} 
			  onClick={() => { 
				if (colOrder === col.id) {
				  if (orderMode === 'asc') {
				    setOrderMode('desc');
				  } else {
				    setOrderMode('asc');
				  }
				} else {
				  setColOrder(col.id);
				  setOrderMode('asc');
				}
			  }}
			>
              {col.label} 
            </div>
          ))}
        </div>
        {pageItems.map((item, index) => {
		  const isItemExpanded = expandedIds.includes(item.id);
		  return (
            <div
              className={styles.row}
              key={`${item.id}-${index}`}
            >
              {renderPrefixCell && renderPrefixCell(item, index, itemsFiltered)}
              {(isExpandable) && (
                <div className={cx(styles.iconCell, styles.expandCell)}>
                  <IconButton
                    aria-label={`${isItemExpanded ? 'Collapse' : 'Expand'} row`}
                    size={'xl'}
                    className={styles.expandButton}
                    name={isItemExpanded ? 'angle-down' : 'angle-right'}
                    onClick={() => toggleExpanded(item)}
                    type="button"
                  />
                </div>
              )}
              {(!isExpandable) && (
                <div className={cx(styles.cell, styles.expandCell)}>
                  <Icon
                    size="xs"
                    className={styles.expandIcon}
                    name={'minus'}
                  />
                </div>
              )}
              {cols.map((col) => (
				<div 
				  className={cx(styles.cell, styles.bodyCell)} 
				  data-column={col.label} 
				  key={`${item.id}-${col.id}`}
				>
                  {col.renderCell(item, index)}
                </div>
              ))}
              {isItemExpanded && (
                <div className={styles.expandedContentRow}>
                  <L1OyMExpandedForm
					renderCount={count}
					onCancel={(item) => {
					  onCancel();
					  toggleExpanded(expandedIds);
					}}
					onSave={(item, typeOfUpdate) => {
					  const actualElements = cleanElements(itemsFiltered);
					  const newElements = updateElement(actualElements, item);
					  saveElement(newElements, pluginVariables[0]).then((resultado) => {
						if (resultado && typeOfUpdate === 'full') {
						  onSave(newElements);
						  toggleExpanded(expandedIds);
						} else if (typeOfUpdate === 'full') {
						  onCancel();
						  toggleExpanded(expandedIds);
						}
					  }).catch((error) => {
						console.error('Error al guardar el elemento:', error);
						onCancel();
						toggleExpanded(expandedIds);
					  });
					}}
					element={item.data}
					isDark={isDark}
					isAdmin={isAdmin}
					width={width}
					elements={elements}
					assets={assets}
					products={products}
					places={places}
					types={types}
					sites={sites}
				    l1Links={l1Links}
				    ppList={ppList}
					pluginVariables={pluginVariables}
					tableMode={true}
				  />
                </div>
              )}
            </div>
          );
        })}
        {footerRow && <div className={cx(styles.row, styles.footerRow)}>{footerRow}</div>}
      </div>
      {pagination && (
        <Pagination
          className={cx(defaultPaginationStyles, paginationStyles)}
          currentPage={page}
          numberOfPages={numberOfPages}
          onNavigate={onPageChange}
          hideWhenSinglePage
        />
      )}
    </>
  );
};

export const systemMessage = async (message: string): Promise<boolean> => {
  if (message) {
	try {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertSuccess, [message]);
      return true;
	} catch (error) {
      console.error(error);
      return false;
    }
  }
};

export interface DynamicMapProps<T = unknown> {
  renderCount: number;
  items: Array<DynamicTableItemProps<T>>;
  onImportData: (newElements: []) => void;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  isDark:boolean;
  isAdmin: boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const DynamicMap = <T extends object>({
  renderCount,
  items,
  onSave,
  onImportData,
  onCancel,
  filters,
  isDark,
  isAdmin,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}: DynamicMapProps<T>) => {
  const styles = useStyles2(getStyles(null, false, false, isDark));
  const uidVariable = getTemplateSrv().replace(`$${pluginVariables[9]}`) || '';
  //console.log(items, elements);
  const itemSelected = uidVariable !== '' ? elements.find(ele => ele.uid === uidVariable) : undefined;
  const [elementSelected, setElementSelected] = useState(itemSelected);
  let actualCenter = undefined;
  let actualZoom = undefined;
  const [colOrder, setColOrder] = useState('');
  const [orderMode, setOrderMode] = useState('');
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();
  const [count, setCount] = useState(renderCount);
  //const [coordinates, setCoordinates] = useState(element ? element.coordinates : []);

  const handleLineCoordsChange = (coords, elementUid) => {
	if (elementUid && elementUid !== null) {
	  const element = elementSelected;
	  const index = elements.findIndex(ele => ele.uid=== elementUid);
      if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
	    element.coordinates = coords;
		element.wasChange = true;
	  }
	  setElementSelected(element);
	  elements[index] = element;
	}
  };

  const handlePlaceCoordsChange = (coords, elementId) => {
	if (elementId && elementId !== null) {
	  const element = elementSelected;
	  const index = elements.findIndex(ele => ele.elementId === elementId);
      if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
	    element.coordinates[0] = parseFloat(coords[0]);
	    element.coordinates[1] = parseFloat(coords[1]);
		element.wasChange = true;
	  }
	  setElementSelected(element);
	  elements[index] = element;
	}
  };

  const itemsFiltered = useMemo(
    () => getFilteredElements(
	  items,
	  false,
	  filters?.title,
	  filters?.elementType,
	  filters?.subType,
	  filters?.place,
	  filters?.hasChange,
	  filters?.version,
	  colOrder,
	  orderMode
	), [items, filters]
  );

  return (
    <>
      <div className={styles.container}>
		<div className={styles.mapContainer}>
		  <L1OyMMapForm
			renderCount={count}
			onCancel={(item) => {
			  setElementSelected(undefined);
			  setCount(count + 1);
			}}
		    onCoordinatesChange={(coords, currentPlace) => {
			  handlePlaceCoordsChange(coords, currentPlace);
		    }}
		    onLineChanges={(coords, currentUid) => {
			  handleLineCoordsChange(coords, currentUid);
		    }}
			onImportData={(newElements, file) => {
			  saveImportData(newElements, elements.length, file).then((resultado) => {
			    if (resultado !== null) {
				  const elementsUpdated = updateImportElements(elements, resultado);
				  const placeUpdated = elementsUpdated.filter(value => value.elementType === 'emp' && value.wasChange === true);
				  const spanUpdated = elementsUpdated.filter(value => value.elementType === 'span' && value.wasChange === true);
				  const pathUpdated = elementsUpdated.filter(value => value.elementType === 'path' && value.wasChange === true);
				  if (placeUpdated.length > 0 || spanUpdated.length > 0) {
				    let infoText = placeUpdated.length > 0 ? 
					  'Se importaron ' + String(placeUpdated.length) + ' emplazamientos,' : 'Se importaron ';
					infoText = pathUpdated.length > 0 ? 
					  infoText + String(pathUpdated.length) + ' Trayectos, ' : infoText;
					infoText = spanUpdated.length > 0 ? 
					  infoText + String(spanUpdated.length) + ' tramos de trayectos.' : infoText;
					systemMessage(infoText);
				  } else {
					const infoText = 'No se pudo importar ningun elemento';
					systemMessage(infoText);
					onCancel();
				  }
				  onImportData(elementsUpdated);
				} else {
				  onCancel();
				}
			  }).catch((error) => {
				console.error('Error al guardar el elemento:', error);
				onCancel();
			  });
			}}
			onSave={(item, typeOfUpdate) => {
			  const actualElements = cleanElements(items);
			  const newElements = updateElement(actualElements, item);
			  saveElement(newElements, pluginVariables[0]).then((resultado) => {
				if (resultado && typeOfUpdate === 'full') {
				  onSave(newElements);
				} else if (typeOfUpdate === 'full') {
				  onCancel();
				}
			  }).catch((error) => {
				console.error('Error al guardar el elemento:', error);
				onCancel();
			  });
			}}
			onSelect={(uid) => {
			  let eleSelected = elements.find(ele => ele.uid === uid);
			  eleSelected = eleSelected ? eleSelected : items.find(ele => ele.data.uid === uid);
			  if (eleSelected) {
				if (eleSelected.elementType === 'path') {
				  eleSelected = elements.find(ele => ele.uid === uid && ele.pathId === uid);
				}
			    setElementSelected(eleSelected);
				setZoom(actualZoom);
				setCenter(actualCenter);
			    setCount(count + 1);
			  }
			}}
		    onPosChange={(center, zoom) => {
			  actualCenter = center;
			  actualZoom = zoom;
		    }}
			items={itemsFiltered}
			element={elementSelected}
			isDark={isDark}
			isAdmin={isAdmin}
			width={width}
			height={height}
			elements={isKmzAddMode ? [] : elements}
			places={places}
			types={types}
			sites={sites}
			pluginVariables={pluginVariables}
			isKmzAddMode={isKmzAddMode}
			zoom={zoom}
			center={center}
		  />
		</div>
      </div>
    </>
  );
};


const getStyles = <T extends unknown>(
  cols: Array<DynamicTableColumnProps<T>>,
  isExpandable: boolean,
  hasPrefixCell: boolean,
  isDark: boolean
) => {
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  const linkColor = isDark ? '#557FFF' : '#6C63FE';
  let sizes = ['auto'];
  
  if (cols !== null) {
    sizes = cols.map((col) => {
      if (!col.size) {
        return 'auto';
      }
      if (typeof col.size === 'number') {
        return `${col.size}fr`;
      }
      return col.size;
    });
  }

  if (isExpandable) {
    sizes.unshift('calc(1em + 16px)');
  }

  if (hasPrefixCell) {
    sizes.unshift('0');
  }

  return (theme: NetMonitorTheme2) => ({
    container: css`
      color: ${fontColor};
      font-size: 12px;
	  overflow: auto;
	  padding-left: 3px;
    `,
    header: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;
      border-bottom: 1px solid ${theme.v1.colors.formInputBorder};
      font-weight: 600;
    `,
    row: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;

      &:nth-child(2n + 1) {
        background-color: ${theme.colors.background.secondary};
      }

      &:nth-child(2n) {
        background-color: ${theme.colors.background.primary};
      }
    `,
    footerRow: css`
      display: flex;
      padding: 12px 4px 0px 4px;
    `,
    cell: css`
      align-items: center;
      margin-left: -8px;
      padding:  13px 4px 0px 4px;
	  cursor: pointer;
    `,
    boldCell_desc: css`
      align-items: center;
      margin-left: -8px;
      padding:  13px 4px 0px 4px;
      color: ${linkColor};
	  cursor: pointer;

	  &::after {
	    content: ' ↑';
	    font-size: inherit;
	    color: inherit;
	  }
    `,
    boldCell_asc: css`
      align-items: center;
      margin-left: -8px;
      padding:  13px 4px 0px 4px;
      color: ${linkColor};
	  cursor: pointer;

	  &::after {
	    content: ' ↓';
	    font-size: inherit;
	    color: inherit;
	  }
    `,
    iconCell: css`
      margin-left: -2px;
      padding:  16px 4px 0px 4px;

      ${theme.breakpoints.down('sm')} {
        padding:  12px 0px 0px 0px;
        grid-template-columns: 1fr;
      }
    `,
    bodyCell: css`
      overflow: hidden;
    `,
    expandCell: css`
      justify-content: center;
    `,
    expandedContentRow: css`
      grid-column-end: ${sizes.length + 1};
      grid-column-start: ${hasPrefixCell ? 3 : 2};
      grid-row: 2;
      padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(1)};
      position: relative;

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    expandButton: css`
      margin-right: 0;
      display: block;
    `,
    expandIcon: css`
      margin-top: 5px;
      margin-right: 0;
      display: block;
    `,
  });
};
