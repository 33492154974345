import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { l1OyMManagerPanel } from './L1OyMManagerPanel';
import { L1OyMPanelOptions } from './types';

export const plugin = new PanelPlugin<L1OyMPanelOptions>(l1OyMManagerPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'uid',
      path: 'uid',
      name: 'UID Field',
      defaultValue: 'uid',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'title',
      path: 'title',
      name: 'Title Field',
      defaultValue: 'title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'technicalDetails',
      path: 'technicalDetails',
      name: 'Technical details Field',
      description: 'Technical details in JSON format',
      defaultValue: 'tech_details',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'updated_at',
      path: 'updated_at',
      name: 'Last Update Field',
      defaultValue: 'updated_at',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'created_at',
      path: 'created_at',
      name: 'Creation Date Field',
      defaultValue: 'created_at',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'user',
      path: 'user',
      name: 'User Field',
      description: 'The user who modified the element for the last time',
      defaultValue: 'user',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'version',
      path: 'version',
      name: 'Version Field',
      description: 'The number of versión for this IDX',
      defaultValue: 'version',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addSelect({
      path: 'mapSource',
      name: 'Map type',
      description: 'This setting controls the map tiles type',
      category: ['Geo Map'],
	  settings: {
        options: [
          { value: 'World_Street_Map', label: 'World Street Map' },
          { value: 'World_Imagery', label: 'World Imagery' },
          { value: 'World_Physical_Map', label: 'World Physical' },
          { value: 'World_Topo_Map', label: 'World Topographic' },
        ],
      },
      defaultValue: 'World_Street_Map',
    })
    .addBooleanSwitch({
      path: 'useZoomVariable',
      name: 'Use Zoom, x and y variables',
	  category: ['Geo Map'],
      defaultValue: true,
    })
    .addTextInput({
      path: 'zoomVariable',
      name: 'zoom Variable',
	  category: ['Geo Map'],
      defaultValue: 'z',
      showIf: (config) => config.useZoomVariable === true,
    })
    .addTextInput({
      path: 'xVariable',
      name: 'X pos Variable',
      category: ['Geo Map'],
      description: 'Ingress 0 if you want center de graph.',
	  defaultValue: 'x',
      showIf: (config) => config.useZoomVariable === true,
    })
    .addTextInput({
      path: 'yVariable',
      name: 'Y Pos Variable',
	  category: ['Geo Map'],
      description: 'Ingress 0 if you want center de graph.',
	  defaultValue: 'y',
      showIf: (config) => config.useZoomVariable === true,
    })

    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'variableToTrack',
      name: 'Main filter variable to Track',
      description: 'This field is used to determine if plugin need to be re-renderized',
      defaultValue: '',
      category: ['General Settings'],
    })
    .addTextInput({
      path: 'processList',
      name: 'Process List',
      category: ['General Settings'],
      description: 'A comma separeted list of defined process respecting the following format: key||status||nombre||actvio||proceso||ruta||parametro||assetManager',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'servicesList',
      name: 'Service List',
      category: ['General Settings'],
      description: 'A comma separeted list of defined services respecting the following format: key||status||nombre||activo||servicio||assetManager',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'appsList',
      name: 'Application List',
      category: ['General Settings'],
      description: 'A comma separeted list of defined apps respecting the following format: key||status||nombre||url||http||http_port||https||https_port||sitio||api||assetManager',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'assetList',
      name: 'Asset List',
      category: ['General Settings'],
      description: 'A comma separeted list of defined asset respecting the following format: nombre||etiqueta||estado||emplazamiento||sitio||tipo||producto||ip||sla||critico||if_notif||cuadrilla||asociado||assetManager',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'localAssetPortList',
      name: 'Local Asset Ports',
      category: ['General Settings'],
      description: 'A comma separeted list of available local asset ports the following format: ifname||ifalias|',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'remoteAssetPortList',
      name: 'Remote Asset Ports',
      category: ['General Settings'],
      description: 'A comma separeted list of available remote asset ports the following format: ifname||ifalias|',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'productList',
      name: 'Product List',
      category: ['General Settings'],
      description: 'A comma separeted list of available product respecting the following format: tipo||producto||protocolo||comunidad||objectid',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'siteList',
      name: 'Site List',
      category: ['General Settings'],
      description: 'A comma separeted list of available collectors respecting the following format: Label||Description||placeID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'placeList',
      name: 'Place List',
      category: ['General Settings'],
      description: 'A comma separeted list respecting the following format: placeLabel||placeDescription||placeType||placeID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'placeTypeList',
      name: 'Place Type List',
      category: ['General Settings'],
      description: 'A comma separeted place type list respecting the following format: typeLabel||typeDescription||typeValue',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'linkList',
      name: 'Link List',
      category: ['General Settings'],
      description: 'A comma separeted list of available link defined following format: Emp||Local_PatchP||local_Port||Remote_PatchP||Remote_Port||Asset||Interface||TxRx||connector',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'intetLinkList',
      name: 'Internet Link List',
      category: ['General Settings'],
      description: 'A comma separeted list of available Internet link defined',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'uidVariable',
      name: 'UID Variable',
      category: ['General Settings'],
      description: 'UID variable to modify when select an element',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'localAssetVariable',
      name: 'Local Asset Variable',
      category: ['General Settings'],
      description: 'Local Asset variable to modify when select an ID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'remoteAssetVariable',
      name: 'Remote Asset Variable',
      category: ['General Settings'],
      description: 'Remote Asset variable to modify when select an ID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'placeVariable',
      name: 'Place Variable',
      category: ['General Settings'],
      description: 'Place variable to modify when select an ID',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'placeTypesVariable',
      name: 'Place Types Variable',
      category: ['General Settings'],
      description: 'variable to modify when select differente asset Type',
	  defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete alert channels',
      category: ['Options'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'showTableModeVariable',
      name: 'Variable name to shich Map/Table mode',
      description: 'Enable plugin to work in Map/table mode',
      category: ['Options'],
      defaultValue: 'mode',
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showPlaceSelector',
      name: 'show asset selector',
      description: 'Enable Asset selector inside plugin',
      category: ['Options'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Firmas customizadas - Reglas configuradas',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Firma modificada exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      path: 'drillDownLink_asset',
      name: 'Asset URL',
      category: ['Link to other features'],
      defaultValue: '/d/networking_datos_dispositivos?var-device=',
    })
    .addTextInput({
      path: 'drillDownLink_site',
      name: 'Site URL',
      category: ['Link to other features'],
      defaultValue: '/d/informacion_emp?var-emplazamiento=',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
