import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { HelpData } from './HelpManager';
import { Text } from './Tags';
import { valueToType, valueToSubtype, valueToStatus } from './types';
import { DynamicTable, DynamicTableColumnProps, DynamicTableItemProps, DynamicToc } from './HelpDynamicTable';

export interface HelpTableProps {
  id: string;
  isAdmin: boolean;
  onSave: (elements: HelpData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; docType?: string; subType?: string; section?: string; chapter?: string };
  isDark:boolean;
  width: number;
  height: number;
  elements: HelpData[];
  pluginVariables: [];
  showTableMode: boolean;
}

type DocTableColumnProps = DynamicTableColumnProps<HelpData>;
type DocTableItemProps = DynamicTableItemProps<HelpData>;

export const getFilteredElements = (
  elements: HelpData[],
  isElement?: boolean,
  title?: string,
  docType?: string,
  subType?: string,
  section?: string,
  chapter?: string,
  colOrder?: string,
  mode?: string
) => {
  let elementsFiltered: [] = [];
  elements.forEach((ele) => {
	const element = isElement ? ele : ele.data;    
	let mustFilter = false;
	if (title !== undefined) {
	  mustFilter = element.title.toLowerCase().includes(title.toLowerCase()) ? mustFilter : true;
    }
	if (docType !== undefined && subType !== undefined) {
	  mustFilter = element.docType === docType && element.subType === subType ? mustFilter : true;
	} else if (docType !== undefined) {
	  mustFilter = element.docType === docType ? mustFilter : true;
    } else if (subType !== undefined) {
	  mustFilter = element.subType === subType ? mustFilter : true;
    } 
	if (section !== undefined) {
	  mustFilter = element.section === section ? mustFilter : true;
	}
	if (chapter !== undefined) {
	  mustFilter = element.chapter === chapter ? mustFilter : true;
	}
	if (!mustFilter) {
	  elementsFiltered.push(element);
	}
  });

  let sortedElements = elementsFiltered;

  if (colOrder !== '') {
    sortedElements = elementsFiltered.sort((a, b) => {
      if (typeof a[colOrder] === "string") {
        return mode === 'asc'
          ? a[colOrder].localeCompare(b[colOrder])
          : b[colOrder].localeCompare(a[colOrder]);
      } else {
        return mode === 'asc'
          ? a[colOrder] - b[colOrder]
          : b[colOrder] - a[colOrder];
      }
    });
  } else {
	sortedElements = elementsFiltered.sort((a, b) => {
      if (a.docType < b.docType) return 1;
      if (a.docType > b.docType) return -1;

      if (a.subType < b.subType) return 1;
      if (a.subType > b.subType) return -1;

      if (a.section < b.section) return -1;
      if (a.section > b.section) return 1;

      if (a.chapter < b.chapter) return -1;
      if (a.chapter > b.chapter) return 1;

      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
    });
  }
  if (isElement) {
    return sortedElements;
  } else {
	const sortedItems = sortedElements.map(item => ({
	  data: item,
	  id: item.id
	}));
	return sortedItems;
  }
};

export const cleanElements = (items: HelpData[]): HelpData[] => {
  const cleannerElements: HelpData[] = [];
  for (let i = 0; i < items.length; i++) {
	const item = items[i].data;
	let elementData = item;
	cleannerElements.push(elementData);
  }
  return cleannerElements;
};
  
export const updateElement = (actualElements: HelpData[], item: l1OyM): HelpData[] => {
  const newElements = [...actualElements];
  const editIndex = newElements.findIndex((element) => element.uid === item.uid);
  const userName = contextSrv.user.name;
  const d = Date.now();
  const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
  item.updated = '';
  item.version = item.version + 1;
  item.user = userName;
  if (editIndex >= 0) {
    newElements[editIndex] = {
      ...newElements[editIndex],
      ...item,
    };
  }
  return newElements;
};

export const saveElement = async (elements: HelpData[], pluginApi: string): Promise<boolean> => {
  const element = elements.find(element => element.updated === '');
  const reference = element.alarmId !== '' ? ', "alarm_id": "' + element.alarmId + '"' : '';
  const reference2 = element.panelId !== '' ? ', "panel_id": "' + element.panelId + '"' : '';
  const reference3 = element.placeId !== '' ? '"place_Id": "' + element.placeId + '"' : '';
  let globalReference = element.docType === 'notes' ? 
	'"hash_ids": "' + element.hashIds + '"' + reference + reference2 : '';
  globalReference = element.docType === 'sitedoc' ? reference3 : globalReference;

  if (element) {
    const json_values = '{"json_values": {' + 
      '"title":"' + element.title + '",' + 
	  '"uid":"' + element.uid + '",' + 
	  '"user":"' + element.user + '",' + 
	  '"version":' + element.version + ',' + 
	  '"groupname":"' + element.docType + '",' +
	  '"reference": {' + globalReference + '} }';
    var objectId = element.docId;

	if ((objectId === undefined || objectId === 'undefined' || objectId === '') && element.uid !== undefined) {
	  objectId = element.uid;
	}

	var json_data = ', "json_data": {' +
	  '"technical_details": {' +
		'"status": "' + element.status + '",' +
		'"docId": "' + objectId + '",' +
		'"category": "' + element.category + '",' +
		'"type": "' + element.subType + '",' +
		'"chapter": "' + element.chapter + '",' +
		'"section": "' + element.section + '",' +
		'"references": "' + element.references + '"';
	  if (element.relatedArticles) {
		if (typeof element.relatedArticles === 'string') {
		  json_data = json_data + ', "related_articles": {' + element.relatedArticles + '} ';
		} else {
		  json_data = json_data + ', "related_articles": ' + JSON.stringify(element.relatedArticles);
		}
	  }
	  json_data = json_data + ', "edition_data": { "last_editor": "' + element.lastEditor + '",' +
		  '"notes": "' + element.editionNotes + '"';
	  if (element.mediaData) {
		if (typeof element.mediaData === 'string') {
		  json_data = json_data + ', "media": {' + element.mediaData + ' } }';
		} else {
		  json_data = json_data + ', "media": ' + JSON.stringify(element.mediaData) + ' }';
		}
	  } else {
		json_data = json_data + ' }';
	  }
	  if (element.content) {
	    if (element.content.startsWith('{"markdownContent":')) {
		  json_data = json_data +	', "content": ' + element.content;
		} else {
		  const contentStringify = JSON.stringify({ markdownContent: element.content });
		  json_data = json_data +	', "content": ' + contentStringify;
		}
	  }
	  if (element.docType === 'notes') {
	    json_data = json_data + ', "alarm_id": "' + element.alarmId + '",' +
		'"panel_id": "' + element.panelId + '" } } }';
	  } else if (element.docType === 'sitedoc') {
	    json_data = json_data + ', "asset_id": "' + element.assetId + '",' +
		'"place_id": "' + element.placeId + '" } } }';
	  } else {
	    json_data = json_data + '} } }';
	  }

	var elementToUpdate = json_values + json_data;

	axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	try {
	  const response = await axios.post(pluginApi, elementToUpdate);
	  if (response.statusText === 'OK') {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, ['Documento actualizado correctamente']);
        return true;
      } else {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
        return false;
      }
	} catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Error al actualizar elemento: ' + error.response.status]);
      return false;
    }
	return false;
  }
};

export const HelpTable: FC<HelpTableProps> = ({
  id,
  isAdmin,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  pluginVariables,
  showTable,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: DocTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const statusToSubtype = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const subtypeToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const tocCols: DocTableColumnProps[] = [
    {
      id: 'section',
      label: 'Sección | Capítulo',
      renderCell: (item) => {
		return item.data.section.length ? (
          <Text text={item.data.section + ' - ' + item.data.chapter + ': ' + item.data.title} enable={item.data.state} />
        ) : (
          <Text text={item.data.chapter.length ? item.data.chapter + ': ' + item.data.title : item.data.title} enable={item.data.state} />
        );
      },
      size: 8,
    }
  ];

  const resCols: DocTableColumnProps[] = [
    {
      id: 'title',
      label: 'Título',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'docType',
      label: 'Tipo',
      renderCell: (item) => {
		return item.data.docType.length ? (
          <Text text={typeToString(item.data.docType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 3,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.state);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
  ];

  const cols: DocTableColumnProps[] = [
    {
      id: 'title',
      label: 'Título',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 7,
    },
  ];
  if (!pluginVariables[5]) {
    cols.push({
      id: 'docType',
      label: 'Tipo de Documento',
      renderCell: (item) => {
		return item.data.docType.length ? (
          <Text text={typeToString(item.data.docType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 3,
    });
    cols.push({
      id: 'subType',
      label: 'Alcance',
      renderCell: (item) => {
		return item.data.subType.length ? (
          <Text text={subtypeToString(item.data.subType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 3,
    });
  }

  cols.push({
    id: 'section',
    label: 'Sección',
    renderCell: (item) => {
	  return item.data.section.length ? (
        <Text text={item.data.section} enable={item.data.state} />
      ) : (
        <Text text={'-'} />
      );
    },
    size: 5,
  });
  cols.push({
    id: 'chapter',
    label: 'Capítulo',
    renderCell: (item) => {
	  return item.data.chapter.length ? (
        <Text text={item.data.chapter} enable={item.data.state} />
      ) : (
        <Text text={'-'} />
      );
    },
    size: 5,
  });

  if (!pluginVariables[5]) {
    cols.push({
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.status);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 4,
    });
  }
  if (isAdmin && !pluginVariables[5]) {
    cols.push({
      id: 'version',
      label: 'Versión',
      renderCell: (item) => {
		return item.data.version ? (
          <Text text={String(item.data.version)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 2,
    });
    cols.push({
      id: 'uid',
      label: 'UID',
      renderCell: (item) => {
		return item.data.uid.length ? (
          <Text text={item.data.uid} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 2,
    });
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 3,
	});
	cols.push({
	  id: 'updated',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.updated ? (
		  <Text text={item.data.updated} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 3,
	});
  }

  /*const elementsFiltered = useMemo(
    () => getFilteredElements(
	  elements, 
	  filters?.title,
	  filters?.docType,
	  filters?.subType,
	  filters?.section,
	  filters?.chapter,
	  '',''
	), [elements, filters]
  );*/
  const elementsFiltered = useMemo(
    () => elements.filter(ele => ele.status !== 'delete'), [elements]
  );

  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (elementsFiltered.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  if (showTable || pluginVariables[7]) {
    return (
      <DynamicToc
        cols={tocCols}
        items={dynamicTableElements}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements.filter((element) => element.version > 0)}
		pluginVariables={pluginVariables}
      />
    );
  } else {
    return (
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        items={dynamicTableElements}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements.filter((element) => element.version > 0)}
		pluginVariables={pluginVariables}
      />
    );
  }
};