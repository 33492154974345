import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { L1OyMData } from './L1OyMManager';
import { L1OyMGroup } from './L1OyMGroup';
import { saveElement } from './L1OyMTable';
import { 
  valueToSubtype,
  fiberOpticConnectors,
  newObject,
  valueToAssetStatus,
  fiberOpticColors,
  bufferColors,
  multiparColors,
  assetTypes
} from './types';

export const l1OyMManagerPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const userName = contextSrv.user.name;
  const styles = useStyles2(getStyles(width, height));
  const pluginTitle = replaceVariables(options.pluginTitle);
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen elementos';
  const error4 = replaceVariables(options.error4);
  const assetLink = replaceVariables(options.drillDownLink_asset);
  const siteLink = replaceVariables(options.drillDownLink_site);
  const pluginApi = replaceVariables(options.api);
  const mapSource = replaceVariables(options.mapSource);
  const tableValue = replaceVariables(options.showTableModeVariable);
  const processVariableValue = replaceVariables(options.processList);
  const servicesVariableValue = replaceVariables(options.servicesList);
  const appsVariableValue = replaceVariables(options.appsList);
  const assetVariableValue = replaceVariables(options.assetList);
  const localAssetPorts = replaceVariables(options.localAssetPortList);
  const remoteAssetPorts = replaceVariables(options.remoteAssetPortList);
  const productVariableValue = replaceVariables(options.productList);
  const siteVariableValue = replaceVariables(options.siteList);
  const placeVariableValue = replaceVariables(options.placeList);
  const typesVariableValue = replaceVariables(options.placeTypeList);
  const linkVariableValue = replaceVariables(options.linkList);
  const intetLinkVariableValue = replaceVariables(options.intetLinkList);
  const tableMode = options.showTableModeVariable ? getTemplateSrv().replace(`$${options.showTableModeVariable}`) : '0';
  const localAssetPortVariable = replaceVariables(options.localAssetVariable);
  const remoteAssetPortVariable = replaceVariables(options.remoteAssetVariable); 
  const filterToTrack = replaceVariables(options.variableToTrack);

  const showInTableMode = tableMode && tableMode === '1' ? false : true;
  const placeVariable = replaceVariables(options.placeVariable);
  const placeTypesVariable = replaceVariables(options.placeTypesVariable);
  const pluginVariables = [
    pluginApi,
	placeVariable,
	placeTypesVariable,
	noAlertMessage,
	mapSource,
	options.useZoomVariable,
	options.zoomVariable,
	options.xVariable,
	options.yVariable,
	options.uidVariable,
	options.showTableModeVariable,
	assetLink,
	siteLink,
	localAssetPortVariable,
	remoteAssetPortVariable,
	localAssetPorts,
	remoteAssetPorts
  ];
  const [elementsConfigured, setElementsConfigured] = useState<L1OyMData[]>([]);
  const [oldElementsConfigured, setOldElementsConfigured] = useState<L1OyMData[]>([]);
  const [valueTracked, setValueTracked] = useState(filterToTrack);
  const [assetMarkers, setAssetMarkers] = useState([]);
  const [empMarkers, setEmpMarkers] = useState([]);
  const [panelMarkers, setPanelMarkers] = useState([]);
  const [linkMarkers, setLinkMarkers] = useState([]);
  const [confirm, setConfirm] = useState(false);
  let wasConfirm = false;
  let mustUpdate = false;

  const [renderCount, setRenderCount] = useState(0);
  if (filterToTrack !== valueTracked) {
	setValueTracked(filterToTrack);
	setRenderCount(renderCount + 1);
  }

  const subTypeToType = (value: string) => {
	const valueSelected = valueToSubtype.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return 'Unknown';
	}
  }

  const subTypeToSubfamily = (value: string) => {
	const valueSelected = valueToSubtype.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.subFamily;
	} else {
	 return 'any';
	}
  }

  const getPanels = (links: [], atributo: string) => {
    const uniquePanels = links.reduce((capacity, link) => {
	  const isFoConnector = fiberOpticConnectors.find(ele => ele.value === link.connector);
	  let linkType = isFoConnector ? 'odf' : 'patch_panel';
	  linkType = link.localPanel === link.assetId ? 'asset_panel' : linkType;
      const name = link[atributo] + '||' + link['emp'] + '||' + linkType + '||' + link.assetManagerId;
      capacity[name] = (capacity[name] || 0) + 1;
      return capacity;
    }, {});
    return Object.entries(uniquePanels).map(([name, qty]) => (
	  {
        emp: name.split('||')[1],
        panelId: name.split('||')[0],
        capacity: qty,
		panelType: name.split('||')[2],
		importId: name.split('||')[3],
      }
	));
  }

  const assetToMarker = (assets: [], items: []) => {
	let nextId = items.length;
	const assetList: [] = [];
	for (let i = 0; i < assets.length; i++) {
	  const actualAsset = assets[i].value ? items.find(ele => ele.elementType === 'asset' && ele.importId === assets[i].assetManagerId &&
	    (ele.key === assets[i].key || ele.idx === assets[i].value)) : undefined;
	  if (!actualAsset) {
	    const empId = assets[i].emp ? items.find(ele => ele.elementType === 'emp' && ele.idx === assets[i].emp) : null;
		let newElement = newObject(nextId, 'asset', '', assets[i].value, assets[i].label);
	    newElement.pathId = '';
		newElement.elementId = newElement.uid;
		newElement.key = assets[i].key;
		newElement.status = assets[i].status;
		newElement.state = assets[i].state;
		newElement.assetType = assets[i].assetType;
		newElement.assetModel = assets[i].product;
		newElement.subType = assets[i].assetFamily;
		newElement.subFamily = assets[i].subFamily;
	    newElement.siteId = assets[i].site;
	    newElement.groupIds = assets[i].groups;
		newElement.ip = assets[i].ip;
		newElement.fqdn = assets[i].fqdn;
		newElement.protocol = assets[i].protocol;
		newElement.community = assets[i].community;
		newElement.sla = assets[i].sla;
		newElement.critic = assets[i].critic;
		newElement.notifEnable = assets[i].notifEnable;
		newElement.technician = assets[i].manteinance;
		newElement.relationIds = assets[i].relations;
		newElement.orquesterId = assets[i].orquested_by;
		newElement.ovOrquested = assets[i].ov_orquested;
		newElement.clusterMacs = assets[i].clusterMacs;
		newElement.clusterSerials = assets[i].clusterSerials;
		newElement.coordinates = empId !== null && empId !== undefined ? empId.coordinates : [0,0];
		newElement.technician = assets[i].manteinance;
	    newElement.importId = assets[i].assetManagerId;
	    newElement.user = 'Asset Manager';
	    newElement.version = 1;
	    assetList.push(newElement);
	    nextId = 1 + nextId;
		if (isAdmin) {
		  if (!wasConfirm && !confirm) {
		    mustUpdate = window.confirm(
			  `¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		    );
			setConfirm(true);
			wasConfirm = true;
		  }
		  if (mustUpdate) {
		    saveElement([], pluginVariables[0], newElement).then((resultado) => {
		      if (!resultado) {
			    console.log('Error', resultado, newElement);
		      }
		    }).catch((error) => {
		      console.error('Error al guardar el elemento:', error);
		    });
		  }
		}
	  }
    }
	return assetList;
  }

  const appToMarker = (assets: [], items: []) => {
	let nextId = items.length;
	const assetList: [] = [];
	for (let i = 0; i < assets.length; i++) {
	  const actualAsset = assets[i].value ? items.find(ele => ele.elementType === 'asset' && ele.importId === assets[i].assetManagerId &&
	    (ele.key === assets[i].key || ele.idx === assets[i].value)) : undefined;
	  if (!actualAsset) {
		const assetId = assets[i].assetType !== 'web' && assets[i].assetId ? items.find(ele => ele.elementType === 'asset' && ele.idx === assets[i].assetId) : null;
	    let newElement = newObject(nextId, 'asset', '', assets[i].value, assets[i].label);
		newElement.elementId = newElement.uid;
		newElement.key = assets[i].key;
		newElement.status = assets[i].status;
		newElement.state = assets[i].state;
		newElement.parentsIds = assetId !== null ? [assetId] : [];
		newElement.assetType = assets[i].assetType;
		newElement.subType = assets[i].assetFamily;
		newElement.subFamily = assets[i].subFamily;
		if (newElement.assetType === 'process') {
	      newElement.siteId = assets[i].site;
		  newElement.ip = assets[i].ip;
		  newElement.fqdn = assets[i].fqdn;
		  newElement.params = assets[i].params;
	      newElement.path = assets[i].path;
		} else if (newElement.assetType=== 'service') {
		  newElement.params = assets[i].value;
		} else if (newElement.assetType === 'web') {
	      newElement.siteId = assets[i].site;
		  newElement.fqdn = assets[i].fqdn;
		  newElement.params = assets[i].api;
		  newElement.http_enable = assets[i].http_enable;
		  newElement.http_port = assets[i].http_port;
		  newElement.https_enable = assets[i].https_enable;
		  newElement.https_port = assets[i].https_port;
	      newElement.path = assets[i].fqdn;
		}
	    newElement.importId = assets[i].assetManagerId;
	    newElement.user = 'Asset Manager';
	    newElement.version = 1;
	    assetList.push(newElement);
	    nextId = 1 + nextId;
	  }
	}
	if (isAdmin) {
	  if (!wasConfirm && !confirm) {
		mustUpdate = window.confirm(
		  `¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		);
		setConfirm(true);
		wasConfirm = true;
	  }
	  if (mustUpdate) {
		for (let i = 0; i < assetList.length; i++) {
		  saveElement([], pluginVariables[0], assetList[i]).then((resultado) => {
			if (!resultado) {
			  console.log('Error', resultado, assetList[i]);
			}
		  }).catch((error) => {
			console.error('Error al guardar el elemento:', error);
		  });
		}
	  }
	}
	return assetList;
  }

  const placeToMarker = (places: [], items: []) => {
	let nextId = items.length;
	const empToMarker: [] = [];
	for (let i = 0; i < places.length; i++) {
	  const actualEmp = places[i].value ? items.find(ele => ele.elementType === 'emp' && ele.importId === places[i].assetManagerId &&
	    ele.idx === places[i].value) : undefined;
	  if (!actualEmp) {
	    let newElement = newObject(nextId, 'emp', '', places[i].value, places[i].description);
	    newElement.elementId = newElement.uid;
	    newElement.siteId = places[i].site;
		newElement.technician = places[i].manteinance;
		newElement.key = places[i].key;
		newElement.address = places[i].location;
	    newElement.importId = places[i].assetManagerId;
	    newElement.user = 'Asset Manager';
	    newElement.version = 1;
	    empToMarker.push(newElement);
	    nextId = 1 + nextId;
		if (isAdmin) {
		  if (!wasConfirm && !confirm) {
		    mustUpdate = window.confirm(
			  `¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		    );
			setConfirm(true);
			wasConfirm = true;
		  }
		  if (mustUpdate) {
		    saveElement([], pluginVariables[0], newElement).then((resultado) => {
		      if (!resultado) {
			    console.log('Error', resultado, newElement);
		      }
		    }).catch((error) => {
		      console.error('Error al guardar el elemento:', error);
		    });
		  }
		}
	  }
    }
	return empToMarker;
  }

  const panelsToMarker = (panels: [], items: []) => {
	let nextId = items.length;
	const panelToMarker: [] = [];
	for (let i = 0; i < panels.length; i++) {
	  let panelExist = items.find(ele => ele.elementType === 'element' && ele.importId === panels[i].assetManagerId && ele.idx === panels[i].panelId);
	  if (!panelExist) {
	    panelExist = panelToMarker.find(ele => ele.elementType === 'element' && ele.importId === panels[i].assetManagerId && ele.idx === panels[i].panelId);
	  }
	  if (!panelExist) {
	    const empId = panels[i].emp ? items.find(ele => ele.elementType === 'emp' && ele.idx === panels[i].emp) : null;
	    const segmentIds = items.filter(ele => 
		  ele.elementType === 'segment' && (ele.origin === panels[i].panelId || ele.destination === panels[i].panelId)
		);
		const relationIds: [] = [];
		const relationCapacities: [] = [];
		segmentIds.forEach(segment => {
		  relationIds.push(segment.uid);
		  relationCapacities.push(1);
		});
	    let newElement = newObject(nextId, panels[i].panelType, '', panels[i].panelId, 'Panel ' + panels[i].panelId);
	    newElement.elementId = newElement.uid;
		newElement.elementType = 'element';
	    newElement.pathId = '';
	    newElement.relationIds = relationIds;
	    newElement.relationCapacities = relationCapacities;
	    newElement.capacity = panels[i].capacity;
	    newElement.coordinates = empId !== null ? empId.coordinates : [0,0];
	    newElement.importId = panels[i].assetManagerId;
	    newElement.user = 'Asset Manager';
	    newElement.version = 1;
	    panelToMarker.push(newElement);
	    nextId = nextId + 1;
		if (isAdmin) {
		  if (!wasConfirm && !confirm) {
		    mustUpdate = window.confirm(
			  `¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		    );
			setConfirm(true);
			wasConfirm = true;
		  }
		  if (mustUpdate) {
		    saveElement([], pluginVariables[0], newElement).then((resultado) => {
		      if (!resultado) {
			    console.log('Error', resultado, newElement);
		      }
		    }).catch((error) => {
		      console.error('Error al guardar el elemento:', error);
		    });
		  }
		}
		const localRelations = l1LinkList.filter(ele => ele.localPanel === panels[i].panelId);
		for (let x = 0; x < localRelations.length; x++) {
		  const portExist = items.find(ele => ele.elementType === 'splicer' && ele.idx === localRelations[x].localPort);
		  if (!portExist) {
			const actualSegment = items.find(ele => ele.elementType === 'data_link' &&
			  ele.origin === localRelations[x].assetId && ele.originPort === localRelations[x].assetPort);
			const linkId = items.find(ele => ele.elementType === 'link' && ele.idx === panels[i].emp);
			const panelId = panelToMarker.find(ele => ele.idx === panels[i].panelId);
			let newPort = newObject(nextId, 'connector', '', localRelations[x].localPort, 'Puerto ' + localRelations[x].localPort);
			newPort.elementId = newPort.uid;
			newPort.panelId = panelId.uid;
			newPort.segmentId = actualSegment ? actualSegment.segmentId : '';
			newPort.pathId = '';
			newPort.coordinates = empId !== null ? empId.coordinates : [0,0];
			newPort.parentsIds = [newElement.uid];
			newPort.relationIds = [localRelations[x].assetId];
			newPort.relationPorts = [localRelations[x].assetPort];
			newPort.capacity = 1;
			newPort.connector = localRelations[x].connector;
			newPort.duplex = localRelations[x].duplex;
			newPort.importId = panels[i].assetManagerId;
			newPort.user = 'Asset Manager';
			newPort.version = 1;
			panelToMarker.push(newPort);
			nextId = nextId + 1;
			if (isAdmin) {
			  if (!wasConfirm && !confirm) {
				mustUpdate = window.confirm(
				  `¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
				);
				setConfirm(true);
				wasConfirm = true;
			  }
			  if (mustUpdate) {
				saveElement([], pluginVariables[0], newPort).then((resultado) => {
				  if (!resultado) {
					console.log('Error', resultado, newPort);
				  }
				}).catch((error) => {
				  console.error('Error al guardar el elemento:', error);
				});
			  }
			}
		  }
		}
	  }
    }
	return panelToMarker;
  }
			
  const linksToMarker = (links: [], items: []) => {
	let nextId = items.length;
	const linkToMarker: [] = [];
	let isFoMEdia = false;
	let linkType = 'ge_path';
	let actualLink: any;
	let actualSegment: any;
	let actualDataLink: any;
	for (let i = 0; i < links.length; i++) {
	  const mediaText = links[i].media ? links[i].media : 'UTP';
	  const media = mediaText.replace(/\s/g,'');
	  isFoMEdia = media.includes('UTP') || media.includes('RADIO') ? false : true;
	  linkType = isFoMEdia ? 'fo_path' : 'utp_path';
	  linkType = media.includes('RADIO') ? 're_path' : linkType;
	  const idx = 'LINK_' + media + '_' + links[i].localEmp + '<->' + links[i].remoteEmp;  
	  const title = 'Enlace ' + links[i].localEmp + ' <-> ' + links[i].remoteEmp;
	  const localEmp = items.find(ele => ele.elementType === 'emp' && ele.idx === links[i].localEmp);
	  const remoteEmp = items.find(ele => ele.elementType === 'emp' && ele.idx === links[i].remoteEmp);
	  const coordinates = localEmp && remoteEmp ? [localEmp.coordinates, remoteEmp.coordinates] : [];
	  actualLink = items.find(ele => ele.elementType === 'link' && ele.idx === idx && ele.importId === links[i].assetManagerId);
	  if (!actualLink) {
	    actualLink = linkToMarker.find(ele => ele.elementType === 'link' && ele.idx === idx && ele.importId === links[i].assetManagerId);
	  }
	  if (!actualLink) {
		const newLink = newObject(nextId, linkType, '', idx, title);
	    newLink.elementId = newLink.uid;
		newLink.linkId = newLink.uid;
		newLink.pathId = '';
		newLink.origin = links[i].localEmp;
		newLink.destination = links[i].remoteEmp;
		newLink.capacity = links[i].capacity;
		newLink.coordinates = coordinates;
	    newLink.duplex = links[i].duplex;
	    newLink.importId = links[i].assetManagerId;
	    newLink.user = 'Asset Manager';
	    newLink.version = 1;
	    linkToMarker.push(newLink);
	    nextId = 1 + nextId;
		actualLink = newLink;
	  }
	  const segIdx = 'SEG_' + media + '_' + links[i].localEmp + '/' + links[i].localPanel + '<->' + links[i].remoteEmp + '/' + links[i].remotePanel;
	  const segTitle = media.includes('RADIO') ? 
	    'Radio ' + links[i].localPanel + ' <-> ' + links[i].remotePanel : 
		'Cable ' + links[i].localPanel + ' <-> ' + links[i].remotePanel;
	  let segCable = isFoMEdia ? 'Cable de fibra óptica' : 'Cable de cobre';
      let segLabel = 'fiber';
	  let segType = 'cable';
      if (linkType === 're_path' || linkType === 'sa_path') {
        segLabel = 'channel';
		segType = 're_path';
	    segCable = 'Enlace de Radio';
      } else if (linkType === 'ge_path') {
        segLabel = 'pair';
      } else if (linkType === 'utp_path') {
        segLabel = 'utp';
      }
	  actualSegment = items.find(ele => ele.elementType === 'segment' && ele.idx === segIdx && ele.importId === links[i].assetManagerId);
	  if (!actualSegment) {
	    actualSegment = linkToMarker.find(ele => ele.elementType === 'segment' && ele.idx === segIdx && ele.importId === links[i].assetManagerId);
	  }
	  if (!actualSegment) {
		const newSegment = newObject(nextId, segType, '', segIdx, segTitle);
		newSegment.elementId = newSegment.uid;
		newSegment.segmentId = newSegment.uid;
		newSegment.sectionType = segCable;
		newSegment.pathId = '';
		newSegment.linkId = actualLink.uid;
		newSegment.origin = links[i].localPanel;
		newSegment.destination = links[i].remotePanel;
		newSegment.capacity = links[i].capacity;
		newSegment.coordinates = coordinates;
		newSegment.parentsIds = [actualLink.uid];
		newSegment.sublinkIds = [`${newSegment.uid}_${segLabel}_1`];
		newSegment.sublinkDistance = [1];
		if (linkType !== 're_path' && linkType !== 'sa_path'  && linkType !== 'utp_path') {
		  newSegment.sublinkColor = linkType === 'ge_path' ? [`${multiparColors[0].value}`] : [`${fiberOpticColors[0].value}`];
		  newSegment.sublinkBuffer = [`${bufferColors[0].value}`];
		}
		newSegment.sublinkAttenuation = [0];
		newSegment.importId = links[i].assetManagerId;
		newSegment.user = 'Asset Manager';
		newSegment.version = 1;
		linkToMarker.push(newSegment);
		nextId = nextId + 1;
		actualSegment = newSegment;
	  } else {
	    const index = actualSegment.sublinkIds.length || 0;
		const bufferIndex = Math.floor(index / 14);
		const colorIndex = index - (bufferIndex * 14);
		actualSegment.capacity = index + 1;
		actualSegment.sublinkIds.push(`${actualSegment.uid}_${segLabel}_${index + 1}`);
		actualSegment.sublinkDistance.push(1);
		if (linkType !== 're_path' && linkType !== 'sa_path'  && linkType !== 'utp_path') {
		  actualSegment.sublinkColor.push(`${fiberOpticColors[colorIndex].value}`);
		  actualSegment.sublinkBuffer.push(`${bufferColors[bufferIndex].value}`);
		}
		actualSegment.sublinkAttenuation.push(0);
	  }
	  let assetExist = links[i].localAssetId !== '' && links[i].remoteAssetId !== '' &&
	    links[i].localAssetPort !== '' && links[i].remoteAssetPort !== '' ? true : false;
	  if (assetExist) {
	    const dataLinkIdx = 'DL_' + links[i].key;
	    const dataLinkTitle = 'Enlace de Datos ' + links[i].localAssetId + '/' + links[i].localAssetPort + ' <-> ' + 
		  links[i].remoteAssetId + '/' + links[i].remoteAssetPort;
		actualDataLink = items.find(ele => ele.subFamily === 'link' && ele.idx === dataLinkIdx && ele.importId === links[i].assetManagerId);
	    if (!actualDataLink) {
	      actualDataLink = linkToMarker.find(ele => ele.subFamily === 'link' && ele.idx === dataLinkIdx && ele.importId === links[i].assetManagerId);
	    }
	    if (!actualDataLink) {
		  const newDataLink = newObject(nextId, 'data_link', '', dataLinkIdx, dataLinkTitle);
		  newDataLink.elementId = newDataLink.uid;
		  newDataLink.linkId = actualLink.uid;
		  newDataLink.segmentId = actualSegment.uid;
		  newDataLink.pathId = '';
		  newDataLink.origin = links[i].localAssetId;
		  newDataLink.destination = links[i].remoteAssetId;
		  newDataLink.originPort = links[i].localAssetPort;
		  newDataLink.destinationPort = links[i].remoteAssetPort;
		  newDataLink.localPanel = links[i].localPanel;
		  newDataLink.remotePanel = links[i].remotePanel;
		  newDataLink.localPort = links[i].localPort;
		  newDataLink.remotePort = links[i].remotePort;
		  newDataLink.capacity = 1;
		  newDataLink.coordinates = coordinates;
		  newDataLink.parentsIds = [actualSegment.uid];
		  newDataLink.importId = links[i].assetManagerId;
		  newDataLink.user = 'Asset Manager';
		  newDataLink.version = 1;
		  linkToMarker.push(newDataLink);
		  nextId = nextId + 1;
	    }
	  }
    }
	for (let i = 0; i < linkToMarker.length; i++) {
	  if (isAdmin) {
		if (!wasConfirm && !confirm) {
		  mustUpdate = window.confirm(
			`¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		  );
		  setConfirm(true);
		  wasConfirm = true;
		}
		if (mustUpdate) {
		  saveElement([], pluginVariables[0], linkToMarker[i]).then((resultado) => {
			if (!resultado) {
			  console.log('Error', resultado, linkToMarker[i]);
			}
		  }).catch((error) => {
			console.error('Error al guardar el elemento:', error);
		  });
		}
	  }
	}
	return linkToMarker;
  }

  const inetLinksToMarker = (links: [], items: []) => {
	//console.log(links, items);
	let nextId = items.length;
	const linkToMarker: [] = [];
	for (let i = 0; i < links.length; i++) {
	  const idx = 'INET_' + links[i].key;  
	  const title = links[i].label !== '' ? links[i].label : 'Enlace Internet ' + links[i].supplier + ' ' + links[i].key;
	  const localEmp = items.find(ele => ele.elementType === 'emp' && ele.idx === links[i].emp);
	  const localAsset = items.find(ele => ele.elementType === 'asset' && ele.idx === links[i].assetId);
	  const coordinates = localEmp ? localEmp.coordinates : [0,0];
	  let actualLink = items.find(ele => ele.elementType === 'inet_link' && ele.key === links[i].key && ele.importId === links[i].assetManagerId);
	  if (!actualLink) {
	    actualLink = linkToMarker.find(ele => ele.elementType === 'inet_link' && ele.key === links[i].key && ele.importId === links[i].assetManagerId);
	  }
	  if (!actualLink) {
		const newLink = newObject(nextId, 'inet_link', '', idx, title);
	    newLink.key = links[i].key;
		newLink.status = links[i].status;
		newLink.elementId = newLink.uid;
		newLink.linkId = newLink.uid;
		newLink.pathId = '';
		newLink.siteId = links[i].site;
		newLink.groupId = links[i].group;
		newLink.origin = localAsset ? localAsset.uid : links[i].assetId;
		newLink.originPort = links[i].assetPort;
		newLink.bwUp = links[i].bwUp;
		newLink.bwDown = links[i].bwDown;
		newLink.coordinates = coordinates;
	    newLink.critic = links[i].critic;
	    newLink.ip = links[i].ip;
	    newLink.mask = links[i].mask;
	    newLink.fqdn = links[i].fqdn;
	    newLink.gw = links[i].gw;
	    newLink.gwCentral = links[i].gwCentral;
	    newLink.fqdnGw = links[i].fqdnGw;
	    newLink.sla = links[i].sla;
		newLink.supplier = links[i].supplier;
		newLink.reference = links[i].reference;
	    newLink.importId = links[i].assetManagerId;
	    newLink.user = 'Asset Manager';
	    newLink.version = 1;
	    linkToMarker.push(newLink);
	    nextId = 1 + nextId;
	  }
    }
	for (let i = 0; i < linkToMarker.length; i++) {
	  if (isAdmin) {
		if (!wasConfirm && !confirm) {
		  mustUpdate = window.confirm(
			`¿Deseas sincronizar datos GIS desde Asset Manager? \n Esta operación no se puede deshacer.`
		  );
		  setConfirm(true);
		  wasConfirm = true;
		}
		if (mustUpdate) {
		  saveElement([], pluginVariables[0], linkToMarker[i]).then((resultado) => {
			if (!resultado) {
			  console.log('Error', resultado, linkToMarker[i]);
			}
		  }).catch((error) => {
			console.error('Error al guardar el elemento:', error);
		  });
		}
	  }
	}
	return linkToMarker;
  }

  const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371e3; // Radio de la Tierra en metros
    const toRadians = (angle: number) => (angle * Math.PI) / 180;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) ** 2;

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  };

  const idxToUid = (items: [], array: [], elementType: string) => {
	if (elementType === 'links') {
	  const updatedElements = items.map((item) => {
        if (item.elementType !== 'segment' && item.elementType !== 'link' && item.elementType !== 'data_link') {
          return item;
        }
		if (item.elementType === 'segment' || item.elementType === 'link') {
          if (item.origin !== '') {
            let localPanel = array.find((ele) => ele.elementType === 'element' && ele.subFamily === 'panels' && ele.idx === item.origin);
            if (!localPanel) {
		      localPanel = array.find((ele) => ele.elementType === 'asset' && ele.subFamily === 'hw' && ele.idx === item.origin);
		    }
		   if (localPanel) {
              item.origin = localPanel.uid;
            }
          }
          if (item.destination !== '') {
            let remotePanel = array.find((ele) =>ele.elementType === 'element' && ele.subFamily === 'panels' && ele.idx === item.destination);
            if (!remotePanel) {
		      remotePanel = array.find((ele) => ele.elementType === 'asset' && ele.subFamily === 'hw' && ele.idx === item.destination);
		    }
            if (remotePanel) {
              item.destination = remotePanel.uid;
            }
          }
          return item;
	    } else if (item.elementType === 'data_link') {
          if (item.origin !== '') {
		    let localAsset = array.find((ele) => ele.elementType === 'asset' && ele.idx === item.origin);
		    if (localAsset) {
              item.origin = localAsset.uid;
            }
		  }
          if (item.localPanel !== '') {
            let localPanel = array.find((ele) => ele.elementType === 'element' && ele.subFamily === 'panels' && ele.idx === item.localPanel);
		    if (localPanel) {
              item.localPanel = localPanel.uid;
            }
          }
          if (item.localPort !== '') {
            let localPort = array.find((ele) => ele.elementType === 'splicer' && ele.idx === item.localPort);
		    if (localPort) {
              item.localPort = localPort.uid;
            }
          }
          if (item.destination !== '') {
		    let remoteAsset = array.find((ele) => ele.elementType === 'asset' && ele.idx === item.destination);
            if (remoteAsset) {
              item.destination = remoteAsset.uid;
		    }
		  }
          if (item.remotePanel !== '') {
            let remotePanel = array.find((ele) =>ele.elementType === 'element' && ele.subFamily === 'panels' && ele.idx === item.remotePanel);
            if (remotePanel) {
              item.remotePanel = remotePanel.uid;
            }
          }
          if (item.remotePort !== '') {
            let remotePort = array.find((ele) => ele.elementType === 'splicer' && ele.idx === item.remotePort);
		    if (remotePort) {
              item.remotePort = remotePort.uid;
            }
          }
         return item;
		}
      });
      return updatedElements;
	}
  };

  const coordinatesToPlaces = (items: []) => {
    const MAX_DISTANCE = 100;
    const findClosestEmp = (lat: number, lon: number) => {
      return items
        .filter((ele) => ele.elementType === 'emp' && ele.coordinates.length > 1)
        .reduce((closest, current) => {
          const distance = haversineDistance(lat, lon, current.coordinates[0], current.coordinates[1]);
          if (distance <= MAX_DISTANCE && (!closest || distance < closest.distance)) {
            return { ...current, distance };
          }
          return closest;
        }, null);
    };

    const updatedElements = items.map((item) => {
      if (item.elementType !== 'span' && item.elementType !== 'segment') {
        return item;
      }
      if (item.origin === '' && item.coordinates.length > 0) {
        const elementLocalCoordinates = item.coordinates[0];
        let localEmp = items.find(
          (ele) =>
            ele.elementType === 'emp' &&
            ele.coordinates[0] === elementLocalCoordinates[0] &&
            ele.coordinates[1] === elementLocalCoordinates[1]
        );
        if (!localEmp) {
		  localEmp = findClosestEmp(elementLocalCoordinates[0], elementLocalCoordinates[1]);
        }
        if (localEmp) {
          item.origin = localEmp.uid;
        }
      }
      if (item.destination === '' && item.coordinates.length > 0) {
        const elementRemoteCoordinates = item.coordinates[item.coordinates.length - 1];
        let remoteEmp = items.find(
          (ele) =>
            ele.elementType === 'emp' &&
            ele.coordinates[0] === elementRemoteCoordinates[0] &&
            ele.coordinates[1] === elementRemoteCoordinates[1]
        );

        if (!remoteEmp) {
          remoteEmp = findClosestEmp(elementRemoteCoordinates[0], elementRemoteCoordinates[1]);
        }
        if (remoteEmp) {
          item.destination = remoteEmp.uid;
        }
      }
      return item;
    });
    return updatedElements;
  };

  const updateElement = (elementData: []) => {
    const newElementsConfigured: L1OyMData[] = [];
    elementData.series.forEach(series => {
	  const rulesVals: GraphSeriesValue[] = series.fields[0] ? series.fields[0].values.toArray() : [];
	  for (let i = 0; i < rulesVals.length; i++) {
	    const uidText = series.fields.find(field => field.name === options.uid)?.values.get(i);
		let titleText = series.fields.find(field => field.name === options.title)?.values.get(i);
	    let newElement = newObject(i, 'path', uidText, '', titleText);
		newElement.technicalDetails = series.fields.find(field => field.name === options.technicalDetails)?.values.get(i);
		newElement.user = series.fields.find(field => field.name === options.user)?.values.get(i);
		newElement.created = series.fields.find(field => field.name === options.created_at)?.values.get(i);
		newElement.updated = series.fields.find(field => field.name === options.updated_at)?.values.get(i);
		newElement.version = Number(series.fields.find(field => field.name === options.version)?.values.get(i));
	    const jsonObject = JSON.parse(String(newElement.technicalDetails));
	    newElement.idx = jsonObject.idx ? jsonObject.idx : jsonObject.id;
	    newElement.idx = newElement.idx === 'undefined' ? newElement.uid : newElement.idx;
	    newElement.elementId = jsonObject.id;
	    newElement.style = jsonObject.style ? jsonObject.style : {color: '#000000', size: 24, weight: 2, icon: '', dashArray: '', id: ''};
	    newElement.importId = jsonObject.import_id ? jsonObject.import_id : '';
	    newElement.status = jsonObject.technical_details.status;
	    newElement.state = newElement.status === 'disable' ? false : true;
	    newElement.subType = jsonObject.type;
	    const elementType = subTypeToType(newElement.subType);
	    newElement.elementType = elementType;
	    const subfamily = subTypeToSubfamily(newElement.subType);
	    newElement.subFamily = subfamily;
	    newElement.lastInspector = jsonObject.inspection_data.last_inspector;
	    newElement.inspectionReport = jsonObject.inspection_data.report;
	    newElement.inspectionNotes = jsonObject.inspection_data.notes;
	    newElement.inspectionMediaData = jsonObject.media.images;
	    const created_at = newElement.created;
	    newElement.created = moment(created_at).format('DD/MM/YYYY HH:mm:ss');
	    const updated_at = newElement.updated;
	    newElement.updated = moment(updated_at).format('DD/MM/YYYY HH:mm:ss');
	    if (jsonObject.technical_details.installation_date && jsonObject.technical_details.installation_date !== '') {
		  const installationDate = moment(jsonObject.technical_details.installation_date);
	      const validDate = installationDate.isValid() ? installationDate : moment(jsonObject.technical_details.installation_date, 'DD/MM/YYYY HH:mm:ss', true);
		  newElement.installationDate = validDate.isValid() ? validDate.format('YYYY-MM-DD') : jsonObject.technical_details.installation_date;
		}
		newElement.installationNotes = jsonObject.technical_details.notes;
	    if (elementType !== 'splicer' && elementType !== 'splitter') {
		  if (jsonObject.technical_details.last_inspection && jsonObject.technical_details.last_inspection !== '') {
		    const lastInspection = moment(jsonObject.technical_details.last_inspection);
			const validDate = lastInspection.isValid() ? lastInspection : moment(jsonObject.technical_details.last_inspection, 'DD/MM/YYYY HH:mm:ss', true);
		    newElement.lastInspection = validDate.isValid() ? validDate.format('YYYY-MM-DD') : jsonObject.technical_details.last_inspection;
		  }
		  if (jsonObject.technical_details.next_maintenance && jsonObject.technical_details.next_maintenance !== '') {
		    const nextMaintenance = moment(jsonObject.technical_details.next_maintenance);
		    const validDate = nextMaintenance.isValid() ? nextMaintenance : moment(jsonObject.technical_details.next_maintenance, 'DD/MM/YYYY HH:mm:ss', true);
			newElement.nextMaintenance = validDate.isValid() ? validDate.format('YYYY-MM-DD') : jsonObject.technical_details.next_maintenance;
		  }
		  newElement.owner = jsonObject.technical_details.owner;
	    } else {
		  newElement.segmentId = jsonObject.technical_details.segment_id;
		  newElement.pathEventId = jsonObject.technical_details.path_event_id;
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.capacity = Number(jsonObject.technical_details.capacity);
		  newElement.connector = jsonObject.technical_details.connector ? jsonObject.technical_details.connector : '';
		  newElement.duplex = jsonObject.technical_details.duplex ? jsonObject.technical_details.duplex : '';
	    }
	    if (elementType === 'element') {
		  newElement.pathId = jsonObject.technical_details.path_id;
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.coordinates = jsonObject.technical_details.location.coordinates;
		  newElement.address = jsonObject.technical_details.location.address;
		  newElement.elevation = jsonObject.technical_details.location.elevation;
		  if (subfamily !== 'termination' && jsonObject.technical_details.relations) {
		    newElement.relationIds = jsonObject.technical_details.relations.map(sublink => sublink.element_id);
		    newElement.relationDistances = jsonObject.technical_details.relations.map(sublink => sublink.distances);
		    newElement.relationCapacities = jsonObject.technical_details.relations.map(sublink => sublink.capacities);
		  } else if (subfamily === 'termination' && jsonObject.technical_details.relations) {
		    newElement.relationIds = jsonObject.technical_details.relations.map(sublink => sublink.element_id);
		    newElement.relationCapacities = jsonObject.technical_details.relations.map(sublink => sublink.capacities);
		    newElement.linkId = jsonObject.technical_details.link_id;
		    newElement.segmentId = jsonObject.technical_details.segment_id;
		  }
	    } else if (elementType === 'emp') {
		  newElement.siteId = jsonObject.technical_details.collector;
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.coordinates = jsonObject.technical_details.location.coordinates;
		  newElement.address = jsonObject.technical_details.location.address;
		  newElement.elevation = jsonObject.technical_details.location.elevation;
		  newElement.technician = jsonObject.technical_details.technician ? jsonObject.technical_details.technician : '';
	   }  else if (elementType === 'asset') {
		  newElement.siteId = jsonObject.technical_details.collector;
		  newElement.groupIds = jsonObject.technical_details.groups;
		  newElement.relationIds = jsonObject.technical_details.conected_to.map(conection => conection.element_id);
		  newElement.relationPorts = jsonObject.technical_details.conected_to ? 
		    jsonObject.technical_details.conected_to.map(conection => conection.port) : [];
		  newElement.clusterSerials = jsonObject.technical_details.clustering ?
		    jsonObject.technical_details.clustering.map(member => member.serial_id) : [];
		  newElement.clusterMacs = jsonObject.technical_details.clustering ? 
		    jsonObject.technical_details.clustering.map(member => member.mac_id) : [];
		  newElement.orquesterId = jsonObject.technical_details.orquested_by ? jsonObject.technical_details.orquested_by : '';
		  newElement.parentsIds = newElement.orquesterId;
		  if (jsonObject.technical_details.geometry) {
		    const coordinates = jsonObject.technical_details.geometry.coordinates ? jsonObject.technical_details.geometry.coordinates : [];
		    newElement.coordinates = coordinates;
		  }
		  newElement.technician = jsonObject.technical_details.technician ? jsonObject.technical_details.technician : '';
		  if (jsonObject.technical_details.monitoring) {
		    newElement.product = jsonObject.technical_details.monitoring.product ? jsonObject.technical_details.monitoring.product : '';
		    newElement.assetType = jsonObject.technical_details.monitoring.type ? jsonObject.technical_details.monitoring.type : '';
		    newElement.assetFamily = jsonObject.technical_details.monitoring.family ? jsonObject.technical_details.monitoring.family : '';
		    newElement.fqdn = jsonObject.technical_details.monitoring.fqdn ? jsonObject.technical_details.monitoring.fqdn : '';
		    newElement.ip = jsonObject.technical_details.monitoring.ip ? jsonObject.technical_details.monitoring.ip : '';
		    newElement.protocol = jsonObject.technical_details.monitoring.protocol ? jsonObject.technical_details.monitoring.protocol : '';
		    newElement.community = jsonObject.technical_details.monitoring.community ? jsonObject.technical_details.monitoring.community : '';
		    const targetSla = jsonObject.technical_details.monitoring.target_sla ? jsonObject.technical_details.monitoring.target_sla : 98;
			newElement.sla = isNaN(targetSla) ? 98 : Number(jsonObject.technical_details.monitoring.target_sla);
		    if (jsonObject.technical_details.monitoring.critic) {
		      newElement.critic = jsonObject.technical_details.monitoring.critic === 'true' ? true : false;
		    } else {
		       newElement.critic = false;
		    }
		    if (jsonObject.technical_details.monitoring.notification) {
		      newElement.notifEnable = jsonObject.technical_details.monitoring.notification === 'true' ? true : false;
		    } else {
		       newElement.notifEnable = false;
		    }
		  }
		  if (jsonObject.technical_details.inventory) {
		    newElement.project = jsonObject.technical_details.inventory.project ? jsonObject.technical_details.monitoring.project : '';
		    newElement.purchaseOrder = jsonObject.technical_details.inventory.purchase_order ? jsonObject.technical_details.monitoring.purchase_order : '';
		    newElement.purchaseDate = jsonObject.technical_details.inventory.invoice_date ? jsonObject.technical_details.monitoring.purchase_date : '';
		    newElement.supplier = jsonObject.technical_details.inventory.invoice_date ? jsonObject.technical_details.monitoring.supplier : '';
		    newElement.deliveryDate = jsonObject.technical_details.inventory.invoice_date ? jsonObject.technical_details.monitoring.delivery_date : '';
		    newElement.deliveryNote = jsonObject.technical_details.inventory.delivery_note ? jsonObject.technical_details.monitoring.delivery_note : '';
		    newElement.invoiceNumber = jsonObject.technical_details.inventory.invoice_number ? jsonObject.technical_details.monitoring.invoice_number : '';
		    newElement.serialNumber = jsonObject.technical_details.inventory.serial_number ? jsonObject.technical_details.monitoring.serial_number : '';
		    newElement.warrantyExp = jsonObject.technical_details.inventory.warranty_exp ? jsonObject.technical_details.monitoring.warranty_exp : '';
		    newElement.contractNumber = jsonObject.technical_details.inventory.warranty_exp ? jsonObject.technical_details.monitoring.contract_number : '';
		    newElement.warrantyHwExp = jsonObject.technical_details.inventory.warranty_exp ? jsonObject.technical_details.monitoring.warranty_hw_exp : '';
		    newElement.hwContractNumber = jsonObject.technical_details.inventory.warranty_exp ? jsonObject.technical_details.monitoring.hw_contract_number : '';
		    newElement.macNumber = jsonObject.technical_details.inventory.mac_number ? jsonObject.technical_details.monitoring.mac_number : '';

		  }
	   } else if (elementType === 'path') {
	      newElement.pathId = jsonObject.id;
		  newElement.origin = jsonObject.technical_details.origin;
		  newElement.destination = jsonObject.technical_details.destination;
		  if (jsonObject.technical_details.geometry) {
		    const coordinates = jsonObject.technical_details.geometry.coordinates ? jsonObject.technical_details.geometry.coordinates : [];
		    newElement.coordinates = coordinates;
		  }
	    } else if (elementType === 'span') {
		  newElement.spanId = jsonObject.id;
		  newElement.pathId = jsonObject.technical_details.path_id;
		  newElement.capacity = Number(jsonObject.technical_details.capacity);
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.origin = jsonObject.technical_details.origin;
		  newElement.destination = jsonObject.technical_details.destination;
		  if (jsonObject.technical_details.geometry) {
		    const coordinates = jsonObject.technical_details.geometry.coordinates ? jsonObject.technical_details.geometry.coordinates : [];
		    newElement.coordinates = coordinates;
		  }
	    } else if (elementType === 'link') {
	      newElement.linkId = jsonObject.id;
		  newElement.pathId = jsonObject.technical_details.path_id;
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.origin = jsonObject.technical_details.origin;
		  newElement.destination = jsonObject.technical_details.destination;
	    }  else if (elementType === 'data_link') {
	      newElement.linkId = jsonObject.id;
		  newElement.segmentId = jsonObject.technical_details.segment_id;
		  newElement.origin = jsonObject.technical_details.origin;
		  newElement.destination = jsonObject.technical_details.destination;
		  newElement.originPort = jsonObject.technical_details.origin_port;
		  newElement.destinationPort = jsonObject.technical_details.destination_port;
		  newElement.localPanel = jsonObject.technical_details.local_panel;
		  newElement.remotePanel = jsonObject.technical_details.remote_panel;
		  newElement.localPort = jsonObject.technical_details.local_port;
		  newElement.remotePort = jsonObject.technical_details.remote_port;
		  newElement.capacity = Number(jsonObject.technical_details.capacity);
		  const targetSla = jsonObject.technical_details.monitoring.target_sla ? jsonObject.technical_details.monitoring.target_sla : 98;
		  newElement.sla = isNaN(targetSla) ? 98 : Number(jsonObject.technical_details.monitoring.target_sla);
		  if (jsonObject.technical_details.monitoring.critic) {
		    newElement.critic = jsonObject.technical_details.monitoring.critic === 'true' ? true : false;
		  } else {
		    newElement.critic = false;
		  }
		  if (jsonObject.technical_details.monitoring.notification) {
		    newElement.notifEnable = jsonObject.technical_details.monitoring.notification === 'true' ? true : false;
		  } else {
		    newElement.notifEnable = false;
		  }
		  if (jsonObject.technical_details.geometry) {
		    const coordinates = jsonObject.technical_details.geometry.coordinates ? jsonObject.technical_details.geometry.coordinates : [];
		    newElement.coordinates = coordinates;
		  }
	    } else if (elementType === 'segment') {
		  newElement.segmentId = jsonObject.id;
		  newElement.linkId = jsonObject.technical_details.link_id;
		  newElement.parentsIds = jsonObject.technical_details.parents;
		  newElement.capacity = Number(jsonObject.technical_details.capacity);
	      newElement.sublinkIds = jsonObject.technical_details.sublinks.map(sublink => sublink.sublink_id);
		  if (newElement.subType !== 're_path' && newElement.subType !== 'sa_path') {
		    newElement.sublinkBuffer = jsonObject.technical_details.sublinks.map(sublink => sublink.buffer);
		    newElement.sublinkColor = jsonObject.technical_details.sublinks.map(sublink => sublink.color);
		    newElement.sublinkAttenuation = jsonObject.technical_details.sublinks.map(sublink => sublink.attenuation);
		  } else {
		    newElement.sublinkBuffer = jsonObject.technical_details.sublinks.map(sublink => sublink.frecuency);
		    newElement.sublinkColor = jsonObject.technical_details.sublinks.map(sublink => sublink.bw);
		    newElement.sublinkAttenuation = jsonObject.technical_details.sublinks.map(sublink => sublink.snr);
		  }
		  newElement.sublinkDistance = jsonObject.technical_details.sublinks.map(sublink => sublink.distance);
		  newElement.origin = jsonObject.technical_details.origin;
		  newElement.destination = jsonObject.technical_details.destination;
	    } else if (elementType === 'splicer') {
		  newElement.fiberIds = jsonObject.technical_details.splicings.map(splice => splice.fiber_id);
		  newElement.eventAttenuation = jsonObject.technical_details.splicings.map(splice => splice.attenuation);
		  newElement.technician = jsonObject.technical_details.technician ? jsonObject.technical_details.technician : '';
	    } else if (elementType === 'splitter') {
		  newElement.fiberIds = jsonObject.technical_details.splittings.map(splice => splice.fiber_id);
		  newElement.eventAttenuation = jsonObject.technical_details.splittings.map(splice => splice.attenuation);
		  newElement.technician = jsonObject.technical_details.technician ? jsonObject.technical_details.technician : '';
	    }
	    newElementsConfigured.push(newElement);
	  }
    });
	return newElementsConfigured;
  };

  const updateProceses = (variableValue: string) => {
	const proceses = variableValue.split(',');
	const newProcessList: [] = [];
    for (let i = 0; i < proceses.length; i++) {
	  const processData = proceses[i].split('||');
	  if (processData[0] !== '' && processData[4] !== '') {
		const status = valueToAssetStatus.find((ele) => ele.label === processData[1]);
	    const process = {
		  key: processData[0],
	      status: status ? status.value : 'in_use',
		  label: processData[2],
	      value: processData[4],
		  assetId: processData[3],
		  state: processData[1] === 'Detenido' ? false : true,
	      assetType: 'process',
	      assetFamily: 'app',
	      subFamily: 'app',
	      ip: '',
	      fqdn: '',
	      params: processData[5],
	      path: processData[6],
		  assetManagerId: processData[7]
	    };
	    newProcessList.push(process);
	  }
    }
	return newProcessList;
  }

  const updateServices = (variableValue: string) => {
	const services = variableValue.split(',');
	const newServiceList: [] = [];
    for (let i = 0; i < services.length; i++) {
	  const serviceData = services[i].split('||');
	  if (serviceData && serviceData[0] !== '' && serviceData[4] !== '') {
		const status = valueToAssetStatus.find((ele) => ele.label === serviceData[1]);
	    const service = {
		  key: serviceData[0],
	      status: status ? status.value : 'in_use',
		  label: serviceData[2],
	      value: serviceData[4],
		  assetId: serviceData[3],
		  state: serviceData[1] === 'Detenido' ? false : true,
	      assetType: 'service',
	      assetFamily: 'app',
	      subFamily: 'app',
		  assetManagerId: serviceData[5]
	    };
	    newServiceList.push(service);
	  }
    }
	return newServiceList;
  }

  const updateApps = (variableValue: string) => {
	const apps = variableValue.split(',');
	const newAppList: [] = [];
    for (let i = 0; i < apps.length; i++) {
	  const appData = apps[i].split('||');
	  if (appData[0] !== '' && appData[4] !== '') {
		const status = valueToAssetStatus.find((ele) => ele.label === appData[1]);
	    const app = {
		  key: appData[0],
	      status: status ? status.value : 'in_use',
		  label: appData[2],
	      value: appData[3],
		  state: appData[1] === 'Detenido' ? false : true,
	      assetType: 'web',
	      assetFamily: 'app',
	      subFamily: 'app',
	      fqdn: appData[3],
	      http_enable: appData[4] && (appData[4] === 'true' || appData[4] === 't') ? true : false,
	      http_port: appData[5] && !isNaN(appData[5]) ? Number(appData[5]) : 80,
	      https_enable: appData[6] && (appData[6] === 'true' || appData[6] === 't') ? true : false,
	      https_port: appData[7] && !isNaN(appData[7]) ? Number(appData[7]) : 443,
		  site: appData[8],
		  api: appData[9],
		  assetManagerId: appData[10]
	    };
	    newAppList.push(app);
	  }
    }
	return newAppList;
  }

  const updateAssets = (variableValue: string) => {
	const assets = variableValue.split(',');
	const newAssetList: [] = [];
    for (let i = 0; i < assets.length; i++) {
	  const assetData = assets[i].split('||');
	  if (assetData[0] !== '' && assetData[20] !== '') {
	    const relationData = assetData[14] ? assetData[14] : '';
	    const clusterData = assetData[15] ? assetData[15] : '';
	    const clusterData2 = assetData[16] ? assetData[16] : '';
		const statusValue = assetData[2] !== 'Por OV 2500' || assetData[2] !== 'Por otro dispositivo' ? assetData[2] : 'Gestionado por otro activo';
		const ov_orquested = assetData[2] === 'Por OV 2500' ? true : false;
		const status = valueToAssetStatus.find((ele) => ele.label === assetData[2]);
		const assetType = assetTypes.find((ele) => ele.label === assetData[6]);
		const subType = valueToSubtype.find((ele) => ele.label === assetData[7]);
		const relations = relationData ? relationData.split(',') : [];
		const groupText = assetData[5] && assetData[5] !== '' ? assetData[5] : '';
		const clusterSerials = clusterData2 ? clusterData2.split(',') : [];
		const clusterMacs = clusterData ? clusterData.split(',') : [];
	    const asset = {
		  key: assetData[20],
	      value: assetData[0],
		  label: assetData[1],
	      status: status ? status.value : 'in_use',
		  state: assetData[2] === 'Detenido' ? false : true,
	      emp: assetData[3],
	      site: assetData[4],
	      groups: groupText.split(','),
	      assetType: assetType ? assetType.value : 'asset',
	      assetFamily: subType ? subType.value : 'asset',
	      subFamily: subType ? subType.subFamily : 'hw',
	      product: assetData[8],
	      ip: assetData[9],
	      fqdn: assetData[17],
	      protocol: assetData[18],
	      community: assetData[19],
	      sla: isNaN(assetData[10]) ? 98 : Number(assetData[10]),
	      critic: assetData[11] === 'true' ? true : false,
		  notifEnable: assetData[12] === 'true' ? true : false,
		  manteinance: assetData[13],
		  relations: relations,
		  orquested_by: assetData[21],
		  ov_orquested: ov_orquested,
		  clusterMacs: clusterMacs,
		  clusterSerials: clusterSerials,
		  assetManagerId: assetData[22]
	    };
	    newAssetList.push(asset);
	  }
    }
	return newAssetList;
  }
  const updateProducts = (variableValue: string) => {
	const products = variableValue.split(',');
	const newProductList: [] = [];
    for (let i = 0; i < products.length; i++) {
	  const productData = products[i].split('||');
	  if (productData[0] !== '') {
	    const product = {
	      value: productData[0],
		  label: productData[1] + ' - ' + productData[0],
		  productType: productData[1],
	      protocol: productData[2],
	      community: productData[3],
	      objectId: productData[4],
		  assetManagerId: productData[5],
	    };
	    newProductList.push(product);
	  }
    }
	return newProductList;
  }

  const updatePlaces = (variableValue: string) => {
	const places = variableValue.split(',');
	const newPlaceList: [] = [];
    for (let i = 0; i < places.length; i++) {
	  const placeData = places[i].split('||');
	  if (placeData.length > 6 && placeData[3] !== '') {
	    const family = valueToSubtype.find(aspect => aspect.label === placeData[2]);
	    const place = {
	      label: placeData[0],
	      description: placeData[1],
	      family: family ? family.value : 'emp',
	      value: placeData[0],
	      key: placeData[3],
	      lat: placeData[4],
	      lng: placeData[5],
	      manteinance: placeData[6],
	      site: placeData[7],
		  location: placeData[8],
		  assetManagerId: placeData[9]
	    };
	    newPlaceList.push(place);
	  }
    }
	return newPlaceList;
  }
  const updatePlaceType = (variableValue: string) => {
    const placeTypes = variableValue.split(',');
	const newPlaceTypeList: [] = [];
    for (let i = 0; i < placeTypes.length; i++) {
	  const placeData = placeTypes[i].split('||');
	  let placeType = {
	    label: placeData[0],
	    description: placeData[1],
	    value: placeData[2],
	  };
	  newPlaceTypeList.push(placeType);
    }
	return newPlaceTypeList;
  }
  const updateSite = (variableValue: string) => {
	const sites = variableValue.split(',');
	const newSiteList: [] = [];
    for (let i = 0; i < sites.length; i++) {
	  const siteData = sites[i].split('||');
	  let site = {
	    label: siteData[0] + ' - ' + siteData[2],
	    description: siteData[1],
	    value: siteData[2],
	  };
	  newSiteList.push(site);
    }
	return newSiteList;
  }
  const updateL1Links = (variableValue: string) => {
    const links = variableValue.split(',');
	const newL1LinkList: [] = [];
    for (let i = 0; i < links.length; i++) {
	  const linkData = links[i].split('||');
	  const status = valueToAssetStatus.find((ele) => ele.label === linkData[12]);
	  let link = {
	    key: linkData[0],
		status: status ? status.value : 'in_use',
		emp: linkData[1],
	    localPanel: linkData[2],
	    localPort: linkData[3],
	    remotePanel: linkData[4],
	    remotePort: linkData[5],
	    assetId: linkData[6],
	    assetPort: linkData[7],
	    duplex: linkData[8],
	    connector: linkData[9],
		media: linkData[10],
		assetManagerId: linkData[11]
	  };
	  newL1LinkList.push(link);
    }
	return newL1LinkList;
  }
  const updateLinks = (l1LinkList: [], panelList: []) => {
    const newLinkList: [] = [];
	for (let i = 0; i < l1LinkList.length; i++) {
	  const linkExist = newLinkList.find(ele => 
	    (ele.localPanel === l1LinkList[i].localPanel && ele.localPort === l1LinkList[i].localPort && 
		  ele.remotePanel === l1LinkList[i].remotePanel && ele.remotePort === l1LinkList[i].remotePort) ||
	    (ele.localPanel === l1LinkList[i].remotePanel && ele.localPort === l1LinkList[i].remotePort &&
		  ele.remotePanel === l1LinkList[i].localPanel && ele.remotePort === l1LinkList[i].localPort)
	  );
	  if (!linkExist) {
	    const remoteEnd = l1LinkList.find(ele => ele.localPanel === l1LinkList[i].remotePanel && ele.localPort === l1LinkList[i].remotePort);
		const panel = panelList.find(ele => ele.panelId === l1LinkList[i].localPanel && ele.emp === l1LinkList[i].emp);
	    if (remoteEnd) {
	      let newLink = {
	        key: l1LinkList[i].key + '_' + remoteEnd.key,
			status: l1LinkList[i].status,
			capacity: 1,
			media: l1LinkList[i].media,
			duplex: l1LinkList[i].duplex,
			localEmp: l1LinkList[i].emp,
	        remoteEmp: remoteEnd.emp,
	        localPanel: l1LinkList[i].localPanel,
	        localPort: l1LinkList[i].localPort,
	        remotePanel: l1LinkList[i].remotePanel,
	        remotePort: l1LinkList[i].remotePort,
	        localAssetId: l1LinkList[i].assetId,
	        localAssetPort: l1LinkList[i].assetPort,
	        remoteAssetId: remoteEnd.assetId,
	        remoteAssetPort: remoteEnd.assetPort,
	        localConnector: l1LinkList[i].connector,
			assetManagerId: l1LinkList[i].assetManagerId
	      };
	      newLinkList.push(newLink);
	    }
	  }
    }
	return newLinkList;
  }
  const updateInetLinks = (variableValue: string) => {
    const links = variableValue.split(',');

	const newInetLinkList: [] = [];
    for (let i = 0; i < links.length; i++) {
	  const linkData = links[i].split('||');
	  const status = valueToAssetStatus.find((ele) => ele.label === linkData[1]);
	  let link = {
	    key: linkData[0],
		status: status ? status.value : 'in_use',
		value: linkData[2],
		label: linkData[3],
		emp: linkData[4],
	    site: linkData[5],
	    group: linkData[6],
	    supplier: linkData[7],
	    reference: linkData[8],
		critic: linkData[9] === true ? true : false,
		ip: linkData[10],
		mask: linkData[11] !== '' ? linkData[11] : '255.255.255.252',
		fqdn: linkData[12],
		assetId: linkData[13],
	    assetPort: linkData[14],
	    bwUp: isNaN(linkData[15]) ? 0 : Number(linkData[15]),
		bwDown: isNaN(linkData[16]) ? 0 : Number(linkData[16]),
		gw: linkData[17],
		fqdnGw: linkData[18],
		gwCentral: linkData[19],
		sla: isNaN(linkData[20]) ? 98 : Number(linkData[20]),
		assetManagerId: linkData[21]
	  };
	  newInetLinkList.push(link);
    }
	return newInetLinkList;
  }

  const elements: [] = useMemo(() => updateElement(data), [data]);
  const assetList: [] = useMemo(() => updateAssets(assetVariableValue), [assetVariableValue]);
  const processList: [] = useMemo(() => updateProceses(processVariableValue), [processVariableValue]);
  const servicesList: [] = useMemo(() => updateServices(servicesVariableValue), [servicesVariableValue]);
  const appsList: [] = useMemo(() => updateApps(appsVariableValue), [appsVariableValue]);

  const productList: [] = useMemo(() => updateProducts(productVariableValue), [productVariableValue]);
  const placeList: [] = useMemo(() => updatePlaces(placeVariableValue), [placeVariableValue]);
  const placeTypeList: [] = useMemo(() => updatePlaceType(typesVariableValue), [typesVariableValue]);
  const siteList: [] = useMemo(() => updateSite(siteVariableValue), [siteVariableValue]);
  const l1LinkList: [] = useMemo(() => updateL1Links(linkVariableValue), [linkVariableValue]);
  const inetLinkList: [] = useMemo(() => updateInetLinks(intetLinkVariableValue), [intetLinkVariableValue]);
  
  const panelList: [] = useMemo(() => getPanels(l1LinkList, 'localPanel'), [linkVariableValue]);
  const linkList: [] = useMemo(() => updateLinks(l1LinkList, panelList), [linkVariableValue]);

  useEffect(() => {
	const elementChecked = coordinatesToPlaces(elements);
	setElementsConfigured(elementChecked);
	setOldElementsConfigured(elementChecked);
	setRenderCount(1);
  }, [elements]);
	
  if (isAdmin && renderCount === 1) {  
	//console.log(assetList, productList, placeList, l1LinkList, linkList, panelList);
	//console.log(processList, servicesList, appsList, inetLinkList);
	const assetManagerMarkers = assetList ? assetToMarker(assetList, [...elementsConfigured]) : [];
	const appAssetManagerMarkers = (processList || servicesList || appsList) ? 
	  appToMarker(
	    [
		  ...processList,
		  ...servicesList,
		  ...appsList
		],[
		  ...assetManagerMarkers,
		  ...elementsConfigured
		]) : [];
	const empAssetManagerMarkers = placeList ? 
	  placeToMarker(placeList,
		[
		  ...appAssetManagerMarkers,
		  ...assetManagerMarkers,
		  ...elementsConfigured
		]) : [];
	const linkAssetManagerMarkers = linkList ? 
	  linksToMarker(linkList,
		[
		  ...empAssetManagerMarkers,
		  ...appAssetManagerMarkers,
		  ...assetManagerMarkers,
		  ...elementsConfigured
		]) : [];
	const inetLinkAssetManagerMarkers = inetLinkList ? 
	  inetLinksToMarker(inetLinkList,
	    [
		  ...linkAssetManagerMarkers,
		  ...empAssetManagerMarkers,
		  ...appAssetManagerMarkers,
		  ...assetManagerMarkers,
		  ...elementsConfigured
		]) : [];
    const panelAssetManagerMarkers = panelList ? 
	  panelsToMarker(panelList,
	    [
		  ...inetLinkAssetManagerMarkers,
		  ...linkAssetManagerMarkers,
		  ...empAssetManagerMarkers,
		  ...appAssetManagerMarkers,
		  ...assetManagerMarkers,
		  ...elementsConfigured
		]) : [];
	setAssetMarkers([...assetManagerMarkers, ...appAssetManagerMarkers]);
	setEmpMarkers(empAssetManagerMarkers);
	const linkChecked = idxToUid(
	  linkAssetManagerMarkers,
	  [...panelAssetManagerMarkers, ...assetManagerMarkers],
	  'links'
	);
	setLinkMarkers([...linkChecked, ...inetLinkAssetManagerMarkers]);
	setPanelMarkers(panelAssetManagerMarkers)
    setRenderCount(2);
  }

  const noAlertsMessage = elementsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }
  //console.log(linkMarkers, assetMarkers);
	
  return (
    <div className={styles.section} key={String(id)}>
      <L1OyMGroup
	    id={renderCount}
	    showTitle={options.showTitle}
		showSelector={options.showAssetSelector}
		showTable={showInTableMode}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    elements={[...elementsConfigured, ...assetMarkers, ...panelMarkers, ...linkMarkers]}
		empMarkers={empMarkers}
		assets={assetList}
		products={productList}
		places={placeList}
		sites={siteList}
		types={placeTypeList}
		l1Links={l1LinkList}
		ppList={panelList}
		pluginVariables={pluginVariables}
		onSaveChanges={(elementSaved) => {
		  const actualElements = elementsConfigured;
		  const element = elementSaved.find(element => element.updated === '');
		  const index = element ? element.id : null;
		  if (index !== null) {
			const d = Date.now();
			const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
			let newElement = element;
			newElement.updated = date;
			actualElements[index] = newElement;
		    setElementsConfigured(actualElements);
			setOldElementsConfigured(actualElements);		
		  }
		  return actualElements;
		}}
		onSaveImportData={(elementsUpdated) => {
		  setElementsConfigured(() => [...elementsUpdated, ...elementsConfigured]);
		}}
		onCancelImportData={() => {
		  setElementsConfigured(() => [...oldElementsConfigured]);
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const getStyles = (width: number, height: number) => {
  return (theme: NetMonitorTheme2) => ({
    section: css`
	  width: 100%;
	  height: 100%;
	  display: flex;
    `,
  });
};
